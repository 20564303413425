// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.I0rpPV2PDJDc3qL6oCL7 {
  padding-top: 0.5rem;
}

.rWUNwvJuf0XOq1J7J1JR {
  margin: 40px 0px;
}

.GDy0X2c91yia3G0VZjmC {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
}

.wCitiKkUeuE3PAcdntpg {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.RaawoMbyLgKCIKRqOdsm {
  font-family: Poppins, sans-serif;
  background-color: #ffffff;
  color: #2755fe;
  border: 1px solid #2755fe;
}

.lqrp2RIhLeeZiRCeTN0Y {
  background-color: #037bff;
  color: white;
  padding: 0.5rem;
  border: none;
}

.uexx26s2uOUXUA9qWNE_ {
  background-color: #1bae9f;
  color: white;
  padding: 0.5rem;
  border: none;
}

.lqrp2RIhLeeZiRCeTN0Y:hover {
  background-color: #076dda;
}

.uexx26s2uOUXUA9qWNE_:hover {
  background-color: #11a294;
}

.Iev0eT2AKbBFNEpsLCTH {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #333333;
}

.LR_c3_gPloMVdliTpEeA {
  border: 1px solid red;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputLabel": `I0rpPV2PDJDc3qL6oCL7`,
	"generateTokenButton": `rWUNwvJuf0XOq1J7J1JR`,
	"generateTokenButton__heading": `GDy0X2c91yia3G0VZjmC`,
	"step1FooterContainer": `wCitiKkUeuE3PAcdntpg`,
	"goBackBtn": `RaawoMbyLgKCIKRqOdsm`,
	"connectButton": `lqrp2RIhLeeZiRCeTN0Y`,
	"connectedButton": `uexx26s2uOUXUA9qWNE_`,
	"listItems": `Iev0eT2AKbBFNEpsLCTH`,
	"removeButton": `LR_c3_gPloMVdliTpEeA`
};
export default ___CSS_LOADER_EXPORT___;
