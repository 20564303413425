// React and libraries imports
import React, { useState, useEffect } from 'react';
import { isEmpty, has, capitalize } from 'lodash';
import { Field } from 'formik';
import { isMobileOnly } from 'react-device-detect';
import Select from 'react-select';
import { pdfjs } from 'react-pdf';
import {
  Modal,
  Form,
  Col,
  Spinner,
  Badge,
  OverlayTrigger,
  Tooltip,
  CloseButton
} from 'react-bootstrap';

// Core components and Utils import
import BGVCamera from '../../../../../CommonComponent/BGVCamera';
import ImagePreviewModal from '../../../../../CommonComponent/ImagePreviewModal';
import Checkbox from '../../../../../../../core-components/Checkbox';
import ValidatedFormInputField from '../../../../../../../core-components/ValidatedFormInputField';
import Image from '../../../../../../../core-components/Image';
import Button from '../../../../../../../core-components/Button';
import AppConstants from '../../../../../../../core-components/AppConstants';
import { getFileHeader, validateImage } from '../../../../../../../utils/utilities';
import PasswordProtectedModal from '../../../PasswordProtectedModal';
import {
  ADDRESS_DOC_TYPE,
  MAP_OLD_ADDRESS_DOC_TYPE
} from '../../../../../../../utils/commonConstant';
import MultiUploadForm from './MultiUploadForm';
import ModalWarningSvg from '../../../../../../../images/warning.svg';

// Styles import
import styles from './AddressStep3.module.scss';

export default (props) => {
  const { setFieldValue } = props;
  const oldIdTypeValues = Object.keys(MAP_OLD_ADDRESS_DOC_TYPE);
  const token = new URLSearchParams(document.location.search).get('token');

  //remove front ID
  const [showRemoveFront, setShowRemoveFront] = useState(false);
  const [showRemoveBack, setRemoveBack] = useState(false);

  //image modal
  const [showImagePreview, setShowImagePreview] = useState(false);
  const [imagePreviewURL, setImagePreviewURL] = useState(false);

  //bgv mobile camera
  const [takeIdPhoto, setTakeIdPhoto] = useState(false);
  const [cameraUploadError, setCameraUploadError] = useState(false);

  //loading id
  const [onLoadingFront, setOnloadingFront] = useState(false);
  const [onLoadingBack, setOnLoadingBack] = useState(false);

  //setting id
  const [Front, setFront] = useState(false);
  const [Back, setBack] = useState(false);

  //error
  const [onFrontError, setFrontError] = useState(false);
  const [onBackError, setBackError] = useState(false);
  const [frontMaxError, setFrontMaxError] = useState(false);
  const [backMaxError, setBackMaxError] = useState(false);
  const [frontFileError, setFrontFileError] = useState(false);
  const [backFileError, setBackFileError] = useState(false);
  const [showPasswordProtectedModal, setShowPasswordProtectedModal] = useState(false);
  const [showAddressDocWarningModal, setShowAddressDocWarningModal] = useState(false);
  const [currentFileFrontOrBack, setCurrentFileFrontOrBack] = useState('');
  const [backPassword, setBackPassword] = useState('');
  const [frontPassword, setFrontPassword] = useState('');
  const [addressMatchPercentageFront, setAddressMatchPercentageFront] = useState();
  const [addressMatchPercentageBack, setAddressMatchPercentageBack] = useState();
  const [frontDocUrl, setFrontDocUrl] = useState('');
  const [backDocUrl, setbackDocUrl] = useState('');
  const [isFrontDocChanged, setFrontDocChanged] = useState();
  const [isBackDocChanged, setBackDocChanged] = useState();
  const [isDocUploadChanged, setDocUploadChanged] = useState(false);
  const [isAddressChanged, setAddressChanged] = useState();
  const [disableSave, setDisableSave] = useState(false);

  //multiupload
  const [isMultiUpload, setIsMultiUpload] = useState(false);
  const [docUpload, setDocUpload] = useState({
    proof_doc: []
  });

  const handleRefreshFront = () => {
    setOnloadingFront(false);
    setFrontMaxError(false);
    setFrontError(false);
    setFieldValue('frontDocUrl', '');
    props.setAddressFrontDoc('');
  };

  const handleRefreshBack = () => {
    setOnLoadingBack(false);
    setBackMaxError(false);
    setBackError(false);
    props.setAddressBackDoc('');
    setFieldValue('backDocUrl', '');
  };

  //adress setting
  useEffect(() => {
    if (!isEmpty(props.addressDocType && isEmpty(props.values.docType))) {
      if (oldIdTypeValues.includes(props.addressDocType)) {
        setFieldValue(
          'docType',
          ADDRESS_DOC_TYPE.find((o) => o.label === MAP_OLD_ADDRESS_DOC_TYPE[props.addressDocType])
            ?.value
        );
      } else {
        setFieldValue('docType', props.addressDocType);
      }
    }
    if (has(props.addressDocFront, 'front') && isEmpty(props.values.frontDocUrl)) {
      setFieldValue('frontDocUrl', props.addressDocFront.front);
      setFieldValue('frontPdf', props.addressDocFront.isPdf);
      setFieldValue('frontDocPassword', props.addressDocFront.password);
      setFrontDocUrl(props.addressDocFront.front);
    }
    if (has(props.addressDocBack, 'back') && isEmpty(props.values.backDocUrl)) {
      setFieldValue('backDocUrl', props.addressDocBack.back);
      setFieldValue('backPdf', props.addressDocBack.isPdf);
      setFieldValue('backDocPassword', props.addressDocBack.password);
      setbackDocUrl(props.addressDocBack.back);
    }

    if (!isEmpty(props.values.frontDocUrl)) {
      setFrontDocUrl(props.values.frontDocUrl);
    }
    if (!isEmpty(props.values.backDocUrl)) {
      setbackDocUrl(props.values.backDocUrl);
    }
    setAddressChanged(
      props?.cachedData?.cacheData?.addressCheck?.[props.index]?.['step1']?.isAddressChanged
    );
  }, []);

  useEffect(() => {}, [frontDocUrl, backDocUrl, docUpload]);
  const singleDocUpdate = () => {
    props.setAddressSameDoc(!props.values.singleDoc);
  };

  //setFrontDLink
  useEffect(() => {
    if (Front) {
      let Id = props.uploadedID;
      setFieldValue('frontDocUrl', Id);
      setFrontDocUrl(Id);
      props.setAddressFrontDoc({
        front: Id,
        isPdf: props.values.frontPdf,
        password: frontPassword
      });
      setOnloadingFront(false);
      setFront(false);
    }
  }, [Front]);
  //setBackIdLink
  useEffect(() => {
    if (Back) {
      let Id = props.uploadedID;
      setFieldValue('backDocUrl', Id);
      setbackDocUrl(Id);
      props.setAddressBackDoc({ back: Id, isPdf: props.values.backPdf, password: backPassword });
      setOnLoadingBack(false);
      setBack(false);
    }
  }, [Back]);

  // Multiupload
  useEffect(() => {
    if (isEmpty(props.values.proofDoc)) {
      setIsMultiUpload(false);
      setDocUpload({ proof_doc: [] });
    } else {
      setIsMultiUpload(true);
      const docUploadCopy = Object.assign({}, { ...docUpload });
      let documents = props.values.proofDoc;
      if (documents.length > 0) {
        docUploadCopy.proof_doc = documents.map((item) => {
          return {
            link: item?.link,
            error: '',
            loading: false,
            isPdf: item?.isPdf || item?.is_pdf,
            password: item?.password
          };
        });
      }
      setDocUpload({ ...docUploadCopy });
    }
  }, []);
  useEffect(() => {
    if (addressMatchPercentageFront) {
      setFieldValue('addressMatchPercentageFront', addressMatchPercentageFront);
    }
  }, [addressMatchPercentageFront]);

  useEffect(() => {
    if (addressMatchPercentageBack) {
      setFieldValue('addressMatchPercentageBack', addressMatchPercentageBack);
    }
  }, [addressMatchPercentageBack]);

  //for mobile cam
  const handleIdPhoto = () => {
    setTakeIdPhoto(!takeIdPhoto);
    setRemoveBack(false);
    setShowRemoveFront(false);
    setCameraUploadError(false);
  };

  //image preiew
  const handleImagePreview = () => {
    setShowImagePreview(!showImagePreview);
  };

  /**
   * Formats an address object into a single-line string.
   *
   * @param {Object} address - The address object to format.
   * @param {string} address.houseNo - The house number of the address.
   * @param {string} address.colony - The colony or neighborhood of the address.
   * @param {string} address.city - The city of the address.
   * @param {string} address.state - The state of the address.
   * @param {string} address.pincode - The postal code of the address.
   * @param {string} address.country - The country of the address.
   * @returns {string} A single-line formatted address string.
   */
  const formatAddressToSingleLine = ({ houseNo, colony, city, state, pincode, country }) =>
    `${houseNo} || ${colony} || ${city} || ${state} || ${country} || ${pincode}`;

  /**
   * Toggles the visibility of the address document warning modal.
   *
   * @returns {void}
   */
  const handleAddressDocumentWarningModalClick = () => {
    setShowAddressDocWarningModal((prev) => !prev);
  };

  //handleUpload for mobile cam
  const handleIdUpload = (formData, isPdf, setCameraSubmitting = null) => {
    //which photo to go where has to be asked
    //we have cam front and cam back
    if (!props.values.frontDocUrl) {
      setOnloadingFront(!onLoadingFront);

      //call s3 link here and get itsresponse and save it in file
      props
        .bgvIdUpload(formData)
        .then(() => {
          setFront(true);
          setCameraSubmitting(false);
          handleIdPhoto();
        })
        .catch((error) => {
          setFrontError(true);
        });
    } else {
      // handleFileSelectBack(formData);
      setOnLoadingBack(!onLoadingBack);
      props
        .bgvIdUpload(formData)
        .then(() => {
          setBack(true);
          setCameraSubmitting(false);
          handleIdPhoto();
        })
        .catch((error) => {
          setBackError(true);
        });
    }
  };

  const modalHide = () => {
    setShowPasswordProtectedModal(false);
    if (currentFileFrontOrBack == 'front') {
      setOnloadingFront(false);
    } else if (currentFileFrontOrBack == 'back') {
      setOnLoadingBack(false);
    }
  };

  const submitHandlerPasswordModal = (password = '') => {
    //Function for handling submit password when unable to unlock the file and when successfully saved the password
    setShowPasswordProtectedModal(false);
    if (currentFileFrontOrBack == 'front') {
      setFieldValue('frontDocPassword', password);
      setFrontPassword(password);
      setFront(true);
    } else if (currentFileFrontOrBack == 'back') {
      setBackPassword(password);
      setFieldValue('backDocPassword', password);
      setBack(true);
    }
  };

  /**
   * Retrieves the current address type data based on the address type and cached data.
   *
   * @returns {Object|null} The data object corresponding to the current address type's step1 check,
   * or `null` if the data is not available.
   */
  const getCurrentAddressTypeData = () => {
    if (props.cachedData.cacheData?.addressCheck?.length > 1) {
      if (props.addressType === 'PERMANENT') {
        return props.cachedData.cacheData?.addressCheck?.[0]?.step1;
      } else {
        return props.cachedData.cacheData?.addressCheck?.[1]?.step1;
      }
    }
    return props.cachedData.cacheData?.addressCheck?.[0]?.step1;
  };

  /**
   * Calculates the address match percentage based on the provided front and back match percentages.
   *
   * @param {number|null} addressMatchPercentageFront - The match percentage for the front of the address document.
   * @param {number|null} addressMatchPercentageBack - The match percentage for the back of the address document.
   * @returns {number|null} The calculated address match percentage. If a single document is used, returns the front match percentage.
   * If both are null, returns null. Otherwise, returns the maximum of the provided percentages.
   */
  const getAddressMatchPercentage = (
    addressMatchPercentageFront,
    addressMatchPercentageBack,
    addressMatchPercentageFull
  ) => {
    if (props.values.singleDoc) {
      return addressMatchPercentageFront;
    }
    if (
      addressMatchPercentageFront == null &&
      addressMatchPercentageBack == null &&
      addressMatchPercentageFull == null
    ) {
      return null;
    }

    return Math.max(
      addressMatchPercentageFront ?? -1,
      addressMatchPercentageBack ?? -1,
      addressMatchPercentageFull ?? -1
    );
  };

  /**
   * Constructs form data for document validation using the provided URL and address data.
   *
   * @function getDocumentValidationFormData
   * @param {string} url - The URL of the document to validate.
   * @param {Object} addressData - The address data to include in the form.
   * @returns {FormData} A FormData object containing the address, document type, and URL.
   *
   */
  const getDocumentValidationFormData = (url, addressData) => {
    const address = formatAddressToSingleLine(addressData);
    let formData = new FormData();
    formData.append('address', address);
    formData.append('doc_type', props?.values?.docType);
    formData.append('url', url);
    return formData;
  };

  /**
   * Validates the front document using the provided form data.
   *
   * @async
   * @function runDocumentFrontValidation
   * @param {FormData} formData - The form data containing the address and document information.
   * @returns {Promise<Object>} A promise that resolves to the response from the validation service.
   *
   */
  const runDocumentFrontValidation = async (formData) => {
    const response = await props.validateDocument(formData);
    const percentage = response?.data?.addressMatchPercentage;
    setAddressMatchPercentageFront(percentage);
    return response;
  };

  /**
   * Validates the back document using the provided form data.
   *
   * @async
   * @function runDocumentBackValidation
   * @param {FormData} formData - The form data containing the address and document information.
   * @returns {Promise<Object>} A promise that resolves to the response from the validation service.
   *
   */
  const runDocumentBackValidation = async (formData) => {
    const response = await props.validateDocument(formData);
    const percentage = response?.data?.addressMatchPercentage;
    setAddressMatchPercentageBack(percentage);
    return response;
  };

  /**
   * Runs the address document validation process, checking if the document or address has changed.
   *
   * This function validates the front and back documents based on the current address type data
   * and whether any documents have changed. It returns the results of the validation along with
   * a flag indicating if a warning should be displayed.
   *
   * @async
   * @function runAddressDocumentValidation
   * @returns {Promise<Object>} A promise that resolves to an object containing the front and back
   *                              validation responses and a display warning flag.
   *
   */
  const runAddressDocumentValidation = async () => {
    const addressData = getCurrentAddressTypeData();

    const hasDocumentChanged = isFrontDocChanged || isBackDocChanged || isDocUploadChanged;
    let frontResponse = null,
      backResponse = null,
      fullDocResponse = null,
      displayWarning = false;
    if (hasDocumentChanged) {
      if (isFrontDocChanged) {
        const formData = getDocumentValidationFormData(frontDocUrl, addressData);
        frontResponse = await runDocumentFrontValidation(formData);
        displayWarning = true;
        setFrontDocChanged(false);
      }
      if (isBackDocChanged) {
        const formData = getDocumentValidationFormData(backDocUrl, addressData);
        backResponse = await runDocumentBackValidation(formData);
        displayWarning = true;
        setBackDocChanged(false);
      }
      if (isDocUploadChanged) {
        fullDocResponse = await processProofDocs(docUpload.proof_doc, addressData);
        displayWarning = true;
        setDocUploadChanged(false);
      }
    } else if (isAddressChanged) {
      if (frontDocUrl) {
        const formData = getDocumentValidationFormData(frontDocUrl, addressData);
        frontResponse = await runDocumentFrontValidation(formData);
        displayWarning = true;
      }
      if (backDocUrl) {
        const formData = getDocumentValidationFormData(backDocUrl, addressData);
        backResponse = await runDocumentBackValidation(formData);
        displayWarning = true;
      }
      if (docUpload.proof_doc.length > 0) {
        fullDocResponse = await processProofDocs(docUpload.proof_doc, addressData);
        displayWarning = true;
        setDocUploadChanged(false);
      }
      setAddressChanged(false);
    }

    return {
      frontResponse: frontResponse?.data,
      backResponse: backResponse?.data,
      fullDocResponse: fullDocResponse?.data,
      displayWarning
    };
  };
  const processProofDocs = async (proofDocArray, addressData) => {
    let highestResponse = { isAttested: true, addressMatchPercentage: null };

    for (const doc of proofDocArray) {
      const formData = getDocumentValidationFormData(doc.link, addressData);
      const response = await runDocumentBackValidation(formData);

      if (
        response &&
        response.addressMatchPercentage !== null &&
        (highestResponse.addressMatchPercentage === null ||
          response.addressMatchPercentage > highestResponse.addressMatchPercentage)
      ) {
        highestResponse = response;
      }
    }

    return highestResponse;
  };
  /**
   * Handles the form submission, including validation of address match percentage.
   *
   * @param {Event} e - The form submission event.
   * @returns {void} If the address match percentage is below a certain threshold or not available,
   * shows a warning modal and prevents form submission.
   * Otherwise, proceeds with form submission using the parent component's handleSubmit function.
   */
  const handleSubmit = async (e) => {
    setDisableSave(true);
    try {
      const { frontResponse, backResponse, fullDocResponse, displayWarning } =
        await runAddressDocumentValidation();
      const isAttested =
        frontResponse?.isAttested || backResponse?.isAttested || fullDocResponse?.isAttested;
      const addressMatchPercentage = getAddressMatchPercentage(
        frontResponse?.addressMatchPercentage,
        backResponse?.addressMatchPercentage,
        fullDocResponse?.addressMatchPercentage
      );
      if (
        isAttested &&
        (!addressMatchPercentage || addressMatchPercentage < 60) &&
        displayWarning
      ) {
        setDisableSave(false);
        setShowAddressDocWarningModal(true);
        return;
      }
      setDisableSave(false);
      props.handleSubmit(e);
    } catch (err) {
      setDisableSave(false);
      props.handleSubmit(e);
    }
  };

  const handleIgnoreAndSave = (e) => {
    setShowAddressDocWarningModal(false);
    props.handleSubmit(e);
  };

  //Id Front upload
  const handleFileSelectFront = (e, type, index) => {
    setFieldValue('frontDocPassword', '');
    let fileType = '';
    var blob = e.target.files[0];
    var fileReader = new FileReader();
    fileReader.onloadend = () => {
      setFrontError(false);
      var arr = new Uint8Array(fileReader.result).subarray(0, 4);
      var header = '';
      for (var i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      // Check the file signature against known types
      fileType = getFileHeader(header);
      const errMessage = validateImage(blob, fileType);
      const isPdf = fileType === 'application/pdf' ? true : false;
      if (isPdf) {
        setFieldValue('frontPdf', true);
      } else {
        setFieldValue('frontPdf', false);
      }

      if (!errMessage) {
        let formData = new FormData();
        formData.append(`file`, blob);
        formData.append(`file_type`, 'CHECK_DOCUMENT');
        formData.append('delete_url', frontDocUrl);
        setOnloadingFront(!onLoadingFront);
        props
          .bgvIdUpload(formData)
          .then(() => {
            try {
              if (isPdf) {
                const loadingTask = pdfjs.getDocument({ data: fileReader.result, password: '' });
                loadingTask.promise
                  .then((pdfDocument) => {
                    setCurrentFileFrontOrBack('');
                    setFront(true);
                  })
                  .catch((error) => {
                    if (error.message == 'No password given') {
                      setCurrentFileFrontOrBack('front');
                      setShowPasswordProtectedModal(true);
                    }
                  });
              } else {
                setCurrentFileFrontOrBack('');
                setFront(true);
              }
              setFrontDocChanged(true);
            } catch (e) {
              console.log(e);
            }
          })
          .catch((error) => {
            setFrontError(true);
            setFrontFileError(true);
            setOnloadingFront(false);
          });
      } else {
        setFrontError(true);
        errMessage === 'FILE_SIZE_EXCEEDS' ? setFrontMaxError(true) : setFrontFileError(true);
      }
    };

    if (blob instanceof Blob) {
      {
        /** To avoid TyperError issue reported by sentry, need to check if param is an Blob type or not */
      }
      fileReader.readAsArrayBuffer(blob);
    } else {
      console.error(
        `Failed to execute 'readAsArrayBuffer' on 'FileReader': parameter is not of type 'Blob'`
      );
    }
  };

  //Id back upload
  const handleFileSelectBack = (e, type, index) => {
    setFieldValue('backDocPassword', '');
    let fileType = '';
    var blob = e.target.files[0];
    var fileReader = new FileReader();
    fileReader.onloadend = () => {
      var arr = new Uint8Array(fileReader.result).subarray(0, 4);
      var header = '';
      for (var i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      // Check the file signature against known types
      fileType = getFileHeader(header);
      const errMessage = validateImage(blob, fileType);
      const isPdf = fileType === 'application/pdf' ? true : false;
      if (isPdf) {
        setFieldValue('backPdf', true);
      } else {
        setFieldValue('backPdf', false);
      }

      if (!errMessage) {
        let formData = new FormData();
        formData.append(`file`, blob);
        formData.append(`file_type`, 'CHECK_DOCUMENT');
        formData.append('delete_url', backDocUrl);
        setOnLoadingBack(!onLoadingBack);
        props
          .bgvIdUpload(formData)
          .then(() => {
            try {
              if (isPdf) {
                const loadingTask = pdfjs.getDocument({ data: fileReader.result, password: '' });
                loadingTask.promise
                  .then((pdfDocument) => {
                    setCurrentFileFrontOrBack('');
                    setBack(true);
                  })
                  .catch((error) => {
                    if (error.message == 'No password given') {
                      setCurrentFileFrontOrBack('back');
                      setShowPasswordProtectedModal(true);
                    }
                  });
              } else {
                setCurrentFileFrontOrBack('');
                setBack(true);
              }
            } catch (e) {
              console.log(e);
            }
            setBackDocChanged(true);
          })
          .catch((error) => {
            setBackError(true);
            setBackFileError(true);
            setOnLoadingBack(false);
          });
      } else {
        setBackError(true);
        errMessage === 'FILE_SIZE_EXCEEDS' ? setBackMaxError(true) : setBackFileError(true);
      }
    };

    if (blob instanceof Blob) {
      {
        /** To avoid TyperError issue reported by sentry, need to check if param is an Blob type or not */
      }
      fileReader.readAsArrayBuffer(blob);
    } else {
      console.error(
        `Failed to execute 'readAsArrayBuffer' on 'FileReader': parameter is not of type 'Blob'`
      );
    }
  };

  const updateDocUpload = (newDocs) => {
    setDocUpload(newDocs);
    setDocUploadChanged(true);
  };

  //delete uploaded ID
  const DeleteLink = (IdSide) => {
    if (IdSide === 'FRONT') {
      setShowRemoveFront(false);
      setFieldValue('frontDocUrl', '');
      props.setAddressFrontDoc('');
    } else {
      setRemoveBack(false);
      props.setAddressBackDoc('');
      setFieldValue('backDocUrl', '');
    }
  };

  const isMutiImageHavingError = () => {
    let isImageHavingError = false;
    for (let value of docUpload.proof_doc) {
      if (!value?.link || value?.error || value?.loading) {
        isImageHavingError = true;
        break;
      }
    }
    return isImageHavingError;
  };

  const isMultiUploadFormValid =
    docUpload.proof_doc.length > 0 && !isMutiImageHavingError() && !isEmpty(props.values.docType);

  const isOtherDetailsUploaded = props.values.docType === 'others' && !props.values.otherDetails;
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (isMultiUpload) {
      let cloneDocs = [];
      for (let value of docUpload.proof_doc) {
        if (value.link) {
          cloneDocs.push({ link: value.link, isPdf: value.isPdf, password: value.password });
        }
      }
      setFieldValue('proof_doc', cloneDocs);
    } else {
      setFieldValue('proof_doc', []);
    }
    handleSubmit();
  };
  const DocumentWarningModal = () => (
    <Modal
      size={440}
      show={props.show}
      radius={12}
      onHide={props.onHide}
      backdrop={'static'}
      centered
    >
      <div className={styles.bgvAddressDocumentWarning}>
        <span
          className={styles.warningModalCloseButton}
          onClick={handleAddressDocumentWarningModalClick}
        >
          <CloseButton />
        </span>
        <span>
          <img src={ModalWarningSvg} />
        </span>
        <span className={styles.modalWarningHeader}>Issue Detected</span>
        <span>
          The address on the uploaded document does not exactly match the address provided in the
          form. Please ensure that you upload the correct document proof.
        </span>
        <div className={styles.modalWarningButtonContain}>
          <Button className={styles.ignoreSaveButton} onClick={handleIgnoreAndSave}>
            Ignore & Save
          </Button>
          <Button
            className={styles.modalWarningButton}
            onClick={handleAddressDocumentWarningModalClick}
          >
            Upload again
          </Button>
        </div>
      </div>
    </Modal>
  );

  //do changes in contaire file

  return (
    <Modal
      size={showPasswordProtectedModal ? 'sm' : 'lg'}
      show={props.show}
      onHide={props.onHide}
      dialogClassName='bgvModalDialog'
      className='bgvModal'
      backdropClassName={'customBGVModalBackdrop'}
      centered
    >
      {showAddressDocWarningModal ? <DocumentWarningModal /> : null}
      <div style={showPasswordProtectedModal ? { display: 'none' } : null}>
        {/* Adding this div to fix the overlapping issue */}
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <div className={styles.step3Title}>
              {`${props.title}  ${
                props.addressType === 'ANY ADDRESS'
                  ? props?.cachedData?.cacheData?.addressCheck[0].step1.addressType
                  : capitalize(props.addressType) + ' Address'
              }`}
            </div>
            <div className={styles.step3SubTitle}>
              {`Step ${props.currentModalSubStep} of ${props.totalStep}`}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modalBody}>
          <Form>
            <Form.Row>
              {isMobileOnly ? (
                <div className={styles.warningHeading}>Add your ID proof here</div>
              ) : (
                <div className={styles.warningHeading}>Add your Address proof here</div>
              )}
            </Form.Row>
            <Form.Row>
              <Col md={12} lg={12}>
                <p className={styles.warningSubHeading}>
                  Please make sure you upload a clear picture of both sides of Id. Upload .jpg,
                  .jpeg, .png or .pdf files only.
                </p>
              </Col>
            </Form.Row>
            {/* <Form.Row className={styles.Row}>
            </Form.Row> */}
            <Form.Row>
              <Col md={6} lg={6}>
                <Form.Label>
                  Select your document type<span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <Select
                  menuPosition='fixed'
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  options={ADDRESS_DOC_TYPE}
                  value={ADDRESS_DOC_TYPE.find((o) => o.value === props?.values?.docType) || ''}
                  name='docType'
                  onChange={(value) => {
                    if (oldIdTypeValues.includes(value.label)) {
                      setIsMultiUpload(false);
                      setDocUpload({ proof_doc: [] });
                    } else {
                      setIsMultiUpload(true);
                      handleRefreshFront();
                      handleRefreshBack();
                    }
                    setFieldValue('docType', value.value);
                    props?.setAddressDocType(value.value);
                  }}
                />
                {props.errors.docType && <div className={styles.error}>{props.errors.docType}</div>}
              </Col>
              {props.values.docType === 'others' && (
                <Col md={6} lg={6}>
                  <Form.Label>
                    Please enter document name<span style={{ color: 'red' }}>*</span>
                  </Form.Label>
                  <Field
                    component={ValidatedFormInputField}
                    type='text'
                    placeholder='What document is this?'
                    name='otherDetails'
                  />
                </Col>
              )}
            </Form.Row>

            {props.values.docType && (
              <>
                {isMultiUpload ? (
                  <MultiUploadForm
                    isMobileOnly={isMobileOnly}
                    docUpload={docUpload}
                    setDocUpload={updateDocUpload}
                    educationGradesheetUpload={props.educationGradesheetUpload}
                    deleteGradesheetUpload={props.deleteGradesheetUpload}
                    isImageHavingError={isMutiImageHavingError}
                  />
                ) : (
                  <>
                    <Form.Row className={styles.Row}>
                      {isMobileOnly ? (
                        <Form.Label>
                          Add a front and back of your ID<span style={{ color: 'red' }}>*</span>
                        </Form.Label>
                      ) : (
                        <Form.Label>
                          Add document<span style={{ color: 'red' }}>*</span>
                        </Form.Label>
                      )}
                    </Form.Row>
                    {isMobileOnly && (
                      <Form.Row className={styles.Row}>
                        <Button
                          className={styles.addBtn}
                          disabled={
                            props.values.frontDocUrl &&
                            (props.values.singleDoc || props.values.backDocUrl) &&
                            true
                          }
                          onClick={() => {
                            handleIdPhoto();
                          }}
                        >
                          Take Photo of Address proof
                        </Button>
                      </Form.Row>
                    )}
                    {isMobileOnly && takeIdPhoto && (
                      <BGVCamera
                        type='single'
                        showBgvCameraModal={takeIdPhoto}
                        handleCameraModal={handleIdPhoto}
                        handleSingleUpload={handleIdUpload}
                        isCameraUploadError={cameraUploadError}
                      />
                    )}
                    <Form.Row>
                      <Col md={4} lg={4}>
                        {/* Id Front here conditions will come   */}
                        <div
                          className={styles.idPlaceHolder}
                          style={{ background: onFrontError && '#000000' }}
                        >
                          {!props.values.frontDocUrl ? (
                            <>
                              {/* onloading  */}
                              {onLoadingFront && (
                                <Spinner
                                  animation='border'
                                  variant='primary'
                                  style={{ margin: '35px 85px' }}
                                />
                              )}
                              {/* onErrorhandling is left */}

                              {/* not on loading not on error  */}
                              {!onLoadingFront && onFrontError ? (
                                <>
                                  <div
                                    className={
                                      frontMaxError || frontFileError
                                        ? styles.idMaxError
                                        : styles.idUploadText
                                    }
                                  >
                                    <Image name='BgvErrorUploadDoc.png' />
                                    {frontMaxError ? (
                                      <div className={styles.errorTag}>
                                        {'Upload Failed,please upload a file that exceeds ' +
                                          AppConstants.fileSize +
                                          ' KB'}
                                      </div>
                                    ) : (
                                      <div className={styles.errorTag}>
                                        Upload Failed.{' '}
                                        {frontFileError ? 'File-type not supported' : ''}
                                      </div>
                                    )}

                                    <div
                                      className={styles.errorRefresh}
                                      onClick={handleRefreshFront}
                                    >
                                      <Image name='BgvErrorRefresh.png' />
                                    </div>
                                  </div>
                                </>
                              ) : (
                                !onLoadingFront && (
                                  <>
                                    <label
                                      style={{ cursor: 'pointer', width: '210px', height: '110px' }}
                                    >
                                      <div className={styles.idUploadText}>
                                        <Image name='BgvIdFront.png' />

                                        {props.values.singleDoc ? (
                                          <div className={styles.tag}>Add document </div>
                                        ) : (
                                          <div className={styles.tag}>Add Front of your Id </div>
                                        )}
                                      </div>
                                      <Form.Control
                                        id='formControlsFile'
                                        accept='image/*,application/pdf'
                                        className={styles.attachDocBtn}
                                        type='file'
                                        label='File'
                                        onChange={handleFileSelectFront}
                                      />
                                    </label>
                                  </>
                                )
                              )}
                            </>
                          ) : (
                            //when image is present
                            // pdf handling is left
                            <>
                              <div className={styles.uploadFunctionality}>
                                <div className={styles.functionBtn}>
                                  {props.values.frontPdf ? (
                                    <>
                                      {' '}
                                      <a
                                        href={`${AppConstants.developmentAPIURL}auth/bgv-docs?token=${token}&url=${props.values.frontDocUrl}`}
                                        target='_blank'
                                        rel='noreferrer'
                                      >
                                        <Image
                                          style={{ marginLeft: '5px', cursor: 'pointer' }}
                                          name='BgvIdView.png'
                                        />
                                      </a>{' '}
                                    </>
                                  ) : (
                                    <Image
                                      style={{ marginLeft: '5px', cursor: 'pointer' }}
                                      onClick={() => {
                                        handleImagePreview();
                                        setImagePreviewURL(props.values.frontDocUrl);
                                      }}
                                      name='BgvIdView.png'
                                    />
                                  )}
                                </div>
                                <div
                                  className={styles.functionBtn}
                                  onClick={() => {
                                    setShowRemoveFront(true);
                                  }}
                                  style={{ background: showRemoveFront && 'transparent' }}
                                >
                                  <Image
                                    style={{
                                      display: showRemoveFront && 'none',
                                      marginLeft: '5px',
                                      cursor: 'pointer'
                                    }}
                                    name='BgvIdDelete.png'
                                  />
                                  {showRemoveFront && (
                                    // here call delete api
                                    <Badge
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        DeleteLink('FRONT');
                                      }}
                                      pill
                                      variant='danger'
                                    >
                                      Remove ?
                                    </Badge>
                                  )}
                                </div>
                              </div>
                              {props.values.frontPdf ? (
                                <Image
                                  name='BgvIdPdf.png'
                                  style={{ margin: '35px 0px 0px 90px', cursor: 'pointer' }}
                                />
                              ) : (
                                <Image
                                  src={`${AppConstants.developmentAPIURL}auth/bgv-docs?token=${token}&url=${props.values.frontDocUrl}`}
                                  style={{ width: '210px', height: '115px', cursor: 'pointer' }}
                                />
                              )}
                              {props.values.frontDocPassword && (
                                <OverlayTrigger
                                  key={`password_protected`}
                                  placement='top'
                                  overlay={
                                    <Tooltip id={`password_protected`}>
                                      <p>Password is saved</p>
                                    </Tooltip>
                                  }
                                >
                                  <div className={styles.passwordProtectIcon}>
                                    <Image
                                      style={{
                                        marginRight: '5px',
                                        cursor: 'pointer',
                                        height: '24px',
                                        width: '24px'
                                      }}
                                      name='passwordSaved.png'
                                    />
                                  </div>
                                </OverlayTrigger>
                              )}
                            </>
                          )}
                        </div>
                      </Col>
                      {!props.values.singleDoc && (
                        <Col md={6} lg={4}>
                          {/* Id Back here condition will come  */}
                          <div
                            className={styles.idPlaceHolder}
                            style={{ background: onBackError && '#000000' }}
                          >
                            {!props.values.backDocUrl ? (
                              <>
                                {/* onloading  */}
                                {onLoadingBack && (
                                  <Spinner
                                    animation='border'
                                    variant='primary'
                                    style={{ margin: '35px 85px' }}
                                  />
                                )}

                                {/* on error handling pending  */}

                                {/* not on loding or not on error  */}
                                {!onLoadingBack && onBackError ? (
                                  <>
                                    <div
                                      className={
                                        backMaxError || backFileError
                                          ? styles.idMaxError
                                          : styles.idUploadText
                                      }
                                    >
                                      <Image name='BgvErrorUploadDoc.png' />
                                      {backMaxError ? (
                                        <div className={styles.errorTag}>
                                          {'Upload Failed,please upload a file that exceeds ' +
                                            AppConstants.fileSize +
                                            ' KB'}
                                        </div>
                                      ) : (
                                        <div className={styles.errorTag}>
                                          Upload Failed.{' '}
                                          {backFileError ? 'File-type not supported' : ''}
                                        </div>
                                      )}
                                      <div
                                        className={styles.errorRefresh}
                                        onClick={handleRefreshBack}
                                      >
                                        <Image
                                          name='BgvErrorRefresh.png'
                                          style={{ cursor: 'pointer' }}
                                        />
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  !onLoadingBack && (
                                    <>
                                      <label
                                        style={{
                                          cursor: 'pointer',
                                          width: '210px',
                                          height: '110px'
                                        }}
                                      >
                                        <div className={styles.idUploadText}>
                                          <Image name='BgvIdBack.png' />

                                          <div className={styles.tag}>Add Back of your Id </div>
                                        </div>
                                        <Form.Control
                                          id='formControlsFile'
                                          className={styles.attachDocBtn}
                                          accept='image/*,application/pdf'
                                          type='file'
                                          label='File'
                                          onChange={handleFileSelectBack}
                                        />
                                      </label>
                                    </>
                                  )
                                )}
                              </>
                            ) : (
                              // when the doc is added
                              // pdf handling is pending
                              <>
                                <div className={styles.uploadFunctionality}>
                                  <div className={styles.functionBtn}>
                                    {props.values.backPdf ? (
                                      <>
                                        {' '}
                                        <a
                                          href={`${AppConstants.developmentAPIURL}auth/bgv-docs?token=${token}&url=${props.values.backDocUrl}`}
                                          target='_blank'
                                          rel='noreferrer'
                                        >
                                          <Image
                                            style={{ marginLeft: '5px', cursor: 'pointer' }}
                                            name='BgvIdView.png'
                                          />
                                        </a>{' '}
                                      </>
                                    ) : (
                                      <Image
                                        style={{ marginLeft: '5px', cursor: 'pointer' }}
                                        onClick={() => {
                                          handleImagePreview();
                                          setImagePreviewURL(props.values.backDocUrl);
                                        }}
                                        name='BgvIdView.png'
                                      />
                                    )}
                                  </div>
                                  <div
                                    className={styles.functionBtn}
                                    onClick={() => {
                                      setRemoveBack(true);
                                    }}
                                    style={{ background: showRemoveBack && 'transparent' }}
                                  >
                                    <Image
                                      style={{
                                        display: showRemoveBack && 'none',
                                        marginLeft: '5px',
                                        cursore: 'pointer'
                                      }}
                                      name='BgvIdDelete.png'
                                    />
                                    {showRemoveBack && (
                                      // here call delete api
                                      <Badge
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                          DeleteLink('BACK');
                                        }}
                                        pill
                                        variant='danger'
                                      >
                                        Remove ?
                                      </Badge>
                                    )}
                                  </div>
                                </div>
                                {props.values.backDocPassword && (
                                  <OverlayTrigger
                                    key={`password_protected`}
                                    placement='top'
                                    overlay={
                                      <Tooltip id={`password_protected`}>
                                        <p>Password is saved</p>
                                      </Tooltip>
                                    }
                                  >
                                    <div className={styles.passwordProtectIcon}>
                                      <Image
                                        style={{
                                          marginRight: '5px',
                                          cursor: 'pointer',
                                          height: '24px',
                                          width: '24px'
                                        }}
                                        name='passwordSaved.png'
                                      />
                                    </div>
                                  </OverlayTrigger>
                                )}
                                {props.values.backPdf ? (
                                  <Image
                                    name='BgvIdPdf.png'
                                    style={{ margin: '35px 0px 0px 90px', cursor: 'pointer' }}
                                  />
                                ) : (
                                  <Image
                                    src={`${AppConstants.developmentAPIURL}auth/bgv-docs?token=${token}&url=${props.values.backDocUrl}`}
                                    style={{ width: '210px', height: '115px', cursor: 'pointer' }}
                                  />
                                )}
                              </>
                            )}
                          </div>
                        </Col>
                      )}
                      <Col md={6} lg={4}></Col>
                    </Form.Row>

                    <Form.Row className={styles.Row}>
                      <Col style={{ color: '#BFBFBF' }}>
                        <Field
                          className={styles.consentLabel}
                          custom
                          onClick={singleDocUpdate}
                          name='singleDoc'
                          component={Checkbox}
                          label='I have images of both sides of my ID in a single file.'
                        />
                      </Col>
                    </Form.Row>
                  </>
                )}
              </>
            )}
          </Form>
          <ImagePreviewModal
            showImagePreviewModal={showImagePreview}
            handleImagePreview={handleImagePreview}
            imagePreviewUrl={imagePreviewURL}
          />
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
          <Button className={'bgvModalSecondaryBtn'} onClick={() => props.prevSubStep()}>
            Go back
          </Button>
          <Button
            className={'bgvModalPrimaryBtn'}
            disabled={
              (disableSave || isOtherDetailsUploaded || !isEmpty(props.errors)
                ? true
                : props.values.frontDocUrl
                ? props.values.singleDoc
                  ? false
                  : props.values.backDocUrl
                  ? false
                  : true
                : true) && !isMultiUploadFormValid
            }
            onClick={handleFormSubmit}
          >
            {disableSave ? (
              <Spinner
                animation='border'
                variant='dark'
                style={{ verticalAlign: 'middle', scale: '0.5' }}
              />
            ) : (
              'Save'
            )}
          </Button>
        </Modal.Footer>
      </div>
      {showPasswordProtectedModal && (
        <PasswordProtectedModal
          submitHandler={submitHandlerPasswordModal}
          subShow={showPasswordProtectedModal}
          checkType={'address'}
          SubOnHide={modalHide}
        />
      )}
    </Modal>
  );
};
