import React, { useState, useEffect } from 'react';
import storage from '../../api/localStorage';
import { Row, Col, Container } from 'react-bootstrap';
import SidePanel from './BgvBodyContainer/BgvSidePanel';
import StepContainer from './BgvBodyContainer/BgvStepContainer';
import styles from './BgvHome.module.scss';
import { isEmpty, truncate } from 'lodash';
import { toast } from 'react-toastify';
import { isMobileOnly } from 'react-device-detect';
import CustomSpinner from '../../core-components/CustomSpinner';
import { Helmet } from 'react-helmet';
import BgvHomeModal from './BgvHomeModal';
import BgvHeader from './BgvHeader';
import BgvFooter from './BgvFooter';
import {
  errToastMessage,
  getRefEmpCount,
  getTotalCheckCount,
  jwtParser,
  toCamelCase
} from '../../utils/utilities';
import validator from './BgvSubmit.validator';
import logger from '../../core-components/Logger';
import { getErrorMessage } from '../../utils/utilities';
import {
  setBgvSubmission as setBgvSubmissionAPI,
  getBgvCache as getBgvCacheAPI,
  getBgvCandidateDetails
} from '../../api/bgv';
import {
  isMobile,
  browserName,
  browserVersion,
  osName,
  osVersion,
  getUA
} from 'react-device-detect';
import { AddressArrLabelObj, CANDIDATE_STATUS } from '../../utils/commonConstant';
import { BGV_ERROR } from '../../utils/commonConstant';
import BgvSidebar from './BgvSidebar/BgvSidebar';
import ReferAndEarn from './BgvBodyContainer/BgvStepBodyContianer/ReferAndEarn';

export default function BgvHome(props) {
  const { location, config, cached_data, initial_candidate_details, history, referralData } = props;
  const isConsentOnly = location.pathname === '/candidate/consent';
  const BGVStartDate = new Date();
  const [loading, setLoading] = useState(true);
  const [showHomeModal, setShowHomeModal] = useState(true);
  const [step, setStep] = useState(String(-1));
  const [sidePanel, setSidePanel] = useState([]);
  const [isNestedStep, setIsNestedStep] = useState(false);
  const [nestedStepValue, setNestedStepValue] = useState(null);
  const [isCompany, setIsCompany] = useState(false);
  const [candidateId, setCandidateId] = useState(null);
  const [firstLoad, setFirstLoad] = useState(false);
  const [token, setToken] = useState(null);
  const [internalLoading, setInternalLoading] = useState(false);
  const [browserDetails, setBrowserDetails] = useState({});
  const [isShowFeedBackPage, setIsShowFeedBackPage] = useState(true);
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const FeedbackState = String(-4);
  useEffect(() => {
    setBrowserDetails({
      browser: `${browserName} (${browserVersion})`,
      os: `${osName} (${osVersion})`,
      device: `${isMobile ? 'Mobile' : 'Browser'}`,
      userAgent: getUA
    });
    window.scroll(0, 0);
    const bgvtoken = location.search && location.search.split('=')[1];
    setToken(bgvtoken);
    const parsedJwtDetail = jwtParser(bgvtoken);
    setCandidateId(
      !isEmpty(parsedJwtDetail?.data) && parsedJwtDetail?.data?.candidateId
        ? parsedJwtDetail?.data?.candidateId
        : null
    );
    let isValid = true;

    if (location.pathname.includes('company')) {
      const currentUserData = jwtParser(storage.getToken());
      if (currentUserData?.data?.companyId !== parsedJwtDetail?.data?.companyId) {
        isValid = false;
        history.push('/unauthorized');
      } else {
        storage.setBGVToken(token);
        setIsCompany(true);
      }
    } else {
      if (storage.getToken()) {
        isValid = false;
        history.push('/error');
      } else {
        storage.clearStorage();
        storage.setBGVToken(token);
      }
    }

    if (isValid) {
      props.getBgvCandidateDetails();

      if (!initial_candidate_details.loading && initial_candidate_details.error) {
        errToastMessage(initial_candidate_details.error);
      } else if (!isConsentOnly) {
        props.getBgvCache();
        props.getBgvPackageConfig().catch((error) => {
          console.error(getErrorMessage(error));
        });
      }
      sessionStorage.setItem('reload', 'true');
    }

    updateFeedbackState(parsedJwtDetail);
    return () => {
      props.invalidateBgvCandidateDetails();
      props.invalidateBgvPackageConfig();
      props.invalidateBgvCache();
    };
  }, []);

  const updateFeedbackState = async (parsedJwtDetail) => {
    try {
      const resp = await getBgvCandidateDetails();
      const isFilledByCa = parsedJwtDetail?.data?.formFilledBy === 2;

      const data = resp?.data?.data;
      const basicDetails = data?.basic_details;

      if (
        isFilledByCa &&
        basicDetails &&
        basicDetails?.status === CANDIDATE_STATUS.CONSENT_MISSING &&
        !isEmpty(basicDetails.form_filled)
      ) {
        setIsShowFeedBackPage(false);
      } else if (basicDetails && basicDetails.status === CANDIDATE_STATUS.PROCESSING) {
        setIsShowFeedBackPage(false);
      } else if (
        basicDetails &&
        !isEmpty(basicDetails.form_filled) &&
        basicDetails.status === CANDIDATE_STATUS.IN_PROGRESS
      ) {
        setIsShowFeedBackPage(false);
      }
    } catch (e) {}
  };

  // Useffect to check if we have received the response from API atleast once and updated redux state,
  // Since we set loading state to false when fetch responds, hence checking for false to true and then false
  // which means we have fetched from API at least once, which is at the mounting phase of the component
  useEffect(() => {
    if (
      !initial_candidate_details.loading &&
      !initial_candidate_details.error &&
      !cached_data &&
      !isConsentOnly
    ) {
      logger.push({
        message: `Candidate Basic Details at starting of ${candidateId}`,
        data: JSON.stringify(initial_candidate_details),
        category: `candidate-id-${candidateId}`,
        type: 'info'
      });
      logger.push({
        message: `BGV Configuration at starting of ${candidateId}`,
        data: JSON.stringify(config),
        category: `candidate-id-${candidateId}`,
        type: 'info'
      });
    }
    if (!isConsentOnly) {
      if (
        (!config && !cached_data && !initial_candidate_details.error) ||
        (config?.loading &&
          (!cached_data || cached_data?.loading) &&
          initial_candidate_details?.loading)
      ) {
        setFirstLoad(true);
      }
      if (firstLoad) {
        if (
          !initial_candidate_details.loading &&
          !initial_candidate_details.error &&
          config &&
          (!cached_data || (cached_data && !cached_data.loading)) &&
          !config.loading
        ) {
          setLoading(false);
          const currentStep = cached_data?.current_state?.split('#');
          const overallStatusFromCandidateBasicDetails =
            initial_candidate_details?.basic_details?.status;

          let newStep = (currentStep && currentStep[0]) || String(-1);

          // If overall status is form-submitted, then show feedback page
          if (overallStatusFromCandidateBasicDetails === 4) newStep = String(-4);
          if (overallStatusFromCandidateBasicDetails === 11) newStep = String(-11);

          setStep(newStep);

          if (currentStep && currentStep?.length > 1) {
            setIsNestedStep(true);
            setNestedStepValue(currentStep[1]);
          }
          createSidePanelSequence();
        }
      }
    } else {
      if (!initial_candidate_details.error || initial_candidate_details?.loading) {
        setFirstLoad(true);
      }
      if (firstLoad) {
        if (!initial_candidate_details.error && !initial_candidate_details.loading) {
          getConsentOnly(token);
        }
      }
    }
  }, [cached_data, config, initial_candidate_details, firstLoad]);

  useEffect(() => {
    createSidePanelSequence();
  }, [step]);

  const getConsentOnly = (token) => {
    let details = initial_candidate_details.basic_details;
    let status = details.status;
    const parsedJwtDetail = jwtParser(token);
    setStep(
      (status === 11 || !details.consent_letter_url) && isConsentOnly ? String(-3) : FeedbackState
    );
    setIsNestedStep(false);
    setNestedStepValue(null);
    setLoading(false);
    setCandidateId(
      !isEmpty(parsedJwtDetail?.data) && parsedJwtDetail?.data?.candidateId
        ? parsedJwtDetail?.data?.candidateId
        : null
    );
  };

  const containerStyle = () => {
    if (showSidePanel(initial_candidate_details)) {
      return {
        width: '100%',
        ...(!isMobileOnly ? { minHeight: '700px' } : { minHeight: 'unset' })
      };
    } else {
      return {
        justifyContent: 'center',
        width: '100%',
        ...(!isMobileOnly ? { minHeight: '700px' } : { minHeight: 'unset' })
      };
    }
  };

  const showSidePanel = (initial_candidate_details) => {
    if (isConsentOnly) {
      return false;
    } else if (
      initial_candidate_details?.basic_details &&
      initial_candidate_details?.basic_details.status !== 3
    ) {
      return false;
    } else if (isMobileOnly) {
      return false;
    } else if (step === FeedbackState) {
      return false;
    } else {
      return true;
    }
  };

  const createSidePanelSequence = () => {
    let sidePanelItems = [];
    sidePanelItems.push({
      id: -1,
      img: 'BasicDetailsBGV',
      text: 'Basic Details',
      isActive:
        step === String(-1)
          ? 'active'
          : checkForCachedData('basic_details', toCamelCase(config))
          ? 'filled'
          : 'normal',
      tick_img: 'normal_tick.png',
      imgColor: checkForCachedData('basic_details', toCamelCase(config)) ? '#333333' : '#2755FE',
      tickImgColor: checkForCachedData('basic_details', toCamelCase(config))
        ? '#52C41A'
        : '#D9D9D9',
      fillRule: 'evenOdd'
    });
    sidePanelItems.push({
      id: -2,
      img: 'ContactDetailsBGV',
      text: 'Contact Details',
      isActive:
        step === String(-2)
          ? 'active'
          : checkForCachedData('contact_details')
          ? 'filled'
          : 'normal',
      tick_img: 'normal_tick.png',
      imgColor: checkForCachedData('contact_details') ? '#333333' : '#2755FE',
      tickImgColor: checkForCachedData('contact_details') ? '#52C41A' : '#D9D9D9'
    });
    if (config?.config?.identity.length || config?.config?.credit) {
      sidePanelItems.push({
        id: 1,
        img: 'IdentityBGV',
        text: 'Identity',
        isActive:
          step === String(1) ? 'active' : checkForCachedData('identity') ? 'filled' : 'normal',
        tick_img: 'normal_tick.png',
        imgColor: checkForCachedData('identity') ? '#333333' : '#2755FE',
        tickImgColor: checkForCachedData('identity') ? '#52C41A' : '#D9D9D9'
      });
    }
    if (config?.config?.employment > 0 || config?.config?.reference.length) {
      sidePanelItems.push({
        id: 4,
        img: 'EmploymentBGV',
        text: 'Employment',
        isActive:
          step === String(4)
            ? 'active'
            : checkForCachedData('employment', config)
            ? 'filled'
            : 'normal',
        tick_img: 'normal_tick.png',
        imgColor: checkForCachedData('employment', config) ? '#333333' : '#2755FE',
        tickImgColor: checkForCachedData('employment', config) ? '#52C41A' : '#D9D9D9'
      });
    }
    if (config?.config?.education > 0 || config?.config?.education.length) {
      sidePanelItems.push({
        id: 5,
        img: 'EducationBGV',
        text: 'Education',
        isActive:
          step === String(5) ? 'active' : checkForCachedData('education') ? 'filled' : 'normal',
        tick_img: 'normal_tick.png',
        imgColor: checkForCachedData('education') ? '#333333' : '#2755FE',
        tickImgColor: checkForCachedData('education') ? '#52C41A' : '#D9D9D9'
      });
    }
    if (config?.config?.court.length || config?.config?.address?.length) {
      sidePanelItems.push({
        id: 3,
        img: 'AddressBGV',
        text: 'Address',
        isActive:
          step === String(3) ? 'active' : checkForCachedData('address') ? 'filled' : 'normal',
        tick_img: 'normal_tick.png',
        imgColor: checkForCachedData('address') ? '#333333' : '#2755FE',
        tickImgColor: checkForCachedData('address') ? '#52C41A' : '#D9D9D9'
      });
    }
    sidePanelItems.push({
      id: -3,
      img: 'ConsentBGV',
      text: 'Consent Form',
      isActive: step === String(-3) ? 'active' : 'normal',
      tick_img: 'normal_tick.png',
      imgColor: checkForCachedData('consent') ? '#333333' : '#2755FE',
      tickImgColor: checkForCachedData('consent') ? '#52C41A' : '#D9D9D9',
      fillRule: 'evenOdd'
    });
    setSidePanel(sidePanelItems);
  };

  const setBgvCacheInternal = (cache) => {
    props.setBgvCache(cache).then(() => {
      setStep(cache.saveObject.current_state);
      setInternalLoading(false);
    });
  };

  const handleStepChange = (id) => {
    getBgvCacheAPI().then((res) => {
      let cacheData = {
        saveObject: {
          ...res.data.data,
          current_state: id ? String(id) : FeedbackState
        }
      };
      if (!cacheData?.saveObject?.device_info) {
        cacheData.saveObject.device_info = [browserDetails];
      } else if (
        cacheData?.saveObject?.device_info &&
        cacheData?.saveObject?.device_info.length > 0
      ) {
        var count = cacheData.saveObject.device_info.some(
          (obj) =>
            obj.browser === browserDetails.browser &&
            obj.os === browserDetails.os &&
            obj.device === browserDetails.device &&
            obj.userAgent === browserDetails.userAgent
        );
        if (!count) {
          cacheData.saveObject.device_info.push(browserDetails);
        }
      }
      setBgvCacheInternal(cacheData);
    });
  };
  const handleSidePanelClick = (id) => {
    handleStepChange(id);
    // setStep(String(id));
  };

  //Function to get the total check Count
  const getTotalIdConfigCount = () => {
    if (
      config?.config?.credit &&
      !config?.config?.identity?.includes('PAN') &&
      !config?.config?.identity?.some((config) => config.includes('ANY'))
    ) {
      return getTotalCheckCount([...config?.config?.identity, 'PAN']);
    } else if (config?.config?.credit && !config?.config?.identity?.includes('PAN')) {
      let count = 0;
      for (let i = 0; i < config?.config?.identity.length; i++) {
        if (config?.config?.identity[i].includes('ANY_')) {
          count += Number(config?.config?.identity[i].split('_')[1]);
        } else {
          count += 1;
        }
      }

      if (
        cached_data?.cache_data?.id_check?.filter((check) => check?.id_type?.name === 'PAN')
          ?.length === 1 &&
        count === cached_data?.cache_data?.id_check?.filter((check) => check?.id_type)?.length
      ) {
        return getTotalCheckCount(config.config.identity);
      } else {
        return getTotalCheckCount([...config?.config?.identity, 'PAN']);
      }
    }
    return getTotalCheckCount(config.config.identity);
  };

  const checkForCachedData = (sidePanelItem, config = undefined) => {
    switch (sidePanelItem) {
      case 'basic_details':
        return (
          cached_data &&
          !isEmpty(cached_data) &&
          !isEmpty(cached_data.cache_data) &&
          !isEmpty(cached_data.cache_data.basic_details) &&
          (!config?.candidateBgvFormConfig?.isGenderOptional
            ? cached_data?.cache_data?.basic_details?.gender !== ''
            : true)
        );
      case 'contact_details':
        return (
          cached_data &&
          !isEmpty(cached_data) &&
          !isEmpty(cached_data.cache_data) &&
          !isEmpty(cached_data.cache_data.contact_details)
        );
      case 'identity':
        return (
          cached_data &&
          !isEmpty(cached_data) &&
          ((!isEmpty(cached_data.cache_data) &&
            !isEmpty(cached_data.cache_data.id_check) &&
            cached_data.cache_data.id_check?.filter((check) => check?.id_type)?.length ===
              getTotalIdConfigCount()) ||
            cached_data.identity_skip_enable)
        );
      case 'employment':
        return (
          cached_data &&
          !isEmpty(cached_data) &&
          !isEmpty(cached_data.cache_data) &&
          !isEmpty(cached_data?.cache_data?.employment_check) &&
          cached_data?.cache_data?.employment_check?.data &&
          (cached_data?.cache_data?.employment_check?.data?.length ===
            getRefEmpCount(config?.config?.reference, config?.config?.employment) ||
            cached_data.skipped_employment) &&
          !checkIfEmploymentEmpty()
        );
      case 'education':
        return (
          cached_data &&
          !isEmpty(cached_data) &&
          !isEmpty(cached_data.cache_data) &&
          !isEmpty(cached_data.cache_data.education_check) &&
          (cached_data.cache_data.education_check.length === getEducationCount() ||
            cached_data.is_skip_education) &&
          !checkIfEducationEmpty()
        );
      case 'address':
        return (
          cached_data &&
          !isEmpty(cached_data) &&
          !isEmpty(cached_data.cache_data) &&
          !isEmpty(cached_data.cache_data.address_check) &&
          cached_data.cache_data.address_check.length === getAddressCount() &&
          !checkIfAddressEmpty()
        );
      default:
        return false;
    }
  };

  const getAddressCount = () => {
    const addressConfig = config.config.address;
    const courtConfig = config.config.court;
    const addressCheckLabelsPresent =
      cached_data.cache_data.address_check
        ?.filter((check) => !isEmpty(check))
        .map((check) => AddressArrLabelObj[check?.step_1?.address_type]) || [];
    if (addressConfig !== 0 && courtConfig !== 0) {
      if (addressConfig.length === courtConfig.length) {
        if (addressConfig.length === 2) {
          return 2;
        } else if (addressConfig[0] === courtConfig[0] || courtConfig[0] === 'ANY_1') {
          return 1;
        } else if (
          addressConfig[0] === 'ANY_1' &&
          addressCheckLabelsPresent.includes(courtConfig[0])
        ) {
          return addressCheckLabelsPresent.length;
        } else {
          return 2;
        }
      } else {
        return addressConfig.length > courtConfig.length
          ? addressConfig.length
          : courtConfig.length;
      }
    } else if (addressConfig !== 0) {
      return addressConfig.length;
    } else if (courtConfig !== 0) {
      return courtConfig.length;
    } else {
      return 0;
    }
  };

  const getEducationCount = () => {
    return config.config.education.length
      ? config.config.education.length
      : config.config.education;
  };
  // const checkIfIdentityEmpty = () => {
  //   const identityCheckArray = cached_data?.cache_data?.id_check?.map((d) => {
  //     return d && !isEmpty(d);
  //   });
  //   return identityCheckArray?.some((check) => check === null || !check);
  // };
  const checkIfEmploymentEmpty = () => {
    const employmentChecksArray = cached_data?.cache_data?.employment_check?.data.map((d) => {
      return (
        d &&
        !isEmpty(d) &&
        d.hasOwnProperty('step_1') &&
        !isEmpty(d.step_1) &&
        d.hasOwnProperty('step_2') &&
        !isEmpty(d.step_2) &&
        d.hasOwnProperty('step_3') &&
        !isEmpty(d.step_3)
      );
    });
    return employmentChecksArray?.some((check) => check === null || !check);
  };
  const checkIfEducationEmpty = () => {
    const educationChecksArray = cached_data?.cache_data?.education_check?.map((d) => {
      return (
        d &&
        !isEmpty(d) &&
        d.hasOwnProperty('step_1') &&
        !isEmpty(d.step_1) &&
        d.hasOwnProperty('step_2') &&
        !isEmpty(d.step_2)
      );
    });
    return educationChecksArray?.some((check) => check === null || !check);
  };
  const checkIfAddressEmpty = () => {
    const addressChecksArray = cached_data?.cache_data?.address_check?.map((d) => {
      return (
        d &&
        !isEmpty(d) &&
        d.hasOwnProperty('step_1') &&
        !isEmpty(d.step_1) &&
        d.hasOwnProperty('step_2') &&
        !isEmpty(d.step_2) &&
        d.hasOwnProperty('step_3') &&
        !isEmpty(d.step_3)
      );
    });
    return addressChecksArray?.some((check) => check === null || !check);
  };

  const nextStep = () => {
    if (adminAccessError()) {
      history.push(adminAccessError());
    } else {
      setInternalLoading(true);
      const sidePanelIndex = sidePanel.map((item) => String(item.id));
      const currentActiveStepIndex = sidePanelIndex.indexOf(String(step));
      handleStepChange(sidePanelIndex[currentActiveStepIndex + 1]);
    }
  };

  const prevStep = () => {
    if (adminAccessError()) {
      history.push(adminAccessError());
    } else {
      const sidePanelIndex = sidePanel.map((item) => String(item.id));
      const currentActiveStepIndex = sidePanelIndex.indexOf(String(step));
      handleStepChange(sidePanelIndex[currentActiveStepIndex - 1]);
    }
  };

  const validateFinalSubmit = (finalFormData = null, callback = null) => {
    if (adminAccessError()) {
      history.push(adminAccessError());
    } else {
      const sidepanelSeqId = sidePanel.map((item) => item.id);
      config &&
        !config.loading &&
        logger.push({
          message: `BGV Configuration at end of ${candidateId}`,
          data: JSON.stringify(config),
          category: `candidate-id-${candidateId}`,
          type: 'info'
        });

      const validatorResp = validator(
        finalFormData,
        sidepanelSeqId,
        config.config,
        cached_data,
        config.candidate_bgv_form_config
      );
      if (!validatorResp.shouldSubmit) {
        showFinalSubmitError(validatorResp.errorParams.state, validatorResp.errorParams.msg);
      } else {
        if (validatorResp.skipped_checks) {
          finalFormData.skipped_checks = validatorResp.skipped_checks;
        }
        if (location.pathname.includes('company')) {
          const currentUserData = jwtParser(storage.getToken());
          finalFormData['ca_user_id'] = currentUserData?.data?.userId;
        }
        setBgvSubmissionAPI(finalFormData)
          .then((response) => {
            callback && callback(false);
            const timeSinceLoad = (new Date().getTime() - BGVStartDate.getTime()) / 1000;
            logger.push({
              message: ` Time tracked : ${timeSinceLoad}ms took to fill the form, started on ${BGVStartDate}`,
              data: JSON.stringify(response),
              category: `candidate-id-${candidateId}`,
              subCategory: 'Time tracking',
              type: 'info'
            });
            handleStepChange(FeedbackState);
          })
          .catch((error) => {
            console.error(error);
            callback && callback(false);
            errToastMessage(error);
            logger.push({
              message: 'Bgv Error occurs while doing final submission',
              data: JSON.stringify(error),
              category: `candidate-id-${candidateId}`,
              subCategory: 'Final Submission Error',
              type: 'error'
            });
          });
      }
    }
  };

  const showFinalSubmitError = (step, errMsg = '') => {
    errMsg && errToastMessage(errMsg);
    handleStepChange(String(step));
  };

  const onHideModal = () => {
    setShowHomeModal(false);
    if (adminAccessError()) {
      history.push(adminAccessError());
    }
  };
  const adminAccessError = () => {
    let error = null;
    const bgvtoken = location.search && location.search.split('=')[1];
    setToken(bgvtoken);
    const parsedJwtDetail = jwtParser(bgvtoken);
    setShowHomeModal(false);
    if (location.pathname.includes('company')) {
      const currentUserData = jwtParser(storage.getToken());
      if (currentUserData?.data?.companyId !== parsedJwtDetail?.data?.companyId) {
        error = BGV_ERROR.UNAUTHORIZED;
        history.push('/unauthorized');
      } else {
        storage.setBGVToken(token);
        setIsCompany(true);
      }
    } else {
      if (storage.getToken()) {
        error = BGV_ERROR.ERROR;
        history.push('/error');
      } else {
        storage.clearStorage();
        storage.setBGVToken(token);
      }
    }
    return error;
  };
  return (
    <>
      <Helmet>
        <title>{'BGV Form | SpringVerify'}</title>
        <meta name='description' content='SpringVerify' />
        <meta
          name='twitter:title'
          content='SpringVerify | Instant Background Verification Tool in India'
        />
        <meta
          property='og:title'
          content='SpringVerify | Instant Background Verification Tool in India'
        />
      </Helmet>
      <div className={styles.bgvPage}>
        <BgvHeader
          companyName={config && config.company ? config.company.name : ''}
          candidateDetail={initial_candidate_details}
          openSidebar={() => {
            setSidebarOpen(true);
          }}
        />
        <div className={styles.bgvHeader}>
          {!initial_candidate_details.loading &&
            !initial_candidate_details.error &&
            !cached_data &&
            !isConsentOnly && (
              <BgvHomeModal
                animation={true}
                isConsentAlreadyAdded={initial_candidate_details?.basic_details?.consent_letter_url}
                companyName={config?.company?.name || ''}
                show={showHomeModal}
                onHide={() => onHideModal()}
              />
            )}
        </div>
        <div className={styles.bgvBody}>
          <Container className={styles.bgvHomeContainer}>
            <Row style={containerStyle()}>
              {loading ? (
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: 'calc(100vh - 64px)',
                    alignItems: 'center'
                  }}
                >
                  <CustomSpinner
                    className='bgvLoader'
                    as='div'
                    animation='border'
                    size='md'
                    role='status'
                    aria-hidden='true'
                  />
                </Col>
              ) : step === String(-4) &&
                config?.candidate_bgv_form_config?.isReferralEnabled &&
                !isCompany &&
                isEmpty(referralData) ? (
                <ReferAndEarn
                  isShowFeedBackPage={isShowFeedBackPage}
                  isCompany={isCompany}
                  candidateId={candidateId}
                  config={config}
                />
              ) : (
                <>
                  {showSidePanel(initial_candidate_details) && (
                    <Col lg={4} md={4} className={styles.bgvHomeSidepanelContainer}>
                      <SidePanel
                        step={step}
                        isNestedStep={isNestedStep}
                        nestedStepValue={nestedStepValue}
                        sidePanelSequence={sidePanel}
                        handleSidePanelClick={handleSidePanelClick}
                      />
                    </Col>
                  )}
                  <Col lg={8} md={8} className={styles.bgvHomeBodyContainer}>
                    <StepContainer
                      isShowFeedBackPage={isShowFeedBackPage}
                      step={step}
                      nextStep={nextStep}
                      prevStep={prevStep}
                      initial_candidate_details={initial_candidate_details}
                      cached_data={cached_data}
                      config={config}
                      finalSubmitCheck={String(-3)}
                      isNestedStep={isNestedStep}
                      nestedStepValue={nestedStepValue}
                      totalSteps={sidePanel}
                      isConsentOnly={isConsentOnly}
                      isCompany={isCompany}
                      validateFinalSubmit={validateFinalSubmit}
                      candidateId={candidateId}
                      BGVStartDate={BGVStartDate}
                      internalLoading={internalLoading}
                    />
                  </Col>
                </>
              )}
            </Row>
            {isSidebarOpen && <div className={styles.sidebarOverlay}> </div>}

            <div className={styles.sidebar + ` ${isSidebarOpen ? styles.open : ''}`}>
              <BgvSidebar
                onClose={() => {
                  setSidebarOpen(false);
                }}
              />
            </div>
          </Container>
        </div>
        <div className={styles.bgvFooter}>
          <BgvFooter />
        </div>
      </div>
    </>
  );
}
