import React from 'react';
import styles from './../DropdownMenu/DropdownMenu.module.scss';
import Image from '../../../../core-components/Image';

const ContactCard = ({ contactData }) => {
  const { name, role, helpText, email, phone } = contactData;
  const sendEmail = (email) => {
    window.open(`mailto:${email}`, '_blank');
  };
  const dialPhone = (phone) => {
    window.location.href = `tel:${phone}`;
  };
  return (
    <div className={styles.contactCard}>
      <div className={styles.bodyContainer}>
        <div className={styles.heading}>
          <div className={styles.headingName}>{name}</div>
          <div className={styles.headingRole}>{role}</div>
        </div>
        <div className={styles.body}>
          <div className={styles.helpText}>{helpText}</div>
        </div>
      </div>
      <div className={styles.footer}>
        {email && (
          <div className={styles.contactContainer}>
            <Image name='email.svg' />
            <span className={styles.contactText} onClick={() => sendEmail(email)}>
              {email}
            </span>
          </div>
        )}
        {phone && (
          <div className={styles.contactContainer}>
            <Image name='phone.svg' />
            <span className={styles.contactText} onClick={() => dialPhone(phone)}>
              {phone}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactCard;
