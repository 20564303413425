import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from '../../Integrations/Integrations.module.scss';
import { Row, Col } from 'react-bootstrap';
import { DropdownList } from 'react-widgets';
import Select from 'react-select';
import Image from '../../../../../core-components/Image';

const IdentifierSeparator = ({ innerProps }) => {
  return <span style={{ display: 'none' }} {...innerProps} />;
};

const PackageConfiguration = ({
  dropDownIdentifierValuesArray,
  packageMappingComponent,
  integrationName
}) => {
  const tagDropdownStyles = {
    option: (provided, state) => ({
      ...provided,
      fontFamily: 'Poppins',
      fontSize: '14px',
      lineHeight: '21px',
      color: '#666666',
      borderRadius: '4px',
      padding: '10px 20px',
      border: 'none',
      background: state.isFocused ? '#ffffff' : ''
    }),
    control: (provided) => ({
      ...provided,
      border: '1px solid #ebedf2',
      boxSizing: 'border-box',
      borderRadius: '4px',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#333333',
      display: 'flex'
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: '#333333'
      };
    },
    valueContainer: (base) => ({
      ...base
    }),
    container: (base) => ({
      ...base
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: '10'
    })
  };

  const checkForTagsError = (packageMappingComponent, tags) => {
    if (
      tags?.length > 0 &&
      !packageMappingComponent?.identifierValues.includes(...tags) &&
      !packageMappingComponent?.identifierDataLoading
    ) {
      return <span className={styles.errorInline}>Tags not available</span>;
    }
    return null;
  };

  useEffect(() => {
    packageMappingComponent.onClickIdentifierHandler();
  }, []);

  return (
    <>
      {/* Component to configure triggerStages, packageIdentifiers, other dropdown values */}
      {dropDownIdentifierValuesArray?.length > 0 &&
        dropDownIdentifierValuesArray.map((dropDownIdentifierValue) => (
          <div>
            <div className={styles.settingPageContainer}>
              <Row className='justify-content-between'>
                <span className={styles.settingPageHeaderText}>
                  {dropDownIdentifierValue?.text}
                </span>
              </Row>
              <div className={styles.settingPageHeaderSubText1}>
                {dropDownIdentifierValue?.subtext}
              </div>
            </div>
            <Row className={styles.dropdownContainer}>
              <DropdownList
                style={{ width: '300px' }}
                data={dropDownIdentifierValue.data} // applicationStages
                dataKey='id'
                textField='text'
                value={dropDownIdentifierValue?.values} // values?.triggerStage
                name={`${dropDownIdentifierValue?.valueName}`} // triggerStage
                selectIcon={<Image name='dropdownArrow.svg' />}
                onChange={(value) => {
                  dropDownIdentifierValue.setFieldValue(dropDownIdentifierValue?.valueName, value); //  setFieldValue('triggerStage', value);
                }}
              />
            </Row>
          </div>
        ))}

      {/* Component to configure packages */}
      {packageMappingComponent && (
        <>
          <div>
            <div className={styles.secondPageSubSectionContainer}>
              <Row>
                <span className={styles.settingPageSubSectionHeader}>
                  {' '}
                  {packageMappingComponent.headerText}
                </span>
              </Row>
              <div className={styles.settingPageSubSectionList1}>
                Match the relevant {packageMappingComponent.identifierValue} in{' '}
                {packageMappingComponent.integrationName.toUpperCase()} to the packages on
                SpringVerify.
                <br />
                Count of {packageMappingComponent.integrationName.toUpperCase()}{' '}
                {packageMappingComponent.identifierValue} should be at least equal to the number of
                package assigned.
              </div>
            </div>

            <div className={styles.packageSelectorHeaderContainer1} xs={2} md={4} lg={6}>
              <Col md={3} className={styles.packageNameHeader}>
                <div className={styles.packageTextStyle}>PACKAGE NAME</div>
              </Col>
              <Col className={styles.refreshPackageContainer}>
                <div className={styles.packageTextStyle}>
                  SELECT {integrationName} Package names
                </div>
                <div
                  className={styles.refreshPackageButton}
                  onClick={() => packageMappingComponent.onClickIdentifierHandler()}
                >
                  <Image className={styles.refreshImageContainer} name='reset.png' />
                  <span className={styles.refreshText}>Refresh</span>
                </div>
              </Col>
            </div>
            {packageMappingComponent?.packages.map((packageRow) => (
              <Row xs={2} md={4} lg={6} className='my-2'>
                <Col md={3} className={styles.packageName}>
                  <div className={styles.text}>
                    <span className={styles.packageNameText}>{packageRow.package_name}</span>
                    <span className={styles.packageSubtypeNameText}>{packageRow.subtype_name}</span>
                  </div>
                </Col>
                <Col>
                  <Select
                    className={styles.tagsDropDown}
                    isMulti
                    placeholder={
                      packageMappingComponent?.identifierDataLoading
                        ? `${packageMappingComponent.values?.identifierValue} loading...`
                        : packageMappingComponent.identifierValues.length > 0
                        ? `Please select ${packageMappingComponent.values?.identifierValue}`
                        : `No ${packageMappingComponent.values.identifierValue}`
                    }
                    name='tags'
                    components={{ IdentifierSeparator }}
                    options={packageMappingComponent.dropdownIdentifierValues}
                    getOptionLabel={(option) => option}
                    getOptionValue={(option) => option}
                    styles={tagDropdownStyles}
                    isClearable={false}
                    noOptionsMessage={() => (
                      <span className={styles.dropDownNoOptionMessage}>
                        No {packageMappingComponent.values?.identifierValue} available. Please
                        create relevant {packageMappingComponent.values?.identifierValue} on Keka
                        and refresh.
                      </span>
                    )}
                    value={packageMappingComponent?.values?.tags[packageRow?.subtype_id]?.tags}
                    onChange={(e) => {
                      packageMappingComponent.handleSetTagsValue(
                        packageRow?.package_id,
                        packageRow?.subtype_id,
                        e
                      );
                    }}
                  />
                  {checkForTagsError(
                    packageMappingComponent,
                    packageMappingComponent?.values?.tags[packageRow.subtype_id]?.tags
                  )}
                </Col>
              </Row>
            ))}
          </div>
        </>
      )}
    </>
  );
};

PackageConfiguration.propTypes = {
  dropDownIdentifierValuesArray: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      subtext: PropTypes.string,
      data: PropTypes.array.isRequired,
      values: PropTypes.any,
      valueName: PropTypes.string.isRequired,
      setFieldValue: PropTypes.func.isRequired
    })
  ).isRequired,
  packageMappingComponent: PropTypes.shape({
    headerText: PropTypes.string.isRequired,
    identifierValue: PropTypes.string.isRequired,
    integrationName: PropTypes.string.isRequired,
    identifierValues: PropTypes.array.isRequired,
    identifierDataLoading: PropTypes.bool,
    onClickIdentifierHandler: PropTypes.func.isRequired,
    packages: PropTypes.arrayOf(
      PropTypes.shape({
        package_id: PropTypes.number.isRequired,
        package_name: PropTypes.string.isRequired,
        subtype_name: PropTypes.string.isRequired,
        subtype_id: PropTypes.number.isRequired
      })
    ).isRequired,
    dropdownIdentifierValues: PropTypes.array.isRequired,
    values: PropTypes.objectOf(
      PropTypes.shape({
        tags: PropTypes.array
      })
    ),
    handleSetTagsValue: PropTypes.func.isRequired
  })
};

IdentifierSeparator.propTypes = {
  innerProps: PropTypes.object
};

export default PackageConfiguration;
