/* eslint-disable import/no-anonymous-default-export */
import React from 'react';

import { Card, Row, Col, Button } from 'react-bootstrap';
import Image from '../../../../../core-components/Image';
import ChecksIncluded from '../../../ViewBuyPackage/PackageListing/ChecksIncluded';
import styles from './Package.module.scss';
import { getPackageUnitIndication } from './../../../../../utils/utilities';
import { PACKAGE_UNIT_INDICATION } from '../../../../../utils/commonConstant';
import { convertToIndianNumeration } from '../../../../../utils/utilities';
import { useSelector } from 'react-redux';

export default ({
  pDetails,
  handleChoose,
  showBorder,
  isStep3,
  packageType,
  isAadhaarAllowed,
  bulkCandidates
}) => {
  const getBalanceShareText = (sharedWith = []) => {
    let text = '';
    sharedWith.map((item, index) => {
      text += `${Object.values(item).join('')}${index < sharedWith.length - 1 ? ' and ' : ''}`;
    });
    return text;
  };

  const profile = useSelector((state) => state.profile);
  const unitIndication = getPackageUnitIndication(
    pDetails?.leastRechargeUnitsTier,
    pDetails?.currentBalance,
    bulkCandidates === 0 ? 1 : bulkCandidates,
    packageType
  );

  const renderPackageDetails = () => {
    return (
      <>
        <Row>
          <Col lg={9} md={9} sm={9} xs={9}>
            <p className={styles.subtypeName}>{pDetails?.packageName}</p>
            <p className={styles.friendlyName}>
              {pDetails?.subtypeName}
              {'  '}
              {pDetails?.subtypeFriendlyName ? ` (${pDetails?.subtypeFriendlyName})` : ''}
            </p>
            {packageType === 'PREPAID' && (
              <p className={styles.balanceText}>
                {`Current balance: ${convertToIndianNumeration(pDetails?.currentBalance)}`}
                <span className={styles.packgeUnitIndication}>{renderPackageUnitIndication()}</span>
              </p>
            )}
          </Col>
          <Col lg={3} md={3} sm={3} xs={3} className={styles.buttonGrid}>
            {getButton(pDetails?.isCustomisable)}
          </Col>
        </Row>

        {/* Balance shared with */}
        {pDetails?.sharedWith && pDetails.sharedWith.length && packageType === 'PREPAID' ? (
          <div className={styles.balanceSharedWithContainer}>
            <span style={{ marginRight: '3px' }}>
              <Image name={'InfoIconFilled.svg'} />
            </span>
            <p className={styles.balanceSharedWithContainerDescription}>
              {`Balance shared with ${getBalanceShareText(pDetails.sharedWith)}`}
            </p>
          </div>
        ) : null}
      </>
    );
  };

  const renderChecksIncluded = () => {
    return (
      <Row>
        <Col lg={10} md={10} sm={10} xs={10}>
          <p className={styles.checkIncludedText}>{'CHECKS INCLUDED'}</p>
          <ChecksIncluded config={pDetails?.config} isAadhaarAllowed={isAadhaarAllowed} />
        </Col>
        <Col lg={2} md={2} sm={2} xs={2}></Col>
      </Row>
    );
  };

  const getChooseButton = (isCustomisable) => {
    const isDisableButton = unitIndication === PACKAGE_UNIT_INDICATION.NILL;
    const button = (
      <Button
        variant={isDisableButton ? 'light' : 'primary'}
        className={isDisableButton ? styles.buttonDisabled : styles.button}
        onClick={() => (isDisableButton ? null : handleChoose(pDetails.subtypeId, isCustomisable))}
      >
        {'Choose'}
      </Button>
    );

    return button;
  };

  const getButton = (isCustomisable) => {
    return (
      <>
        {
          <p className={styles.amountInfoContainer}>
            {packageType === 'POSTPAID' && profile?.profile?.access_level !== 10 && (
              <span className={styles.amount}>&#8377;{pDetails?.costPerCandidate}</span>
            )}
          </p>
        }
        {!isStep3 ? (
          <>
            {getChooseButton(isCustomisable)}
            {isCustomisable ? <p className={styles.isCustomisableText}>{`*customizable`}</p> : null}
          </>
        ) : (
          <>
            {isCustomisable ? (
              <Button
                variant='light'
                className={styles.customiseButton}
                onClick={() => handleChoose(pDetails.subtypeId, isCustomisable)}
              >
                {'Customize'}
              </Button>
            ) : null}
          </>
        )}
      </>
    );
  };

  const renderPackageUnitIndication = () => {
    switch (unitIndication) {
      case PACKAGE_UNIT_INDICATION.NILL:
        return (
          <>
            <Image name={'nillPackageUnit.svg'} className={styles.unitIndicationIcon} />
            <span className={styles.unitNillIndicationText}>Buy units to proceed</span>
          </>
        );
      case PACKAGE_UNIT_INDICATION.LOW:
        return (
          <>
            <Image name={'lowPackageUnit.svg'} className={styles.unitIndicationIcon} />
            <span className={styles.unitLowIndicationText}>You are running low on units</span>
          </>
        );
      default:
        return <Image name={'CheckCircleIcon.svg'} className={styles.unitIndicationIcon} />;
    }
  };

  return (
    <div className={`${styles.wrapper} ${showBorder ? styles.showBorder : ''}`}>
      <Card className={styles.packageDetailsCard}>
        <Card.Body className={styles.packageDetailsCardBody}>
          <Row className={styles.packageDetailsRow}>
            <Col lg={12} md={12} sm={12} xs={12} className={styles.packageDetailsColLeft}>
              <div className={styles.packageDetailsSection}>{renderPackageDetails()}</div>
              <div className={styles.checksIncludedSection}>{renderChecksIncluded()}</div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};
