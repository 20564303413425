// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: Poppins, sans-serif;
}

.l0tfiS709CKU4zVj82No {
  color: #000000;
  font-size: 22px;
}

.XVcABGNtr8QbQED0lj9L {
  color: #8c8c8c;
  font-size: 14px;
  margin-bottom: -10px;
}

.eqpMsqBdUcIKKDfyVsO1 {
  min-height: 500px;
  padding: 30px 30px 40px 30px !important;
}
.eqpMsqBdUcIKKDfyVsO1 .wytV7G4N94eiCAolsT_Z .U6OMuqsVxnbNq7pCL4W3 {
  color: #262626;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0;
}
.eqpMsqBdUcIKKDfyVsO1 .wytV7G4N94eiCAolsT_Z .SO1kGrCQjdj2c9b6R97P {
  color: #999999;
  font-size: 14px;
  font-weight: normal;
}
.eqpMsqBdUcIKKDfyVsO1 .YRz7MnLiA0Y2RfDm7Egu .x0cimdzd586OFM2rzMSg {
  font-weight: 600;
  font-size: 14px;
  color: #262626;
}
.eqpMsqBdUcIKKDfyVsO1 .YRz7MnLiA0Y2RfDm7Egu .x0cimdzd586OFM2rzMSg span {
  color: #f5222d;
}
.eqpMsqBdUcIKKDfyVsO1 .LSfOkgPtNyBA9td9qkG_ {
  background: #f5f7ff;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  text-align: center;
  height: 117px;
  justify-content: center;
  margin-bottom: 16px !important;
  cursor: pointer;
}
.eqpMsqBdUcIKKDfyVsO1 .LSfOkgPtNyBA9td9qkG_ p {
  font-weight: normal !important;
  margin: 0;
}
.eqpMsqBdUcIKKDfyVsO1 .JQJ4VAacn97Qesm3P0L0 {
  background: #f5f5f5;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  text-align: center;
  height: 117px;
  justify-content: center;
  margin-bottom: 16px !important;
  cursor: not-allowed;
}
.eqpMsqBdUcIKKDfyVsO1 .JQJ4VAacn97Qesm3P0L0 p {
  font-weight: normal !important;
  margin: 0;
  color: #bfbfbf;
}
@media screen and (max-width: 601px) {
  .eqpMsqBdUcIKKDfyVsO1 {
    padding-bottom: 65px !important;
  }
}

.Vy0l0TNElbjiXNN_BEC3 {
  padding: 0 !important;
  justify-content: flex-end !important;
}
@media screen and (max-width: 601px) {
  .Vy0l0TNElbjiXNN_BEC3 {
    position: fixed;
    left: 0;
    margin: 0;
    bottom: 0;
    width: 100%;
    box-shadow: 3px -1px 8px 0px rgba(0, 0, 0, 0.11);
    padding: 12px 16px;
    background: #ffffff;
    display: flex;
    align-items: center;
    padding: 12px 16px !important;
    display: flex;
    align-items: center;
  }
  .Vy0l0TNElbjiXNN_BEC3 button {
    height: 40px;
    margin: 0 !important;
    width: 100%;
  }
  .Vy0l0TNElbjiXNN_BEC3 .Gqy4H3OCJyUVGBXC7OkD,
  .Vy0l0TNElbjiXNN_BEC3 .JiTJs7MSj5SqKJivAtbw {
    padding: 5px 35px;
  }
}

.hab6aGnrtfvGd5JZtroa {
  padding: 0;
}

.gJzdCfRl9Ic9TGRBggH1 {
  padding: 0;
}
.gJzdCfRl9Ic9TGRBggH1 .XGWwuVbfSGDro61_NsVG {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  color: #2755fe;
  padding: 8px 18px;
  margin-bottom: 16px;
  text-align: center;
  border: none;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
}
.gJzdCfRl9Ic9TGRBggH1 .XGWwuVbfSGDro61_NsVG:active {
  background: #ffffff !important;
  color: #2755fe !important;
  border: none !important;
}

.IU_3yPWAoLrfprhLZQSm {
  padding: 0;
}

.JsKqUEMCD_6PgQe7cYBg {
  color: #f5222d;
}

.WIEhhVnWkNOkpFMIlSv6 {
  display: flex;
  position: relative;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.WIEhhVnWkNOkpFMIlSv6 .bgeZvJe9PRx5JhsFQW9h {
  position: absolute;
  top: 0;
  right: 5px;
  cursor: pointer;
}
.WIEhhVnWkNOkpFMIlSv6 span {
  margin: 5px;
}
.WIEhhVnWkNOkpFMIlSv6 img {
  height: 100%;
  width: 100%;
}
.WIEhhVnWkNOkpFMIlSv6 .VpnPjFoH01zZ41NFSl2O {
  font-size: 20px;
  font-weight: 500;
  color: #faad14;
}
.WIEhhVnWkNOkpFMIlSv6 .dIel2xsaBZUQhCL1Oxyc {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
}
.WIEhhVnWkNOkpFMIlSv6 .dIel2xsaBZUQhCL1Oxyc .aUSFqd3wiE3hFrkKZJVX {
  background: transparent;
  color: #2755fe;
  font-style: normal;
  font-size: 16px;
  font-family: Poppins, sans-serif;
  margin: 0px 10px 5px 10px;
}
.WIEhhVnWkNOkpFMIlSv6 .dIel2xsaBZUQhCL1Oxyc ._Ypstp3tDryvX64dAVCw {
  background: #2755fe;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  margin: 0px 10px 5px 10px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"educationModalTitle": `l0tfiS709CKU4zVj82No`,
	"educationModalSubTitle": `XVcABGNtr8QbQED0lj9L`,
	"addEditEducationModalBody": `eqpMsqBdUcIKKDfyVsO1`,
	"educationProofContainer": `wytV7G4N94eiCAolsT_Z`,
	"educationProofTitle": `U6OMuqsVxnbNq7pCL4W3`,
	"educationProofDescription": `SO1kGrCQjdj2c9b6R97P`,
	"degreeCertificateContainer": `YRz7MnLiA0Y2RfDm7Egu`,
	"degreeCertificateTitle": `x0cimdzd586OFM2rzMSg`,
	"certificateInputFileContainer": `LSfOkgPtNyBA9td9qkG_`,
	"disabledInputFileContainer": `JQJ4VAacn97Qesm3P0L0`,
	"educationSubmitBtn": `Vy0l0TNElbjiXNN_BEC3`,
	"activeBtn": `Gqy4H3OCJyUVGBXC7OkD`,
	"disableBtn": `JiTJs7MSj5SqKJivAtbw`,
	"uploadParentContainer": `hab6aGnrtfvGd5JZtroa`,
	"takePhotoBtnContainer": `gJzdCfRl9Ic9TGRBggH1`,
	"takePhotoBtn": `XGWwuVbfSGDro61_NsVG`,
	"uploadContainer": `IU_3yPWAoLrfprhLZQSm`,
	"maxFileError": `JsKqUEMCD_6PgQe7cYBg`,
	"bgvEmpDocumentWarning": `WIEhhVnWkNOkpFMIlSv6`,
	"warningModalCloseButton": `bgeZvJe9PRx5JhsFQW9h`,
	"modalWarningHeader": `VpnPjFoH01zZ41NFSl2O`,
	"modalWarningButtonContain": `dIel2xsaBZUQhCL1Oxyc`,
	"ignoreSaveButton": `aUSFqd3wiE3hFrkKZJVX`,
	"modalWarningButton": `_Ypstp3tDryvX64dAVCw`
};
export default ___CSS_LOADER_EXPORT___;
