import React, { useState, useEffect } from 'react';

import { Accordion, Card, Row, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faBan } from '@fortawesome/free-solid-svg-icons';

import Image from '../../../../../../../core-components/Image';
import CustomCounter from '../../../../../../../core-components/CustomCounter';

import { PLAIN_CHECK_ICONS, CHECKS_LIMIT } from '../../../../../../../utils/commonConstant';
import { convertToIndianNumeration } from '../../../../../../../utils/utilities';
import styles from './Employment.module.scss';
import { maskPriceForAdmins } from '../../../../../frontend-common/CreditBuyPackagePage/UtilComponents/UtilitiesFunction';

function Employment(props) {
  const [activeAccordion, setActiveAccordion] = useState(false);
  const [empCheckCount, setEmpCheckCount] = useState(0);
  const [counterValue, setCounterValue] = useState(0);

  const { values, onValueChange, pDetails } = props;

  useEffect(() => {
    if (pDetails?.config) {
      setEmpCheckCount(pDetails.config.employment);
    }
  }, [pDetails]);

  const handleCounterValueChange = (value) => {
    if (value > counterValue) {
      setCounterValue(value);
      setEmpCheckCount((prevCount) => prevCount + 1);
    } else if (value < counterValue) {
      setCounterValue(value);
      setEmpCheckCount((prevCount) => prevCount - 1);
    }
  };

  const decoratedOnClick = (eventKey = 0) => {
    if (eventKey === activeAccordion) {
      setActiveAccordion(null);
    } else {
      setActiveAccordion(eventKey);
    }
  };

  const handleConfigChange = (value1, value) => {
    onValueChange(value);
  };

  return (
    <div className={styles.addonsContainer}>
      <Accordion>
        <Card className={styles.addonsContainerCard}>
          <Accordion.Toggle
            as={Card.Header}
            eventKey='0'
            className={styles.addonsContainerHeader}
            onClick={() => setActiveAccordion(!activeAccordion)}
            data-testid='accordion-toggle'
          >
            <div>
              <span className={styles.iconContainer}>
                <FontAwesomeIcon icon={faPlusCircle} color='#389E0D' className={styles.plusIcon} />
                <FontAwesomeIcon
                  icon={
                    PLAIN_CHECK_ICONS['employment'] ? PLAIN_CHECK_ICONS['employment'].icon : faBan
                  }
                  color='rgba(39, 85, 254, 0.5)'
                  style={{ fontSize: '18px' }}
                />
              </span>
            </div>
            <div className={styles.headingRightContainer}>
              <span className={styles.heading}>{props?.heading}</span>
              <div className={styles.headingRight}>
                <span className={styles.startFrom}>
                  <span className={styles.rupee}>&#8377;</span>
                  {maskPriceForAdmins(props?.price, props?.accessLevel, props?.paymentModelType)}
                  {' per check'}
                </span>
                {/* <Image name={'DisableDownAngle.svg'} svg={'DisableDownAngle.svg'} /> */}
                {activeAccordion ? (
                  <Image name={'UpAngle.svg'} svg={'UpAngle.svg'} />
                ) : (
                  <Image name={'DownAngle.svg'} svg={'DownAngle.svg'} />
                )}
              </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='0'>
            <Card.Body className={styles.cardBody}>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <CustomCounter
                    limit={CHECKS_LIMIT.EMP - empCheckCount}
                    totalCount={counterValue}
                    value={values}
                    index={null}
                    valueChange={(value) => {
                      handleCounterValueChange(value);
                      handleConfigChange(null, value);
                    }}
                    data-testid='counter-input'
                  />
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
}

export default Employment;
