// React and libraries imports
import React from 'react';
import { isEmpty } from 'lodash';
import { Card, Form, Button, Row, Col } from 'react-bootstrap';
import { Field } from 'formik';
import { isMobileOnly } from 'react-device-detect';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

// Core components and utils import
import BgvSecureText from '../../../CommonComponent/BgvSecureText/BgvSecureText';
import ValidatedFormInputField from '../../../../../core-components/ValidatedFormInputField';
import ProgressBar from '../../../../../core-components/ProgressBar';
import { errToastMessage, getDateValidity, getTrimmedValue } from '../../../../../utils/utilities';
import { checkBgvCandidateEmployeeId as checkBgvCandidateEmployeeIdAPI } from '../../../../../api/bgv';
import ValidatedRadioFields from '../../../../../core-components/ValidatedRadioFields';
import { sexTypes } from '../../../../../utils/commonConstant';
import store from '../../../../../store';
import { invalidateHrRefer } from '../../../../../actions/bgv';
import Image from '../../../../CompanyAdmin/frontend-common/core-components/Image/Image';
import DatePicker from 'react-datepicker';
import { capitalizeName } from '../../../../CompanyAdmin/frontend-common/utils/Utilities';

// Styles import
import styles from './BasicDetails.module.scss';
import moment from 'moment';
import AppConstants from '../../../../../core-components/AppConstants';

class BasicDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEmpIdValid: true
    };
  }

  handleChange = (e) => {
    const { setFieldValue, handleChange } = this.props;

    handleChange(e);
    if (e?.target?.value && e?.target?.value?.length >= 2) {
      this.setState({ isEmpIdValid: false });
      checkBgvCandidateEmployeeIdAPI(e.target.value)
        .then((response) => {
          if (response?.data?.data?.resp) {
            setFieldValue('employeeIdExists', true);
            this.setState({ isEmpIdValid: false });
          } else {
            this.setState({ isEmpIdValid: true });
            setFieldValue('employeeIdExists', false);
          }
        })
        .catch((error) => errToastMessage(error));
    }
  };

  componentDidMount() {
    store.dispatch(invalidateHrRefer());
    window.scroll(0, 0);
  }

  onChangeSex = (e) => {
    this.props.setFieldValue('gender', e.target.value);
  };
  render() {
    const { isSubmitting, values, errors, handleSubmit, setFieldValue, setErrors } = this.props;
    const enableBtn =
      !isSubmitting && isEmpty(errors) && this?.state?.isEmpIdValid && !values?.employeeIdExists
        ? true
        : false;
    const indexing =
      this?.props?.totalSteps
        .map((val) => {
          return val?.id;
        })
        .indexOf(-1) + 1;
    const setValue = (fieldname, value, withSpace) => {
      setFieldValue(fieldname, getTrimmedValue(value, withSpace));
    };

    const isDOBError = getDateValidity(values.dob);
    return (
      <Card className={styles.basicDetailsCard}>
        <Card.Body className={styles.basicDetailsCardBody}>
          {!isMobileOnly ? (
            <div data-testid='no-mobile-header'>
              <span className={styles.basicDetailHeading}>Basic Details</span>
              <div className={styles.basicDetailProgressContainer}>
                <span className={styles.stepText}>
                  {indexing} of {this.props.totalSteps.length}
                </span>
                <ProgressBar max={this.props.totalSteps.length} min={0} now={indexing} />
              </div>
            </div>
          ) : (
            <>
              <BgvSecureText />
              <div className={styles.basicDetailProgressContainerMobile}>
                <span className={styles.basicDetailHeading}>Basic Details</span>
                <span className={styles.stepTextMobile}>
                  {indexing} of {this.props.totalSteps.length}
                </span>
              </div>
              <ProgressBar max={this.props.totalSteps.length} min={0} now={indexing} />
            </>
          )}

          <Form
            onSubmit={handleSubmit}
            className='basic-details-form'
            data-testid='basic-details-form'
          >
            <Row>
              <Col sm={12} xs={12} md={10} lg={8}>
                <Form.Label>
                  Full Name<span>*</span>
                </Form.Label>
                <Field
                  data-testid='full-name'
                  type='text'
                  name='full_name'
                  className={'fullName'}
                  component={ValidatedFormInputField}
                  autoFocus
                  onBlur={(e) => {
                    this.props?.handleBlur(e);
                    setValue('full_name', capitalizeName(values?.full_name), true);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} xs={12} md={12} lg={12}>
                <Form.Label>Sex {!values?.is_sex_optional ? <span>*</span> : null}</Form.Label>
                <Field
                  name='gender'
                  component={ValidatedRadioFields}
                  options={sexTypes}
                  className={styles.sex}
                  onChange={(e) => {
                    this.onChangeSex(e);
                  }}
                />
              </Col>
            </Row>{' '}
            <Row>
              <Col sm={12} xs={12} md={10} lg={8}>
                <Form.Label>
                  Father's Name{!values?.is_father_name_optional && <span>*</span>}
                </Form.Label>
                <Field
                  data-testid='father-name'
                  type='text'
                  name='father_name'
                  className={'fatherName'}
                  component={ValidatedFormInputField}
                  onBlur={(e) => {
                    this.props?.handleBlur(e);
                    setValue('father_name', capitalizeName(values?.father_name), true);
                  }}
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: '20px' }}>
              <Col sm={12} xs={12} md={10} lg={8}>
                <Form.Label>Date of Birth{!values?.is_dob_optional && <span>*</span>}</Form.Label>
              </Col>
              <Col sm={12} xs={12} md={10} lg={8}>
                <DatePicker
                  placeholderText={'Date of Birth'}
                  selected={values.dob ? moment(values.dob).toDate() : null}
                  dateFormat='dd MMM yyyy'
                  isClearable={values.dob}
                  className={styles.datePicker}
                  popperPlacement='auto'
                  onChange={(date) => {
                    setFieldValue('dob', date ? moment(date).format('YYYY-MM-DD') : '');
                  }}
                  onBlur={(e) => {
                    this.props.setFieldTouched('dob', true);
                  }}
                  onChangeRaw={(e) => e.preventDefault()}
                  maxDate={new Date()}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode='select'
                />

                {isDOBError.error ? (
                  <div className={styles.dobErrorMsg}>{isDOBError.msg}</div>
                ) : null}
                {!values.dob && this.props.touched.dob && !values.is_dob_optional && (
                  <div className={styles.dobErrorMsg}>{'Please enter Date of Birth'}</div>
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={12} xs={12} md={10} lg={6}>
                <Form.Label>Employee ID</Form.Label>
                <Field
                  data-testid='employee-id'
                  type='text'
                  name='employee_id'
                  component={ValidatedFormInputField}
                  className={values.employeeIdExists ? styles.emailError : null}
                  onChange={this.handleChange}
                  disabled={true}
                />
                {!errors.employee_id && values.employeeIdExists && (
                  <div className={styles.emailErrorMsg}>
                    {values.employeeIdExists ? 'Employee ID already exists' : ''}
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={12} xs={12} md={12} lg={12}>
                <Form.Label>
                  Universal Account Number (UAN)
                  {values?.is_uan_mandatory && !values?.uan_not_available ? (
                    <span>*</span>
                  ) : (
                    ' '
                  )}{' '}
                  <OverlayTrigger
                    placement='top'
                    overlay={
                      <Tooltip>
                        <div className={styles.amountInfoTooltip}>
                          12-digit EPFO number linked to your EPF accounts.
                        </div>
                      </Tooltip>
                    }
                  >
                    <Image className={styles.infoIcon} name='info.svg' />
                  </OverlayTrigger>
                </Form.Label>
                <Row>
                  <Col sm={12} xs={12} md={12} lg={6}>
                    <Field
                      data-testid='uan-number'
                      type='number'
                      name='uan_number'
                      className={`UAN ${styles.uanInput} ${styles.noBottomMargin}`}
                      component={ValidatedFormInputField}
                      disabled={values?.uan_not_available}
                      showError={!values?.uan_not_available}
                      onBlur={(e) => {
                        this.props?.handleBlur(e);
                        setValue('uan_number', values?.uan_number, false);
                      }}
                    />
                    <div className={styles.uanCheckBoxContainer}>
                      <Form.Check
                        type='checkbox'
                        name='uan_not_available'
                        checked={values.uan_not_available}
                        onChange={(e) => {
                          setFieldValue('uan_not_available', e.target.checked);
                          if (e.target.checked) {
                            setTimeout(() => {
                              setFieldValue('uan_number', '');
                              let newErrors = { ...errors };
                              delete newErrors.uan_number;
                              setErrors(newErrors);
                            }, 100);
                          }
                        }}
                      />
                      <div className={styles.uanText}>I don't have UAN</div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className={styles.basicDetailSubmitBtn}>
              <Col sm={12} xs={12} md={12} lg={12} className={styles.mobileBtnContainer}>
                <Button
                  data-testid='next-btn'
                  type='submit'
                  className={
                    !enableBtn ||
                    this.props.isLoadingBgvCache ||
                    this.props.internalLoading ||
                    isDOBError.error
                      ? styles.disabledBtn
                      : styles.activeBtn
                  }
                  disabled={
                    !enableBtn ||
                    this.props.isLoadingBgvCache ||
                    this.props.internalLoading ||
                    isDOBError.error
                  }
                >
                  Next
                </Button>
              </Col>
            </Row>
          </Form>
          <p className={styles.basicDetailInfo}>All fields marked with an asterisk are required.</p>
        </Card.Body>
      </Card>
    );
  }
}

export default BasicDetails;
