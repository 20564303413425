import React, { useState } from 'react';
import { Field } from 'formik';
import { Form, Modal, Spinner, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import ValidatedFormInputField from '../../../../../core-components/ValidatedFormInputField';
import Button from '../../../../../core-components/Button';

import styles from './PasswordProtectedModal.module.scss';
import { useEffect } from 'react';

export default (props) => {
  const { handleSubmit } = props;
  const [showPassword, setShowPassword] = useState(true);

  const disabled =
    !props.values.password ||
    props.values.error ||
    props.values.submitting ||
    props.isValidatingDoc;

  useEffect(() => {
    props.setFieldValue('error', '');
  }, [props.values.password]);

  const handleClose = () => {
    props.SubOnHide();
  };
  const handleSkip = () => {
    props.skipFileHandler([...props.passwordProtectedFiles].pop()?.index);
  };

  const getCurrentIndexOfFile = () => {
    if (props.passwordProtectedFiles)
      return props?.passwordProtectedUploadedLength - props?.passwordProtectedFiles.length + 1;
  };

  const checkTypeIsIdAdd = () => {
    return props.checkType === 'id' || props.checkType === 'address';
  };
  return (
    <Modal
      size='lg'
      show={props.subShow}
      onHide={(e) => handleClose()}
      backdrop='static'
      fullscreen={true}
      aria-labelledby='contained-modal-title-vcenter'
      dialogClassName='passwordProtectedModal'
      backdropClassName='passwordProtectedModalBackdrop'
    >
      <Modal.Header closeButton>
        {props.values.maxSubmittingCount < 3 ? (
          <Modal.Title id='contained-modal-title-vcenter' className={styles.title}>
            Password Protected Document{' '}
            {!checkTypeIsIdAdd()
              ? `(${getCurrentIndexOfFile()} of ${props?.passwordProtectedUploadedLength})`
              : null}
          </Modal.Title>
        ) : (
          <Modal.Title id='contained-modal-title-vcenter' className={styles.title}>
            Password Protected Document Error
          </Modal.Title>
        )}
      </Modal.Header>

      {props?.passwordProtectedFiles && (
        <Modal.Body className={styles.fileName}>
          <span className={styles.heading}>File name:</span> {props?.values?.pdfName}
        </Modal.Body>
      )}
      {props.values.maxSubmittingCount < 3 ? (
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <div className={styles.searchInput}>
              <div>
                <p className={styles.subHeading}>
                  The document you have uploaded is protected with password. Please provide the
                  password below.
                </p>
                <p className={styles.important}>
                  IMPORTANT: Your password will not be saved and will only be used to unlock the
                  document.
                </p>
                <p className={styles.subHeading}>
                  If you wish to submit the password, please provide a document without any password
                  protection.
                </p>
              </div>
              <Col sm={12} xs={12} md={6} lg={6} style={{ padding: '0px' }}>
                <Form.Label>Password</Form.Label>
                <Form>
                  <Form.Row>
                    <Col>
                      <Field
                        type={showPassword ? 'password' : 'text'}
                        name='password'
                        autoComplete='off'
                        component={ValidatedFormInputField}
                      />
                    </Col>

                    <span>
                      {!props.values.submitting && !props.isValidatingDoc ? (
                        <FontAwesomeIcon
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                          icon={showPassword ? faEye : faEyeSlash}
                          color={'#366cf3'}
                          style={{ position: 'absolute', left: '310px', marginTop: '10px' }}
                        />
                      ) : (
                        <Spinner
                          className={styles.unlockSpinner}
                          style={{ position: 'absolute', left: '310px', marginTop: '10px' }}
                          animation='border'
                          variant='primary'
                          size='sm'
                        />
                      )}
                    </span>
                  </Form.Row>
                </Form>
              </Col>
              {props.values.error && <div className={styles.error}>{props.values.error}</div>}
            </div>
          </Modal.Body>
          <Modal.Footer className={styles.passwordSubmitBtn}>
            <Col sm={6} xs={6} md={3} lg={3}>
              <Button
                className={styles.cancelBtn}
                onClick={() => {
                  !checkTypeIsIdAdd() ? handleSkip() : handleClose();
                }}
              >
                {!checkTypeIsIdAdd() ? 'Skip' : 'Cancel'}
              </Button>
            </Col>

            <Col sm={6} xs={6} md={3} lg={3} className={styles.passwordSubmitBtn}>
              <Button
                disabled={disabled}
                onClick={() => {
                  setShowPassword(true);
                  props.handleSubmit();
                }}
              >
                Submit
              </Button>
            </Col>
          </Modal.Footer>
        </Form>
      ) : (
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <div className={styles.searchInput}>
              <div className={styles.maximumErrorMsg}>
                You have reached the maximum number of attempts to unlock this document.
              </div>
              <div>
                <p className={styles.subHeading}>
                  We are unable to unlock the document with the password you have provided.
                </p>
                <p className={styles.subHeading}>
                  <span className={styles.important}>Option 1:</span> If you are certain that the
                  password is accurate and you are able to open to it yourself, then you can proceed
                  by saving the password and our team will attempt to open this file manually.
                </p>
                <p className={styles.subHeading}>
                  <span className={styles.important}>Option 2:</span> Upload the document without
                  password protection
                </p>
              </div>
              <Col sm={12} xs={12} md={6} lg={6}>
                <Form.Label>Password</Form.Label>
                <Form>
                  <Form.Row>
                    <Col>
                      <Field
                        type={showPassword ? 'password' : 'text'}
                        name='password'
                        autoComplete='off'
                        component={ValidatedFormInputField}
                      />
                    </Col>

                    <span>
                      {!props.values.submitting ? (
                        <FontAwesomeIcon
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                          icon={showPassword ? faEye : faEyeSlash}
                          color={'#366cf3'}
                          style={{ position: 'absolute', left: '310px', marginTop: '10px' }}
                        />
                      ) : (
                        <Spinner
                          className={styles.unlockSpinner}
                          style={{ position: 'absolute', left: '310px', marginTop: '10px' }}
                          animation='border'
                          variant='primary'
                          size='sm'
                        />
                      )}
                    </span>
                  </Form.Row>
                </Form>
              </Col>
            </div>
          </Modal.Body>
          <Modal.Footer className={styles.passwordSubmitBtn}>
            <Col sm={6} xs={6} md={3} lg={3}>
              <Button
                className={styles.cancelBtn}
                onClick={() => {
                  !checkTypeIsIdAdd() ? handleSkip() : handleClose();
                }}
              >
                {!checkTypeIsIdAdd() ? 'Skip' : 'Cancel'}
              </Button>
            </Col>

            <Col sm={6} xs={6} md={3} lg={3} className={styles.passwordSubmitBtn}>
              <Button
                onClick={() => {
                  props.handleSubmit();
                }}
              >
                Save Password
              </Button>
            </Col>
          </Modal.Footer>
        </Form>
      )}
    </Modal>
  );
};
