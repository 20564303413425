import { connect } from 'react-redux';
import { compose } from 'redux';

import {
  setBulkUploadData as setBulkUploadDataAPI,
  getCandidateFormConfig as getCandidateFormConfigAPI
} from '../../../../actions/company';
const mapStateToProps = (state) => {
  return {
    companyId: (state && state?.profile?.profile?.company_user_mapping?.company_id) || '',
    rows: (state && state?.bulkData?.rows) || [],
    formConfig: state?.formConfig
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setBulkUploadDataAPI: (data) => dispatch(setBulkUploadDataAPI(data)),
    getCandidateFormConfigAPI: (data) => dispatch(getCandidateFormConfigAPI(data))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps));
