import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faBan } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Form } from 'react-bootstrap';

import { PLAIN_CHECK_ICONS } from '../../../../../../../utils/commonConstant';

import styles from './BooleanField.module.scss';
import { convertToIndianNumeration } from '../../../../../../../utils/utilities';
import { maskPriceForAdmins } from '../../../../../frontend-common/CreditBuyPackagePage/UtilComponents/UtilitiesFunction';

export default (props) => {
  const {
    cPkgDetails,
    sPkgDetails,
    aPkgDetails,
    onValueChange,
    value,
    price,
    type,
    heading,
    disabled
  } = props;

  return (
    <div className={styles.booleanContainer}>
      <Row>
        <Col lg={10} md={10} sm={10} xs={10}>
          <Row>
            <Col lg={1} md={1} sm={1} xs={1}>
              <span className={styles.iconContainer}>
                <FontAwesomeIcon icon={faPlusCircle} color='#389E0D' className={styles.plusIcon} />
                <FontAwesomeIcon
                  icon={PLAIN_CHECK_ICONS[type] ? PLAIN_CHECK_ICONS[type].icon : faBan}
                  color='rgba(39, 85, 254, 0.5)'
                  style={{ fontSize: '18px' }}
                />
              </span>
            </Col>
            <Col lg={10} md={10} sm={10} xs={10}>
              <span className={styles.heading}>{heading}</span>
            </Col>
          </Row>
        </Col>
        <Col lg={2} md={2} sm={2} xs={2} className={styles.checkboxFormGrp}>
          <span className={styles.label}>
            <span className={styles.rupee}>&#8377;</span>
            {maskPriceForAdmins(price, props?.accessLevel, props?.paymentModelType)}
          </span>
          <Form.Group controlId={`${type}_check`}>
            <Form.Check
              data-testid={`${type}_check`}
              name={`${type}_check`}
              id={`${type}_check`}
              type='checkbox'
              checked={value}
              disabled={disabled}
              onChange={(e) => {
                onValueChange(e.target.checked);
              }}
              label={''}
            />
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};
