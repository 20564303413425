// Import necessary libraries
import React from 'react';
import { isArray, debounce, isEmpty } from 'lodash';
import Select from 'react-select';
import { Table, Card, Tooltip, Form, Col, OverlayTrigger, Spinner } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import { DropdownList } from 'react-widgets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination as SwiperPagination, Autoplay } from 'swiper/modules';
import moment from 'moment';
import { Link } from 'react-router-dom';

import AppConstants from '../../../core-components/AppConstants';

// Import styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

// Import local components & utils
import {
  convertToIndianNumeration,
  covertObjToQueryString,
  covertQueryStringToObject,
  getBanners,
  getTrimmedValue,
  mapCustomizationTypes,
  toCamelCaseTemp
} from '../../../utils/utilities';
import {
  CANDIDATES_STATUSES,
  CANDIDATES_TIME_RANGE,
  COMPLETE_STATUS,
  FORM_PENDING_STATUS,
  IN_PROGRESS_STATUS,
  INSUFFICIENCY_STATUS,
  ONHOLD_STATUS
} from '../../../utils/commonConstant';
import { STATUSES } from '../../../utils';
import VerificationIcons from './VerificationIcons';
import FilterModal from './FilterModal';
import AdminModals from '../Administrators/AdminModals';
import Icon from '../../../core-components/Icon';
import Pagination from '../../../core-components/Pagination';
import Input from '../../../core-components/Input';
import Image from '../../../core-components/Image';
import Banner from '../../../core-components/Banner/Banner';
import styles from './Candidates.module.scss';
import EditEmail from '../UtilsComponents/EditEmail';
import AddCandidateButton from '../../../core-components/AddCandidate/AddCandidateButton';
import ReferAndEarnBanner from '../Banners/ReferAndEarn/ReferAndEarnBanner';
// import BulkSuccessModal from '../ReferAndEarn/Modals/BulkSuccessModal';
import { getRecommendedChecks } from '../../../api/company';
import SlackConnectBanner from '../Banners/SlackConnect';
import UpsellBanner from '../Banners/UpsellBanner';
import AlumniVerification from '../Banners/AlumniVerification/AlumniVerification';
import MarketingBanner from '../../MarketingBanner/MarketingBanner';
import RazorpayBanner from '../Banners/Razorpay/RazorpayBanner';
import { capitalizeName } from '../frontend-common/utils/Utilities';

export default class extends React.Component {
  pageSizes = [10, 20, 50, 100];
  defaultPageSize = this.pageSizes[0];
  maxPageSize = this.pageSizes[this.pageSizes.length - 1];
  minimumSearchQueryLength = 3;
  urlParams = new URLSearchParams(this.props.location.search);
  tagFromUrl = this.urlParams.get('tag');
  categoryFromUrl = this.urlParams.get('category');

  constructor(props) {
    super(props);
    this.state = {
      upsellRecommendationData: {},
      pageSize: this.pageSizes[0],
      pageNumber: 0,
      query: '',
      count: 0,
      data: [],
      isData: true,
      loading: true,
      status: [],
      category: null,
      tag: null,
      search: '',
      isSearch: false,
      categories: [],
      tags: [],
      tagSelect: [],
      userCategorySelect: [],
      modalShowContent: '',
      modalTitle: null,
      modalSubTitle: null,
      modalShowStatus: false,
      modalButton: null,
      modalShow: false,
      statuses: CANDIDATES_STATUSES,
      timeRanges: CANDIDATES_TIME_RANGE,
      isAdmin: localStorage.accessLevel === 'admin',
      selectedTime: -1,
      showAllCandidate: props?.paymentDetails?.emailBounceData?.count === 0,
      dateRange: {
        from: null,
        to: null
      },
      showEditModal: false,
      emailEditText: '',
      emailEditId: '',
      showReferAndEarnModal: this.getFromUrlReferEarnModalState(this.props.location),
      carouselBanners: []
    };
  }
  extractFilterFromURL() {
    this.setState(() => {
      this.fetchCategories().then(() => {
        if (this.categoryFromUrl) {
          const category = this.state.categories.find(
            (catg) => catg.label === this.categoryFromUrl
          );

          if (category) {
            this.applyCategory([category]);
          }
        }
      });

      this.fetchTags().then(() => {
        if (this.tagFromUrl) {
          const tag = this.state.tags.find((tg) => tg.label === this.tagFromUrl);
          if (tag) {
            this.applyTag([tag]);
          }
        }
      });
      this.setState({ showAllCandidate: true });
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    if (this.props?.location?.state === 'deleted') {
      this.setState({
        modalShowStatus: true,
        modalShowContent: 'Candidate has been deleted successfully'
      });
    }

    if (this.tagFromUrl || this.categoryFromUrl) {
      this.extractFilterFromURL()?.then(() => this.loadCandidates());
    } else {
      this.fetchCategories();
      this.fetchTags();
      this.loadCandidates();
    }
  }

  loadCandidates() {
    this.props?.invalidateCandidates();
    this.props?.getPaymentDetails();

    const limit = this.state.pageSize;
    const offset = this.state.pageNumber;
    let status = this.getStatus(this.props.location);
    const statusMap = {
      candidatesAdded: null,
      formPending: FORM_PENDING_STATUS,
      inProgress: IN_PROGRESS_STATUS,
      insufficiency: INSUFFICIENCY_STATUS,
      onHold: ONHOLD_STATUS,
      verificationComplete: COMPLETE_STATUS
    };
    // const statusMap = {
    //   verificationsDone: 1,
    //   candidatesAdded: '',
    //   pendingForms: 3,
    //   insufficiency: 7,
    //   checkInProgress: 0,
    //   issuesFound: 6
    // };
    status = statusMap[status] ?? [];
    this.getRecommendationsChecksApi('CANDIDATE_LIST');
    this.setState(
      {
        status: [...status]
      },
      () => {
        this.getCandidates(
          limit,
          offset,
          status,
          null,
          [],
          [],
          null,
          this.state.dateRange,
          this.state.showAllCandidate
        );
      }
    );
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps?.paymentDetails?.emailBounceData?.count === 0) {
      return {
        showAllCandidate: nextProps?.paymentDetails?.emailBounceData?.count === 0
      };
    }
  }

  getFromUrlShowAllCandidateValue = (location) => {
    const query = new URLSearchParams(location.search);
    return query.get('_showAllCandidates');
  };

  getFromUrlReferEarnModalState = (location) => {
    const query = new URLSearchParams(location.search);
    return query.get('_showReferModal') === 'true' ? true : false;
  };

  isShowAllCandidate = (paramValue) => {
    if (paramValue === null) return null;

    const showAllCandidate = this.state.showAllCandidate;

    if (paramValue === 'true' && !showAllCandidate) {
      return true;
    }
    if (paramValue === 'false' && showAllCandidate) return false;
    return null;
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const showCandidateTooltip = new URLSearchParams(this.props.location.search).get(
      'showCandidateTooltip'
    );
    if (showCandidateTooltip) {
      return;
    }
    const isShowAllCandidate = this.getFromUrlShowAllCandidateValue(this.props.location);
    const isReferAndEarnModal = this.getFromUrlReferEarnModalState(this.props.location);
    const paramValue = this.isShowAllCandidate(isShowAllCandidate);

    if (
      this.state.status === null &&
      this.getStatus(this.props.location) !== 'candidatesAdded' &&
      isShowAllCandidate === null &&
      !isReferAndEarnModal &&
      this.props.location.search !== '' &&
      !(this.tagFromUrl || this.categoryFromUrl)
    ) {
      this.initializeStatusFromURL(prevProps);
    }

    if (isShowAllCandidate !== null && paramValue !== null) {
      this.setState(() => ({
        showAllCandidate: paramValue
      }));
    }

    const candidateUrlParam = new URLSearchParams(this.props.location.search).has(
      '_showAllCandidates'
    );

    if (this.state.showAllCandidate !== prevState.showAllCandidate && candidateUrlParam) {
      this.getCandidates(
        10,
        0,
        this.state.status,
        this.state.search,
        this.state.tagSelect,
        this.state.userCategorySelect,
        this.state.selectedTime,
        this.state.dateRange,
        this.state.showAllCandidate
      );
    }

    if (
      prevProps.paymentDetails !== this.props.paymentDetails &&
      this.props.paymentDetails.loading &&
      !(this.tagFromUrl || this.categoryFromUrl)
    ) {
      this.getCandidates(
        10,
        0,
        this.state.status,
        this.state.search,
        this.state.tagSelect,
        this.state.userCategorySelect,
        this.state.selectedTime,
        this.state.dateRange,
        paramValue === null ? this.state.showAllCandidate : paramValue
      );
    }

    if (prevProps.paymentDetails !== this.props.paymentDetails) {
      this.setState({
        carouselBanners: getBanners({
          paymentDetails: this.props?.paymentDetails,
          upsellRecommendationData: this.state.upsellRecommendationData,
          isCandidatePage: true
        })
      });
    }
  }

  initializeStatusFromURL(prevProps) {
    let status = this.getStatus(prevProps.location);
    const statusMap = {
      verificationsDone: 1,
      candidatesAdded: '',
      pendingForms: 3,
      insufficiency: 7,
      checkInProgress: 0,
      issuesFound: 6
    };
    status = statusMap[status] ?? '';

    this.setState({ status }, () => {
      this.getCandidates(
        this.state.pageSize,
        this.state.pageNumber,
        status,
        null,
        [],
        [],
        null,
        this.state.dateRange,
        this.state.showAllCandidate
      );
    });
  }

  fetchCategories = () => {
    let categoriesList = [];
    return this?.props?.getCategories().then(() => {
      this?.props?.categories?.userCategories &&
        this?.props?.categories?.userCategories?.map((category) => {
          return categoriesList.push({ label: category.categoryName, value: category.id });
        });
      this.setState({
        categories: categoriesList
      });
    });
  };

  fetchTags = () => {
    let tagsList = [];
    return this.props.getTags().then(() => {
      this?.props?.tags?.categories &&
        this?.props?.tags?.categories?.map((tag) => {
          return tagsList.push({ label: tag.category, value: tag.id });
        });
      this.setState({
        tags: tagsList
      });
    });
  };

  formatCategoryTag = (catTagArr) => {
    const formattedValue = [];
    if (isArray(catTagArr) && catTagArr.length > 0) {
      for (let value of catTagArr) {
        formattedValue.push(value['value']);
      }
    }
    return formattedValue;
  };

  getCandidates(
    limit,
    offset,
    status,
    search,
    tag = [],
    userCategory = [],
    days,
    dateRange,
    showAllCandidate
  ) {
    const tagString = tag?.map((item) => item.value).join(',');
    const categoryString = userCategory?.map((item) => item.value).join(',');
    const statusString = status?.map((item) => item.value).join(',');
    days = (days && days != 1 && days != -1 && days) || null;
    dateRange = {
      from: (dateRange && dateRange.from) || null,
      to: (dateRange && dateRange.to) || null
    };
    // status = dateRange.from && dateRange.to ? 1 : status >= 0 && status !== '' ? status : null;

    this.setState({ loading: true });

    this.props
      ?.getCandidates(
        limit.toString(),
        offset.toString(),
        statusString,
        search,
        tagString,
        categoryString,
        days,
        dateRange,
        showAllCandidate
      )
      .then(() => {
        if (this?.props?.candidates?.all?.length && this?.props?.candidates?.all[offset]) {
          this.setState({
            data:
              this?.props?.candidates?.all?.length &&
              this?.props?.candidates?.all[offset]?.candidates,
            count:
              this?.props?.candidates?.all?.length && this?.props?.candidates?.all[offset]?.count,
            loading: this?.props?.candidates?.loading,
            dateRange: Object.assign({}, dateRange),
            status: status?.length > 0 ? status : [],
            isData: !(
              this?.props?.candidates?.all?.length &&
              (this?.props?.candidates?.all[offset]?.count === 0 ||
                this?.props?.candidates?.all[offset]?.candidates?.length === 0)
            )
          });
        } else {
          this.setState({
            data: [],
            count: 0,
            loading: this?.props?.candidates?.loading,
            dateRange: Object.assign({}, dateRange),
            status: status?.length > 0 ? [...status] : [],
            isData: false
          });
        }
      });
  }
  applyStatus(status) {
    const hasOtherValues = status?.some((item) => item.value !== 1);
    this.setState(
      {
        status: status?.length > 0 ? [...status] : [],
        pageNumber: 0,
        pageSize: 10,
        dateRange:
          !status || hasOtherValues
            ? Object.assign({}, { from: null, to: null })
            : this.state.dateRange,
        selectedTime: !status || hasOtherValues ? -1 : this.state.selectedTime
      },
      () => {
        this.getCandidates(
          10,
          0,
          this.state.status,
          this.state.search,
          this.state.tagSelect,
          this.state.userCategorySelect,
          this.state.selectedTime,
          this.state.dateRange,
          this.state.showAllCandidate
        );
      }
    );
  }

  applySearch(search) {
    this.setState({
      search: search,
      pageNumber: 0,
      pageSize: 10,
      isSearch: search !== '' ? true : false,
      loading: true
    });
    this.debounceSearch();
  }

  applyCategory(category) {
    this.setState(
      {
        userCategorySelect: category && category.length > 0 ? [...category] : [],
        pageNumber: 0,
        pageSize: 10
      },
      () => {
        this.getCandidates(
          10,
          0,
          this.state.status,
          this.state.search,
          this.state.tagSelect,
          this.state.userCategorySelect,
          this.state.selectedTime,
          this.state.dateRange,
          this.state.showAllCandidate
        );
      }
    );
  }

  applyTag(tag) {
    this.setState(
      {
        tagSelect: tag && tag.length > 0 ? [...tag] : [],
        pageNumber: 0,
        pageSize: 10
      },
      () => {
        this.getCandidates(
          10,
          0,
          this.state.status,
          this.state.search,
          this.state.tagSelect,
          this.state.userCategorySelect,
          this.state.selectedTime,
          this.state.dateRange,
          this.state.showAllCandidate
        );
      }
    );
  }

  applyTime = (value) => {
    if (value != 0) {
      this.setState(
        {
          selectedTime: (value != -1 && value) || -1,
          status: value != -1 ? [{ label: 'Completed', value: 1 }] : [],
          pageNumber: 0,
          pageSize: 10,
          dateRange: Object.assign({}, { from: null, to: null })
        },
        () => {
          this.getCandidates(
            10,
            0,
            this.state.status,
            this.state.search,
            this.state.tagSelect,
            this.state.userCategorySelect,
            this.state.selectedTime,
            this.state.dateRange,
            this.state.showAllCandidate
          );
        }
      );
    } else {
      this.setState(
        {
          selectedTime: 0,
          pageNumber: 0,
          pageSize: 10
        },
        () => {
          this.modalToggle(
            (this.modalTitle = 'Set custom range '),
            (this.modalButton = 'Confirm'),
            (this.modalSubTitle = 'Set custom range')
          );
        }
      );
    }
  };

  paginate(limit, offset) {
    this.getCandidates(
      limit,
      offset,
      this.state.status,
      this.state.search,
      this.state.tagSelect,
      this.state.userCategorySelect,
      this.state.selectedTime,
      this.state.dateRange,
      this.state.showAllCandidate
    );
  }

  modalHideStatus = () => {
    this.setState({
      modalShowStatus: false,
      modalShowContent: ''
    });
    if (this?.props?.location?.state === 'deleted') {
      this?.props?.history?.push('/candidates');
    }
  };
  modalToggle = () => {
    this.setState({
      modalShow: true,
      modalTitle: this.modalTitle,
      modalSubTitle: this.modalSubTitle,
      modalButton: this.modalButton
      // status: [{ label: 'Completed', value: 1 }]
    });
  };

  modalHide = (value) => {
    this.setState({
      modalShow: false,
      selectedTime: -1
      // status: value ? [{ label: 'Completed', value: 1 }] : []
    });
  };

  debounceSearch = debounce(
    () =>
      this.getCandidates(
        10,
        0,
        this.state.status,
        this.state.search ? getTrimmedValue(this.state.search, true) : '',
        this.state.tagSelect,
        this.state.userCategorySelect,
        this.state.selectedTime,
        this.state.dateRange,
        this.state.showAllCandidate
      ),
    1500
  );

  getRecommendationsChecksApi(currentRoute) {
    const params = {
      page: currentRoute
    };

    getRecommendedChecks(params)
      .then((res) => {
        this.setState(
          {
            upsellRecommendationData: toCamelCaseTemp(res?.data?.data)
          },
          () => {
            this.setState({
              carouselBanners: getBanners({
                paymentDetails: this.props?.paymentDetails,
                upsellRecommendationData: this.state.upsellRecommendationData,
                isCandidatePage: true
              })
            });
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleSearch = (event) => this.applySearch(event.target.value);

  handleBounceFilter = (e) => {
    this.setState((prevState, props) => ({
      showAllCandidate: !prevState.showAllCandidate,
      pageNumber: 0,
      pageSize: 10
    }));
  };

  editEmailHandlerModal = (isSubmit = false) => {
    this.setState({ showEditModal: !this.state.showEditModal });
    if (isSubmit) {
      this.props?.getPaymentDetails();
    }
  };
  getEmailData = (emailData) => {
    /**
     * Note:
     * * Limit one log per day(last log for every day).
     * * Limit to last 10 logs only.
     */

    const filteredLog = new Map();

    emailData.forEach((item, index) => {
      const dateObj = new Date(item.eventTime);
      const date = dateObj.getDate();

      if (!filteredLog.has(date)) {
        filteredLog.set(date, item);
      }
    });

    let value = [];

    filteredLog.forEach((i) => {
      value.push(
        <p>
          Email last viewed on {moment(i.eventTime).utc('GMT+5:30').format('HH:mm')} on{' '}
          {moment(i.eventTime).utc('GMT+5:30').format('DD-MMM-YYYY')}
        </p>
      );
    });

    if (value.length > 10) value = value.splice(0, 10);

    return value;
  };

  getStatus = (location) => {
    const query = new URLSearchParams(location.search);
    return query.get('_type');
  };

  render() {
    const { pageNumber, pageSize, count, data, loading } = this.state;
    const pageSizeOptions = [];
    //multiple select dropdown style
    const customStyles = {
      container: (provided, state) => ({
        ...provided,
        width: '174px',
        marginLeft: '12px'
      }),
      option: (provided, state) => ({
        ...provided,
        fontFamily: 'Poppins',
        fontSize: '14px',
        lineHeight: '21px',
        color: '#666666',
        borderRadius: '4px',
        padding: '10px 20px',
        border: 'none',
        background: state.isFocused ? '#ffffff' : ''
      }),
      control: () => ({
        border: '1px solid #c2cfff',
        boxSizing: 'border-box',
        borderRadius: '4px',
        width: 'auto',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#333333',
        display: 'flex',
        maxHeight:
          this.state.tagSelect.length > 1 || this.state.userCategorySelect.length > 1
            ? '70px'
            : 'unset',
        height: this.state.tagSelect.length > 1 ? 'auto' : '45px'
      }),
      placeholder: (defaultStyles) => {
        return {
          ...defaultStyles,
          color: '#333333'
        };
      },
      valueContainer: (base) => ({
        ...base,
        overflow:
          this.state.tagSelect.length > 1 ||
          this.state.userCategorySelect.length > 1 ||
          this.state.status.length
            ? 'auto'
            : 'none',
        textOverflow: 'ellipsis',
        maxWidth: '78%'
      }),
      input: (provided, state) => ({
        ...provided,
        color: 'transparent'
      })
    };

    const IndicatorSeparator = ({ innerProps }) => {
      return <span style={{ display: 'none' }} {...innerProps} />;
    };

    let index = 0;
    do {
      const value = this.pageSizes[index] > count ? count : this.pageSizes[index];
      if (!value) break;
      pageSizeOptions.push(
        <option key={index} value={value}>
          {value}
        </option>
      );
    } while (this.pageSizes[index++] < count);

    const CarouselBanners = {
      upsell: (
        <UpsellBanner
          getRecommendationsChecksApi={() => this.getRecommendationsChecksApi('CANDIDATE_LIST')}
          upsellRecommendationData={this.state.upsellRecommendationData}
        />
      ),
      refer: <ReferAndEarnBanner history={this.props.history} />,
      connect: (
        <SlackConnectBanner
          history={this.props.history}
          getBanner={() =>
            this.setState({
              carouselBanners: getBanners({
                paymentDetails: this.props?.paymentDetails,
                upsellRecommendationData: this.state.upsellRecommendationData,
                isCandidatePage: true
              })
            })
          }
        />
      ),
      alumniVerification: <AlumniVerification />,
      marketing: <MarketingBanner />,
      razorpay: <RazorpayBanner />
    };
    const Carousel = () => {
      return (
        <div className={styles.cContainer}>
          <div className='swiper-custom'>
            {this.state.carouselBanners.length ? (
              <Swiper
                loop={true}
                slidesPerView={1}
                speed={800}
                autoplay={{
                  delay: 6000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                  waitForTransition: true
                }}
                pagination={{ clickable: true }}
                modules={[Autoplay, SwiperPagination]}
                className='mySwiper'
              >
                {this.state.carouselBanners.map((item) => {
                  return (
                    <SwiperSlide>
                      <div>{CarouselBanners[item]}</div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            ) : null}
          </div>
        </div>
      );
    };
    const companyCustomizations = this.props?.paymentDetails?.companyCustomizations?.length
      ? mapCustomizationTypes(this.props?.paymentDetails?.companyCustomizations)
      : [];
    return (
      <div>
        <AdminModals
          show={this.state.modalShowStatus}
          onHide={this.modalHideStatus}
          content={this.state.modalShowContent}
        />
        <EditEmail
          show={this.state.showEditModal}
          onHide={this.editEmailHandlerModal}
          email={this.state.emailEditText}
          candidateId={this.state.emailEditId}
        />
        <FilterModal
          show={this.state.modalShow}
          onHide={this.modalHide}
          modaltitle={this.modalTitle}
          subTitle={this.modalSubTitle}
          button={this.modalButton}
          filterData={{
            search: this.state.search,
            days: this.state.selectedTime,
            status: [{ label: 'Completed', value: 1 }],
            tag: this.state.tagSelect,
            usercategory: this.state.userCategorySelect,
            dateRange: this.state.dateRange,
            showAllCandidate: this.state.showAllCandidate
          }}
          parentGetCandidate={this.getCandidates.bind(this)}
          {...this.props}
        />
        <div className={styles.candidatesWrapper}>
          <div className={styles.header}>
            <div className={styles.heading}>
              {'Candidates'}
              {!loading && typeof count === 'number' && ` (${convertToIndianNumeration(count)})`}
            </div>
            <AddCandidateButton />
          </div>

          {Carousel()}

          <Card className={styles.candidatesCard}>
            {this.props?.paymentDetails &&
              this.props?.paymentDetails?.emailBounceData &&
              this.props?.paymentDetails?.emailBounceData?.count > 0 && (
                <Banner clsName='bounceEmailBanner' name='whiteWarning.svg'>
                  <div>
                    Emails to some of your candidates have bounced. Please correct them to avoid
                    delays in verifications.
                  </div>
                  <div className={styles.showAllCandidates}>
                    {this.state.showAllCandidate ? (
                      <Link
                        style={{ color: 'white' }}
                        to={{ pathname: '/candidates', search: '?_showAllCandidates=false' }}
                      >
                        <span>
                          <Image name='emailBounceIconWhite.svg' style={{ marginRight: '3px' }} />
                          Show Bounced Only
                        </span>
                      </Link>
                    ) : (
                      <Link
                        style={{ color: 'white' }}
                        to={{ pathname: '/candidates', search: '?_showAllCandidates=true' }}
                      >
                        Show all candidates
                      </Link>
                    )}
                  </div>
                </Banner>
              )}
            <nav className={styles.candidatesCardHeader}>
              <div className={styles.headingContent}>
                {!loading &&
                  count > 0 &&
                  `${pageNumber * pageSize + 1}-${
                    pageNumber * pageSize + pageSize > count
                      ? count
                      : pageNumber * pageSize + pageSize
                  } of ${count}`}
              </div>
              <div className={styles.filter}>
                <div className={styles.searchInput}>
                  <Image style={{ width: '20px' }} name='searchInputIcon.svg' />
                  <Input
                    placeholder={'Search Name, Email, Phone'}
                    className={styles.inputText}
                    value={this.state.search}
                    onBlur={() => {
                      this.setState({
                        ...this.state,
                        search: getTrimmedValue(this.state.search, true)
                      });
                    }}
                    onChange={this.handleSearch}
                  />
                  {this.state.isSearch ? (
                    <Image
                      onClick={() => {
                        this.applySearch('');
                      }}
                      style={{ width: '20px' }}
                      name='closeIcon.svg'
                    />
                  ) : null}
                </div>
                <div className={styles.customDropdown}>
                  <div className={`${styles.customLabel} ${styles.customLabelDate}`}>
                    {'Completed Within'}
                  </div>
                  <DropdownList
                    data={this.state.timeRanges}
                    textField='label'
                    valueField='value'
                    defaultValue={-1}
                    value={
                      this.state.dateRange.from && this.state.dateRange.to
                        ? ''
                        : this.state.selectedTime
                    }
                    placeholder={
                      this.state.dateRange.from && this.state.dateRange.to
                        ? `${moment(this.state.dateRange.from).format('MMM Do, YY')}-${moment(
                            this.state.dateRange.to
                          ).format('MMM Do, YY')}`
                        : 'Select'
                    }
                    className={styles.widgetDropdownTime}
                    containerClassName={'widgetDropdownTimeContainer'}
                    selectIcon={<Image name='dropdownArrow.svg' />}
                    onChange={(value) => {
                      this.applyTime(value?.value);
                    }}
                  />
                </div>
                <div className={styles.customDropdown}>
                  <div className={`${styles.customLabel} ${styles.customLabelStatus}`}>
                    {'Status'}
                  </div>
                  <Select
                    components={{ IndicatorSeparator }}
                    isMulti
                    placeholder='All Statuses'
                    name='all_statuses'
                    options={this.state.statuses}
                    noOptionsMessage={() => 'No Status found'}
                    styles={customStyles}
                    isClearable={false}
                    value={this.state.status}
                    onChange={(value) => {
                      this.applyStatus(value);
                    }}
                  />
                </div>
                <div className={styles.customDropdown}>
                  <div className={`${styles.customLabel} ${styles.customLabelStatus}`}>
                    {'Category'}
                  </div>
                  <Select
                    components={{ IndicatorSeparator }}
                    isMulti
                    placeholder='All categories'
                    name='all_categories'
                    options={this.state.categories}
                    noOptionsMessage={() => 'No Category found'}
                    styles={customStyles}
                    isClearable={false}
                    value={this.state.userCategorySelect}
                    onChange={(value) => {
                      this.applyCategory(value);
                    }}
                  />
                </div>
                <div className={styles.customDropdown}>
                  <div className={`${styles.customLabel} ${styles.customLabelStatus}`}>{'Tag'}</div>
                  <Select
                    components={{ IndicatorSeparator }}
                    isMulti
                    placeholder='All tags'
                    name='all_tags'
                    options={this.state.tags}
                    noOptionsMessage={() => 'No Tags found'}
                    styles={customStyles}
                    isClearable={false}
                    value={this.state.tagSelect}
                    onChange={(value) => this.applyTag(value)}
                  />
                </div>
              </div>
            </nav>
            <Card className={styles.tableCard}>
              <Table className={styles.candidatesTable}>
                <thead>
                  <tr>
                    <th>#</th>
                    <th className={styles.mainField}>Name</th>
                    {this.state.isAdmin ? <th>Added By</th> : null}
                    <th>Status</th>
                    <th>Verifications</th>
                    <th>Category</th>
                    <th>Tags</th>
                    <th className={styles.alignCenter}>Report</th>
                  </tr>
                </thead>
                {this.state.isData && !loading ? (
                  <tbody>
                    {loading
                      ? Array.apply(null, { length: pageSize })
                          .map(Number.call, Number)
                          .map((loadData, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  <ContentLoader height={20} width={20}>
                                    <rect x='0' y='0' width='20' height='14' />
                                  </ContentLoader>
                                </td>
                                <td className={styles.mainField}>
                                  <ContentLoader height={20} width={200}>
                                    <rect x='0' y='0' width='200' height='14' />
                                  </ContentLoader>
                                </td>
                                {this.state.isAdmin ? (
                                  <td className={styles.mainField}>
                                    <ContentLoader height={20} width={100}>
                                      <rect x='0' y='0' width='100' height='14' />
                                    </ContentLoader>
                                  </td>
                                ) : null}
                                <td>
                                  <ContentLoader height={20} width={100}>
                                    <rect x='0' y='0' width='100' height='14' />
                                  </ContentLoader>
                                </td>
                                <td>
                                  <ContentLoader height={20} width={50}>
                                    <rect x='0' y='0' width='50' height='14' />
                                  </ContentLoader>
                                </td>
                                <td>
                                  <ContentLoader height={20} width={80}>
                                    <rect x='0' y='0' width='80' height='14' />
                                  </ContentLoader>
                                </td>
                                <td className={styles.displayInline}>
                                  <ContentLoader height={20} width={80}>
                                    <rect x='0' y='0' width='80' height='14' />
                                  </ContentLoader>
                                </td>
                                <td className={styles.reportIcon}>
                                  <ContentLoader height={20} width={80}>
                                    <rect x='0' y='0' width='80' height='14' />
                                  </ContentLoader>
                                </td>
                              </tr>
                            );
                          })
                      : data &&
                        data
                          .sort(
                            (a, b) => moment(b.candidate.createdAt) - moment(a.candidate.createdAt)
                          )
                          .map((rowData, i) => {
                            return (
                              <tr key={i}>
                                <td>{i + 1 + pageSize * pageNumber}</td>
                                <td className={styles.mainField}>
                                  <div data-testid='name' className={styles.nameField}>
                                    <div className={styles.mainNameField}>
                                      <Link
                                        to={{
                                          pathname: '/viewCandidate',
                                          search: `candidateId=${rowData.candidateId}`
                                        }}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        className={styles.nameButton}
                                      >
                                        {capitalizeName(rowData.candidate.name)}
                                      </Link>
                                      {rowData.candidate.emailRejectType === 'NA' ||
                                      isEmpty(rowData?.candidate?.email) ? null : (
                                        <OverlayTrigger
                                          placement='bottom'
                                          overlay={
                                            <Tooltip id={'EmailBounced'}>
                                              <p>{'Email has bounced'}</p>
                                            </Tooltip>
                                          }
                                        >
                                          <span className={styles.mailIcon}>
                                            <Image name='emailBouncedIcon.svg' />
                                          </span>
                                        </OverlayTrigger>
                                      )}
                                    </div>
                                    <div>
                                      {rowData.status === 3 &&
                                      rowData.candidate.emailTrackingLogs &&
                                      rowData.candidate.emailTrackingLogs.length &&
                                      rowData.candidate.emailRejectType === 'NA' ? (
                                        <OverlayTrigger
                                          placement='bottom'
                                          overlay={
                                            <Tooltip id={'emailData'}>
                                              {this.getEmailData(
                                                rowData.candidate.emailTrackingLogs
                                              )}
                                            </Tooltip>
                                          }
                                        >
                                          <div className={styles.mailIcon}>
                                            <FontAwesomeIcon
                                              icon={faEye}
                                              color={'#366cf3'}
                                              style={{ marginRight: '8px' }}
                                            />
                                          </div>
                                        </OverlayTrigger>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className={styles.email}>
                                    <OverlayTrigger
                                      placement='bottom'
                                      overlay={
                                        <Tooltip id={'EmailBounced'} className='emailTooltip'>
                                          <p>{rowData.candidate.email}</p>
                                        </Tooltip>
                                      }
                                    >
                                      <span className={styles.emailField}>
                                        {rowData.candidate.email}
                                      </span>
                                    </OverlayTrigger>

                                    {rowData.candidate.emailRejectType === 'NA' ||
                                    isEmpty(rowData?.candidate?.email)
                                      ? null
                                      : (rowData.status == 3 || rowData.status == 11) && (
                                          <div
                                            className={styles.editCategoryIcon}
                                            onClick={() => {
                                              this.setState({
                                                emailEditText: rowData.candidate.email
                                              });
                                              this.setState({ emailEditId: rowData.candidate.id });
                                              this.editEmailHandlerModal();
                                            }}
                                          >
                                            <Icon
                                              icon={'EditCandidateCategory'}
                                              color={'#333333'}
                                              height={16}
                                              width={16}
                                            />
                                          </div>
                                        )}
                                  </div>
                                </td>

                                {this.state.isAdmin ? (
                                  <td>
                                    {/* this may get change because we are not getting  companyCandidateMapping here*/}
                                    <span
                                      className={
                                        rowData?.candidate?.companyApiToken === null
                                          ? rowData.candidate.user?.deletedAt
                                            ? styles.deletedUser
                                            : ''
                                          : rowData?.candidate?.companyApiToken?.deletedAt !== null
                                          ? styles.deletedUser
                                          : ''
                                      }
                                    >
                                      {rowData.candidateAddedBy === 5 ? (
                                        `API (${rowData?.candidate?.companyApiToken?.name})`
                                      ) : rowData.candidate.user ? (
                                        <span
                                          className={
                                            rowData?.candidate?.user?.deletedAt
                                              ? styles.deletedUser
                                              : ''
                                          }
                                        >
                                          {capitalizeName(rowData.candidate.user.name)}
                                        </span>
                                      ) : rowData.candidateAddedBy === 1 ? (
                                        'SpringVerify'
                                      ) : rowData.candidateAddedBy === 2 ? (
                                        'Company Admin'
                                      ) : (
                                        'SpringVerify'
                                      )}
                                    </span>
                                  </td>
                                ) : null}
                                <td
                                  style={{
                                    color:
                                      STATUSES.values[rowData.status === -1 ? 7 : rowData.status]
                                        .color
                                  }}
                                >
                                  {
                                    STATUSES.values[rowData.status === -1 ? 7 : rowData.status]
                                      .value
                                  }
                                </td>
                                <td className={styles.displayInline}>
                                  <VerificationIcons
                                    candidate={rowData.candidate}
                                    checkStatusesMapping={
                                      companyCustomizations.checkStatusesMapping
                                    }
                                    status={rowData.status}
                                    candidateMapping={rowData}
                                    checkType={
                                      rowData.candidateForm &&
                                      rowData.candidateForm.checkType &&
                                      rowData.candidateForm.checkType
                                        .split(',')
                                        .filter((item, ind, ar) => ar.indexOf(item) === ind)
                                        .sort()
                                    }
                                  />
                                </td>
                                <td>
                                  {isArray(rowData.candidate.candidateUserCategoryMapping) &&
                                  rowData.candidate.candidateUserCategoryMapping.length
                                    ? rowData.candidate.candidateUserCategoryMapping[0].userCategory
                                        .categoryName
                                    : rowData.candidate.candidateUserCategoryMapping &&
                                      rowData.candidate.candidateUserCategoryMapping.userCategory
                                    ? rowData.candidate.candidateUserCategoryMapping.userCategory
                                        .categoryName
                                    : '-'}
                                </td>
                                <td>
                                  {rowData.tags
                                    ? rowData.tags
                                        .split(',')
                                        .map((value, ind) => (
                                          <span className={styles.tagText}>{value}</span>
                                        ))
                                    : '-'}
                                </td>
                                <td className={styles.reportIcon}>
                                  {rowData.reportUrl !== null ? (
                                    <div className={styles.icon}>
                                      <a
                                        onClick={() => {
                                          window.open(
                                            `${AppConstants.baseURL}v1/candidate/report/html?candidate_id=${rowData.candidateId}`,
                                            '_blank'
                                          );
                                        }}
                                      >
                                        <Image name='reportIcon.svg' />
                                      </a>
                                    </div>
                                  ) : (
                                    <div>{'-'}</div>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={this.state.isAdmin ? 8 : 7}>
                        {loading ? (
                          <div className={styles.noDataWrapper}>
                            <Spinner
                              animation='border'
                              variant='primary'
                              width={100}
                              height={100}
                            />
                            <div className={styles.noDataContent}>{'Loading'}</div>
                          </div>
                        ) : (
                          <div className={styles.noDataWrapper}>
                            <Icon icon={'NoData'} color={'#666666'} width={100} height={100} />
                            <div className={styles.noDataContent}>
                              {'No results matched your search query'}
                            </div>
                          </div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
              {this.state.isData && !loading ? (
                <Form.Row className={styles.paginationWrapper}>
                  <Col className={styles.showPagination}>
                    Show{' '}
                    <select
                      className={styles.selectPagination}
                      value={pageSize}
                      onChange={(event) => {
                        const self = event.target.value;
                        this.setState(
                          {
                            pageNumber: 0,
                            pageSize: parseInt(self)
                          },
                          () => this.paginate(self, 0)
                        );
                      }}
                    >
                      {pageSizeOptions}
                    </select>{' '}
                    items of {count}
                  </Col>
                  <Col>
                    <Pagination
                      forcePage={pageNumber}
                      onPageChange={({ selected }) =>
                        this.setState({ pageNumber: selected }, () =>
                          this.paginate(pageSize, selected)
                        )
                      }
                      pageCount={count / pageSize}
                    />
                  </Col>
                  <Col></Col>
                </Form.Row>
              ) : (
                <div></div>
              )}
            </Card>
          </Card>
          {/* Commenting As per Improvement 20836 */}
          {/* {this?.props?.paymentDetails?.isReferralEnabled && (
            <BulkSuccessModal
              isOpen={this.state.showReferAndEarnModal}
              history={this?.props?.history}
              onClose={() => {
                this.setState(() => ({
                  showReferAndEarnModal: false
                }));
              }}
            />
          )} */}
        </div>
      </div>
    );
  }
}
