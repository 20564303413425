import React, { useState } from 'react';

import ordinal from 'ordinal';
import { Accordion, Card, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faBan } from '@fortawesome/free-solid-svg-icons';

import Image from '../../../../../../../core-components/Image';
import CustomCounter from '../../../../../../../core-components/CustomCounter';

import { PLAIN_CHECK_ICONS } from '../../../../../../../utils/commonConstant';
import { convertToIndianNumeration } from '../../../../../../../utils/utilities';
import styles from './Reference.module.scss';
import { maskPriceForAdmins } from '../../../../../frontend-common/CreditBuyPackagePage/UtilComponents/UtilitiesFunction';

function Reference(props) {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const { values, onValueChange, empCount } = props;

  const decoratedOnClick = (eventKey = 0) => {
    if (eventKey === activeAccordion) {
      setActiveAccordion(null);
    } else {
      setActiveAccordion(eventKey);
    }
  };

  const handleConfigChange = (value, index) => {
    let dummy = [...values.config];

    dummy[index] = value;
    onValueChange('config', dummy);
  };

  return (
    <div className={styles.addonsContainer}>
      <Accordion>
        <Card className={styles.addonsContainerCard}>
          <Accordion.Toggle
            as={Card.Header}
            eventKey={empCount ? '0' : '1'}
            className={styles.addonsContainerHeader}
            onClick={() => (empCount ? decoratedOnClick(0) : setActiveAccordion(null))}
          >
            <div>
              <span className={styles.iconContainer}>
                <FontAwesomeIcon icon={faPlusCircle} color='#389E0D' className={styles.plusIcon} />
                <FontAwesomeIcon
                  icon={
                    PLAIN_CHECK_ICONS['reference'] ? PLAIN_CHECK_ICONS['reference'].icon : faBan
                  }
                  color='rgba(39, 85, 254, 0.5)'
                  style={{ fontSize: '18px' }}
                />
              </span>
            </div>
            <div className={styles.headingRightContainer}>
              <div className={!empCount ? styles.heading : ''}>
                {props?.heading}
                {!empCount ? (
                  <p className={styles.headingDesc} data-testid='employment-check-description'>
                    {'Only available with employment check'}
                  </p>
                ) : null}
              </div>
              <div className={empCount ? styles.headingRight : styles.headingRightDisable}>
                <span className={styles.startFrom}>
                  <span className={styles.rupee}>&#8377;</span>
                  {maskPriceForAdmins(props?.price, props?.accessLevel, props?.paymentModelType)}
                  {' per check'}
                </span>
                {activeAccordion === 0 ? (
                  <Image name={'UpAngle.svg'} svg={'UpAngle.svg'} />
                ) : empCount ? (
                  <Image name={'DownAngle.svg'} svg={'DownAngle.svg'} />
                ) : (
                  <Image name={'DisableDownAngle.svg'} svg={'DisableDownAngle.svg'} />
                )}
              </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='0'>
            <Card.Body className={styles.cardBody}>
              <Row>
                {/* last emp will be first */}
                {values?.config?.map((item, index) => {
                  return (
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      key={index}
                      className={styles.refCounterContainer}
                    >
                      <span className={styles.refNumber}>
                        {index === 0 ? 'Last employment' : `${ordinal(index + 1)} last employment`}
                      </span>
                      <CustomCounter
                        limit={50}
                        totalCount={0}
                        value={item}
                        index={index}
                        valueChange={(value, index) => {
                          handleConfigChange(value, index);
                        }}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
}

export default Reference;
