export const integrationsCardData = {
  RAZORPAYX: {
    id: 'razorpay',
    icon: 'razorPayX.png',
    title: 'RazorpayX Payroll',
    type: 'ATS',
    subtitle: 'Payroll Software',
    details: `Manage payroll, attendance, reimbursements, and compliances, all on a single dashboard. Automate payroll & compliances like PF, TDS, ESI & PT for your business in 10 minutes.`
  },
  GREENHOUSE: {
    id: 'greenhouse',
    icon: 'greenhouse.png',
    title: 'Greenhouse',
    type: 'ATS',
    subtitle: 'Applicant Tracking System',
    details: `An easy, automated way to engage with talent sourcing automation helps hiring teams find, reach and engage top talent quickly and effectively.`
  },
  LEVER: {
    id: 'lever',
    icon: 'lever.png',
    title: 'Lever',
    type: 'ATS',
    subtitle: 'Applicant Tracking System',
    details: `Lever is a leading talent acquisition suite that makes it easy for talent teams to reach their hiring goals and to connect companies with top talent.`
  },
  ZOHORECRUIT: {
    id: 'zoho-recruit',
    icon: 'zoho_recruit.png',
    title: 'Zoho Recruit',
    type: 'ATS',
    subtitle: 'Recruitment Software',
    details:
      'Zoho Recruit is a comprehensive recruitment platform that helps streamline your hiring process. It offers features like candidate sourcing, resume management, and interview scheduling.'
  },
  RECRUITEE: {
    id: 'recruitee',
    icon: 'recruitee.png',
    title: 'Recruitee',
    type: 'ATS',
    subtitle: 'Applicant Tracking System',
    details: `Recruitee is built for bringing teams together. More than 5,000 companies use our collaborative hiring software to share the work, speed up daily processes, and grow their teams together.`
  },
  BAMBOOHR: {
    id: 'BambooHR',
    icon: 'bamboohr.png',
    title: 'BambooHR',
    type: 'HRMS',
    subtitle: 'Modern HR Management',
    details:
      'BambooHR saves you time and effort so you can focus on what matters most—growing your people, improving the employee experience, and taking your business forward.',
    comingSoon: true
  },
  DARWINBOX: {
    id: 'Darwinbox',
    icon: 'darwinbox.png',
    title: 'Darwinbox',
    type: 'HRMS',
    subtitle: 'Empowering HR Transformation',
    details: `Make your enterprise change-ready for now and the next chapter with Darwinbox's end-to-end, agile HCM platform for the evolving world of work.`,
    comingSoon: true
  },
  GREYTHR: {
    id: 'greytHR',
    icon: 'GreytHR.png',
    title: 'greytHR',
    type: 'ATS',
    subtitle: 'HR Simplified Solutions',
    details:
      'The greytHR Platform automates HR processes & empowers employee self-service. Get all-around productivity and engaged employees—the crucial ingredients for growth.',
    comingSoon: false
  },
  GOOGLEWORKSPACE: {
    id: 'GoogleWorkspace',
    icon: 'goggleworkspace.png',
    title: 'Google Workspace',
    type: 'ATS',
    subtitle: 'Collaborative Productivity Suite',
    details: `Google Workspace is a collection of cloud computing, productivity and collaboration tools, software and products developed and marketed by Google.`,
    comingSoon: true
  },
  NAMELY: {
    id: 'Namely',
    icon: 'namely.png',
    title: 'Namely',
    type: 'HRMS',
    subtitle: 'Modern HR Platform',
    details: `Namely is the only HR, payroll, benefits, and talent management platform your employees will love.`,
    comingSoon: true
  },
  ORACLECLOUDHCM: {
    id: 'OracleCloudHCM',
    icon: 'oracleCloud.png',
    title: 'Oracle Cloud HCM',
    type: 'HRMS',
    subtitle: 'Integrated HR Cloud Solutions',
    details: `Oracle Cloud Human Capital Management is a cloud-based HCM software application suite for global HR, talent, and workforce management.`,
    comingSoon: true
  },
  SAPSUCCESSFACTORS: {
    id: 'SapSuccessFactors',
    icon: 'sapsuccessfactors.png',
    title: 'SAP SuccessFactors',
    type: 'ATS',
    subtitle: 'Integrated HR Management',
    details: `Powerful cloud HR software that empowers individuals to reach their full potential at work while strengthening the connection of HR across the business.`,
    comingSoon: true
  },
  ZENEFITS: {
    id: 'Zenefits',
    icon: 'zenefits.png',
    title: 'Zenefits',
    type: 'ATS',
    subtitle: 'Integrated HR Solutions',
    details: `Zenefits is an award-winning People Ops Platform that makes it easy to manage your employee documents, HR, benefits, payroll, time and attendance.`,
    comingSoon: true
  },
  WORKDAY: {
    id: 'Workday',
    icon: 'workday.png',
    title: 'Workday',
    type: 'ATS',
    subtitle: 'Unified HR Management',
    details: `Workday Enterprise Management Cloud currently includes solutions for finance, HR, planning, and spend management.`,
    comingSoon: true
  },
  HIBOB: {
    id: 'HiBob',
    icon: 'hibob.png',
    title: 'HiBob',
    type: 'ATS',
    subtitle: 'Modern HR Management',
    details: `Bob's HR platform helps modern, dynamic companies drive productivity, engagement, and retention.`,
    comingSoon: true
  },
  WORKABLE: {
    id: 'workable',
    icon: 'workable.png',
    title: 'Workable',
    type: 'ATS',
    subtitle: 'Applicant Tracking System',
    details:
      'Workable Software is a software-as-a-service that provides applicant tracking system and recruitment software to support and manage the hiring process.'
  },
  HUMAANS: {
    id: 'humaans',
    icon: 'humaans.png',
    title: 'Humaans',
    type: 'HRMS',
    subtitle: 'HRIS Employee Management Software',
    details: `Humaans HRMS is a cutting-edge Human Resource Management System designed to empower organizations with a seamless, intuitive, and efficient HR solution.`
  },
  HRCLOUD: {
    id: 'hrcloud',
    icon: 'hrcloud.png',
    title: 'HR Cloud',
    type: 'HRMS',
    subtitle: 'HR software & HRMS solutions',
    details: `HR Cloud is a leading developer of HR software & HRMS solutions for small and medium size businesses that have high turnover.`
  },
  REPUTE: {
    id: 'repute',
    icon: 'repute.png',
    title: 'Repute',
    type: 'HRMS',
    subtitle: 'HR management system',
    details: `Repute is a blockchain-based network that provides a marketplace of partner apps for HR productivity and employee wellness.`
  },
  FOUNTAIN: {
    id: 'fountain',
    icon: 'fountain.png',
    title: 'Fountain',
    type: 'ATS',
    subtitle: 'Hiring Software',
    details: `Fountain is a hiring platform trusted by the world's leading brands. Source, screen, and hire better candidates faster at scale.`
  },
  JOBSCORE: {
    id: 'jobscore',
    icon: 'jobscore.png',
    title: 'JobScore',
    type: 'ATS',
    subtitle: 'Applicant Tracking System',
    details: `JobScore is a software solution that helps companies with recruiting.`
  },
  TEAMTAILOR: {
    id: 'teamtailor',
    icon: 'teamtailor.png',
    title: 'Teamtailor',
    type: 'ATS',
    subtitle: 'Recruitment Software',
    details: `Teamtailor is a recruitment software designed to help companies attract and hire candidates.`
  },
  ZOHOPEOPLE: {
    id: 'zohopeople',
    icon: 'zoho.png',
    title: 'Zoho People',
    type: 'HRMS',
    comingSoon: false,
    subtitle: 'HRMS Software',
    details: `Zoho People is an online HRIS which lets you maintain your employee database, manage your workforce and ensures consistency in all your HR Processes.`
  },
  SPRINTO: {
    id: 'sprinto',
    icon: 'sprinto.png',
    title: 'Sprinto',
    type: 'ATS',
    subtitle: 'Security Compliance Automation Platform',
    details:
      'Sprinto is a security compliance automation platform that helps businesses automate their risk assessment and monitoring. It offers features like real-time risk monitoring, collaborative workflows, and deep integrations.'
  },
  ALEXISHR: {
    id: 'alexishr',
    icon: 'alexishr.png',
    title: 'Alexis HR',
    type: 'HRMS',
    subtitle: 'HRMS Software',
    details:
      'AlexisHR provides an all-in-one HR platform that saves businesses time, allows them to make better decisions, and ultimately creates a better workplace.'
  },
  BREATHE: {
    id: 'breathehr',
    icon: 'breathehr.png',
    title: 'Breathe HR',
    type: 'ATS',
    subtitle: 'HR Software',
    details: `Breathe's award-winning HR software helps over 13,000 SMEs to streamline their everyday people-tasks and covers everything from holiday, sickness & document `
  },
  FACTORIAL: {
    id: 'factorial',
    icon: 'factorial1.png',
    title: 'Factorial',
    type: 'HRMS',
    subtitle: 'HRMS Software',
    details:
      'Factorial is a human resources software developed to digitize companies by integrating human management processes such as time control, clocking in, etc.'
  },
  HAILEYHR: {
    id: 'haileyhr',
    icon: 'haileyhr.png',
    title: 'Hailey HR',
    type: 'HRMS',
    subtitle: 'HRMS Software',
    details:
      'HaileyHR is an employee management software that helps streamline all HR tasks in one place, all within one user-friendly and flexible system.'
  },
  PERSONIO: {
    id: 'personio',
    icon: 'personio.png',
    title: 'Personio',
    type: 'HRMS',
    subtitle: 'HR Software',
    details:
      'Personio is the all-in-one HR software that upgrades your people operations for now and the future.'
  },
  JUMPCLOUD: {
    id: 'jumpcloud',
    icon: 'jumpcloud.png',
    title: 'JumpCloud',
    type: 'HRMS',
    subtitle: 'HRMS Software',
    details:
      'JumpCloud is the central hub to automate and simplify the entire process of provisioning access for users to any IT environment.'
  },
  HEAVENHR: {
    id: 'heavenhr',
    icon: 'heavenhr.png',
    title: 'HeavenHR',
    type: 'HRMS',
    subtitle: 'HRMS Software',
    details: 'Heaven is a HR management platform for small and medium enterprises.'
  },
  PEOPLEHR: {
    id: 'peoplehr',
    icon: 'peoplehr.png',
    title: 'PeopleHR',
    type: 'HRMS',
    subtitle: 'HRMS Software',
    details:
      'PeopleHR India is a Cloud-Based HRMS with Mobile apps, Automated Payroll & Statutory Compliance, Flexible Authorization Workflow, Employee Onboarding.'
  },
  GUSTO: {
    id: 'gusto',
    icon: 'Gusto.png',
    title: 'Gusto',
    type: 'HRMS',
    subtitle: 'HRMS Software',
    details:
      'Gusto, a robust, cloud-based Human Resource Information System (HRIS), is engineered to streamline and automate HR operations.'
  },
  CLAYHR: {
    id: 'clayhr',
    icon: 'clayhr.png',
    title: 'ClayHR',
    type: 'HRMS',
    subtitle: 'HRMS Software',
    details:
      'ClayHR is a hyper-configurable HRMS with open API and robust data integrations, streamlining HR tasks through extendable, customizable features and automated processes.'
  },
  KEKA: {
    id: 'keka',
    icon: 'keka.png',
    title: 'Keka',
    type: 'HRMS',
    subtitle: 'HRMS Software',
    details:
      'Keka is a cloud-based human resource (HR) solution, which helps businesses manage staff profiles, track attendance, process recruitments and analyze performance'
  },
  JAZZHR: {
    id: 'JazzHR',
    icon: 'jazzHr.png',
    title: 'JazzHR',
    type: 'ATS',
    subtitle: 'Applicant Tracking System',
    details: `JazzHR is a powerful, user-friendly, applicant tracking system that is purpose-built to help businesses exceed their recruiting goals.`
  },
  POCKETHRMS: {
    id: 'pocketHRMS',
    icon: 'pocketHRMS.png',
    title: 'Pocket HRMS',
    type: 'HRMS',
    comingSoon: true,
    subtitle: 'HRMS Software',
    details: `Pocket HRMS is the Ultimate HR Software for the Modern HR with Innovative Features like AI-enabled HR Chatbot ‘smHRty’, Geo-tagging & Biometric Attendance, Machine Learning-based Payroll, and more.`,
    comingSoon: true
  },
  BAMBOOHRATS: {
    id: 'BambooHRATS',
    icon: 'bamboohr.png',
    title: 'BambooHR ATS',
    type: 'ATS',
    subtitle: 'Applicant Tracking System',
    details:
      'BambooHR saves you time and effort so you can focus on what matters most—growing your people, improving the employee experience, and taking your business forward.'
  },
  SCRUT: {
    id: 'scrut',
    icon: 'scrut.jpg',
    title: 'Scrut Automation',
    type: 'HRIS',
    comingSoon: true,
    subtitle: 'Compliance Automation Software',
    details:
      'Scrut Automation offers a platform for continuous risk assessment and monitoring. It helps businesses build unique risk-first infosec programs and manage multiple compliance audits.',
    comingSoon: true
  },
  RIPPLING: {
    id: 'rippling',
    icon: 'rippling.png',
    title: 'Rippling',
    type: 'ATS',
    comingSoon: true,
    subtitle: 'Workforce Management Platform',
    details:
      'Rippling is a platform that simplifies managing your employees payroll, benefits, expenses, and more. It is designed to help you easily scale from 2 to 2,000+ employees.',
    comingSoon: true
  },
  INKLE: {
    id: 'inkle',
    icon: 'inkle.jpg',
    title: 'Inkle',
    type: 'HRIS',
    comingSoon: true,
    subtitle: 'CPA and Compliance Software',
    details:
      'Inkle is a chat-based CPA platform used by hundreds of US cross-border companies for books, tax, and compliance. It offers an all-in-one SaaS stack for founders to access affordable, quality, US-licensed Certified Public Accountant services',
    comingSoon: true
  },
  HRONE: {
    id: 'hrone',
    icon: 'hrone1.png',
    title: 'HROne',
    type: 'HRMS',
    comingSoon: false,
    subtitle: 'HRMS Software',
    details:
      'HROne is the simplest HR automation platform that helps companies eliminate repetitive HR tasks by automating 127+ processes. They are trusted by 1500+ organizations for simplifying- attendance, payroll, expense, employee lifecycle, recruitment, employee engagement, and more. HROne equates its success with the success of its people, clients, partners, and community. To learn more, visit hrone.cloud'
  },
  ASHBY: {
    id: 'ashby',
    icon: 'ashby.png',
    title: 'Ashby',
    type: 'ATS',
    comingSoon: false,
    subtitle: 'ATS Software',
    details:
      'Consolidated all-in-one solution Ashby combines your ATS, CRM, scheduling, and analytics into a single scalable solution with best-in-breed features.'
  },
  KEKAATS: {
    id: 'keka-ats',
    icon: 'keka.png',
    title: 'Keka ATS',
    type: 'ATS',
    subtitle: 'ATS Software',
    details:
      'Keka is a cloud-based human resource (HR) solution, which helps businesses manage staff profiles, track attendance, process recruitments and analyze performance'
  },
  ZIMYO: {
    id: 'zimyo',
    icon: 'zimyo.png',
    title: 'Zimyo',
    type: 'HRMS',
    subtitle: 'HRMS | HRMS Software',
    details:
      'Zimyo is a modern HRMS software that helps you automate HR processes, manage employee data, and improve employee engagement. It is designed to help businesses streamline their HR operations and improve their overall productivity.'
  }
};
