import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Dashboard from '../Dashboard';
import DropdownMenu from './DropdownMenu';
import Image from '../../../core-components/Image';
import Button from '../../../core-components/Button';
import { Tooltip, OverlayTrigger, Alert } from 'react-bootstrap';

import styles from './Header.module.scss';
import localStorage from '../../../api/localStorage';
import { HEADER_TAB_VALUES } from './utils';
import { ACCESS_LEVEL, modelType } from '../../../utils/commonConstant';
import {
  AddCreditsWarning,
  AdminBounceEmailBannerVisible,
  CreditLimitExceeded,
  NoPackageBanner,
  WalletPackageBalanceBanner
} from './TopBannerx';
import LinesEllipsis from 'react-lines-ellipsis';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { capitalizeName } from '../frontend-common/utils/Utilities';

export default withRouter((props) => {
  const isToken = localStorage.getToken();
  const isAdmin = localStorage.getAccessLevel() === 'admin';

  const [numberOfBanner, setNumberOfBanner] = useState(0);

  const [emailBounceCount, setEmailBounceCount] = useState(0);
  const [selectedHeaderTab, setSelectedHeaderTab] = useState(HEADER_TAB_VALUES.DASHBOARD);

  const [companyLineCount, setCompanyLineCount] = useState(1);
  const [usernameLineCount, setUsernameLineCount] = useState(1);
  props = { ...props, ...props.historyProps };
  const companyNameref = useRef(null);
  const userNameref = useRef(null);
  const packageType = props.packageType;
  useEffect(() => {
    if (props.profile && isToken) {
      props?.getPaymentDetails();
      props?.getWalletDetail();
    }
    if (companyNameref.current && userNameref.current) {
      const elementCompany = companyNameref.current;
      const computedStyleCompany = window.getComputedStyle(elementCompany);
      const lineHeightCompany = parseInt(computedStyleCompany.getPropertyValue('line-height'));
      const heightCompany = elementCompany.clientHeight;
      const lineCountCompany = Math.round(heightCompany / lineHeightCompany);
      if (lineCountCompany >= 2) {
        setCompanyLineCount(2);
      }
      const elementUser = userNameref.current;
      const computedStyleUser = window.getComputedStyle(elementUser);
      const lineHeightUser = parseInt(computedStyleUser.getPropertyValue('line-height'));
      const heightUser = elementUser.clientHeight;
      const lineCountUser = Math.round(heightUser / lineHeightUser);
      if (lineCountUser >= 2) {
        setUsernameLineCount(2);
      }
    }
  }, []);

  useEffect(() => {
    const { location } = props;
    const path = location.pathname;
    const search = location.search;

    const zoho = window?.$zoho;
    if (zoho) {
      zoho.salesiq?.visitor?.name(props?.profile?.name);
      zoho.salesiq?.visitor?.email(props?.profile?.email);
    }

    // Use the URLSearchParams API to parse the search value
    const searchParams = new URLSearchParams(search);

    // Check if the searchParams object has a key named _showAllCandidates
    const showAllCandidates = searchParams.has('_showAllCandidates');

    setSelectedHeaderTab('');
    if (showAllCandidates || !search) {
      switch (path) {
        case '/dashboard':
          setSelectedHeaderTab(HEADER_TAB_VALUES.DASHBOARD);
          break;
        case '/candidates':
          setSelectedHeaderTab(HEADER_TAB_VALUES.CANDIDATES);
          break;
        case '/packages':
          setSelectedHeaderTab(HEADER_TAB_VALUES.PACKAGES);
          break;
        case '/approval':
          setSelectedHeaderTab(HEADER_TAB_VALUES.APPROVE_CHARGES);
          break;
        case '/buy':
        case '/balance':
          setSelectedHeaderTab(HEADER_TAB_VALUES.BUY);
          break;
        case '/alumni-verification':
          setSelectedHeaderTab(HEADER_TAB_VALUES.ALUMNI);
          break;
      }
    }
  }, [props?.location]);

  useEffect(() => {
    // Postpaid Conditions
    if (packageType === 'POSTPAID_CANDIDATE_COMPLETION') {
      const functions = [
        isAdminBounceEmailBannerVisible(),
        isUnpaidInvoiceBannerVisible(),
        isCreditExceededBannerVisible(),
        isNoPackageBalanceVisible()
      ];

      const trueCount = functions.filter((func) => func === true).length;
      setNumberOfBanner(trueCount);
    }

    // Prepaid Conditions
    if (packageType === modelType['prepaid']) {
      const functions = [
        isWalletPackageBalanceBannerVisible(),
        isNoPackageBalanceVisible(),
        isAdminBounceEmailBannerVisible()
      ];

      const trueCount = functions.filter((func) => func === true).length;
      setNumberOfBanner(trueCount);
    }

    if (props?.paymentDetails && props?.paymentDetails?.emailBounceData) {
      setEmailBounceCount(props?.paymentDetails?.emailBounceData?.count);
    }
  }, [props]);

  const getApproveChargesButtonStyle = (hasPendingCharges, isTabSelected) => {
    const {
      approveChargesWarning,
      buttonWithBorder,
      approveChargesWarningHover,
      approveChargesNormal,
      hover
    } = styles;

    // If there are pending charges
    if (hasPendingCharges) {
      // If the tab is selected, return the concatenation of the approveChargesWarning and buttonWithBorder class names
      // Otherwise, return the concatenation of the approveChargesWarning and approveChargesWarningHover class names
      return isTabSelected
        ? `${approveChargesWarning} ${buttonWithBorder}`
        : `${approveChargesWarning} ${approveChargesWarningHover}`;
    } else {
      // If the tab is selected, return the concatenation of the approveChargesNormal and buttonWithBorder class names
      // Otherwise, return the concatenation of the approveChargesNormal and hover class names
      return isTabSelected
        ? `${approveChargesNormal} ${buttonWithBorder}`
        : `${approveChargesNormal} ${hover}`;
    }
  };

  const getCandidatesButtonStyle = (isTabSelected) => {
    const { buttonWithBorder, candidateButtonHover, hover } = styles;

    // If emailBounceCount is greater than 0 and the tab is selected, return the buttonWithBorder style
    if (emailBounceCount > 0 && isTabSelected) {
      return buttonWithBorder;
    }
    // If emailBounceCount is greater than 0 and the tab is not selected, return the candidateButtonHover style
    else if (emailBounceCount > 0 && !isTabSelected) {
      return candidateButtonHover;
    }
    // If emailBounceCount is less than or equal to 0 and the tab is selected, return the buttonWithBorder style
    else if (emailBounceCount <= 0 && isTabSelected) {
      return buttonWithBorder;
    }
    // If none of the above conditions are met, return the hover style
    else {
      return hover;
    }
  };

  const ApproveChargesButton = () => {
    const pendingChargesCount = props?.paymentDetails?.totalPendingChargesCount;

    return (
      <Link
        className={styles.heightAdjustment}
        style={{ color: '#FFFFFF', textDecoration: 'none' }}
        to={{ pathname: '/approval' }}
      >
        <Button
          className={getApproveChargesButtonStyle(
            pendingChargesCount,
            selectedHeaderTab === HEADER_TAB_VALUES.APPROVE_CHARGES
          )}
        >
          <div>Approve Charges</div>
          {pendingChargesCount ? (
            <div
              style={{
                width: 'auto',
                paddingInline: '5px',
                background: 'white',
                color: 'black',
                textAlign: 'center',
                borderRadius: '5px',
                marginLeft: '5px'
              }}
            >
              {pendingChargesCount}
            </div>
          ) : null}
        </Button>
      </Link>
    );
  };

  const isWalletPackageBalanceBannerVisible = () => {
    return (
      isToken &&
      isAdmin &&
      packageType === modelType['prepaid'] &&
      !props?.paymentDetails?.loading &&
      !props?.walletDetail?.loading &&
      location?.pathname !== '/balance' &&
      location?.pathname !== '/buy'
    );
  };

  const isNoPackageBalanceVisible = () => {
    return (
      props?.paymentDetails &&
      !props.paymentDetails.loading &&
      location?.pathname !== '/balance' &&
      location?.pathname !== '/buy' &&
      props.paymentDetails?.packageDetails?.count === 0
    );
  };
  const isAdminBounceEmailBannerVisible = () => {
    return (
      props?.profile?.access_level === ACCESS_LEVEL.COMPANY_ADMIN &&
      props?.paymentDetails &&
      props?.paymentDetails?.userEmailBounceData &&
      props?.paymentDetails?.userEmailBounceData?.count > 0
    );
  };

  const isUnpaidInvoiceBannerVisible = () => {
    return (
      packageType === 'POSTPAID_CANDIDATE_COMPLETION' &&
      props?.paymentDetails &&
      props?.paymentDetails?.anyUnpaidInvoices
    );
  };
  const isCreditExceededBannerVisible = () => {
    return (
      packageType === 'POSTPAID_CANDIDATE_COMPLETION' &&
      props?.paymentDetails &&
      props?.paymentDetails?.isCreditLimitExceeded
    );
  };
  const getBannerLengthClassName = () => {
    switch (numberOfBanner) {
      case 4:
        return styles.fourBanners;
      case 3:
        return styles.threeBanners;
      case 2:
        return styles.twoBanners;
      default:
        return '';
    }
  };
  return (
    <>
      <Helmet>
        <title>{props?.pageTitle || 'Dashboard | SpringVerify'}</title>
        <meta name='description' content='SpringVerify' />
        <meta
          name='twitter:title'
          content='SpringVerify | Instant Background Verification Tool in India'
        />
        <meta
          property='og:title'
          content='SpringVerify | Instant Background Verification Tool in India'
        />
      </Helmet>
      <div className={`${styles.headerParent} ${getBannerLengthClassName()}`}>
        <div className={styles.headerMain}>
          <div className={styles.header1}>
            <div className={styles.headerLogo}>
              <Image name='sv_logo_desktop.svg' />
            </div>
            {isToken && props.profile ? (
              <div className={styles.headerButtons}>
                <Link className={styles.heightAdjustment} to={{ pathname: '/dashboard' }}>
                  <Button
                    className={
                      selectedHeaderTab === HEADER_TAB_VALUES.DASHBOARD
                        ? styles.buttonWithBorder
                        : styles.hover
                    }
                  >
                    Dashboard
                  </Button>
                </Link>
                <div
                  className={
                    emailBounceCount > 0 ? styles.headerDebounceButton : styles.headerButtons
                  }
                >
                  <Link className={styles.heightAdjustment} to={{ pathname: '/candidates' }}>
                    <Button
                      className={getCandidatesButtonStyle(
                        selectedHeaderTab === HEADER_TAB_VALUES.CANDIDATES
                      )}
                    >
                      <div className={classNames(styles.heightAdjustment, styles.headerBtn)}>
                        Candidates
                        {emailBounceCount > 0 && (
                          <span>
                            <OverlayTrigger
                              placement='bottom'
                              overlay={
                                <Tooltip>
                                  <p>
                                    {'[PRIORITY] Emails to some of your candidates have bounced'}
                                  </p>
                                </Tooltip>
                              }
                            >
                              <span style={{ paddingLeft: '5px' }}>
                                <Image
                                  name={'whiteWarning.svg'}
                                  className={styles.image}
                                  style={{ marginTop: props.margin }}
                                />
                              </span>
                            </OverlayTrigger>
                          </span>
                        )}
                      </div>
                    </Button>
                  </Link>
                </div>
                {/* <Button onClick={() => props.historyProps.history.push('/insufficiencies')}>
                {'Insufficiencies'}
              </Button> */}
                <Button
                  className={
                    selectedHeaderTab === HEADER_TAB_VALUES.ALUMNI
                      ? styles.buttonWithBorder
                      : styles.hover
                  }
                  onClick={() => props?.history?.push('/alumni-verification')}
                >
                  Alumni Verification
                </Button>{' '}
                {isAdmin && packageType === modelType['postpaid'] ? (
                  <Link to={{ pathname: '/packages' }}>
                    <Button
                      className={
                        selectedHeaderTab === HEADER_TAB_VALUES.PACKAGES
                          ? styles.buttonWithBorder
                          : styles.hover
                      }
                    >
                      {'Packages'}
                    </Button>
                  </Link>
                ) : null}
                {isAdmin ? ApproveChargesButton() : null}
                {isAdmin && packageType === modelType['prepaid'] ? (
                  <Link className={styles.heightAdjustment} to={{ pathname: '/balance' }}>
                    <Button
                      className={
                        selectedHeaderTab === HEADER_TAB_VALUES.BUY
                          ? styles.buttonWithBorder
                          : styles.hover
                      }
                    >
                      {'Balances / Buy'}
                    </Button>
                  </Link>
                ) : null}
              </div>
            ) : null}
          </div>
          {isToken ? (
            <div className={styles.header2}>
              <div className={styles.profileInfo}>
                <p className={styles.companyName} ref={companyNameref} data-testid='companyName'>
                  {companyLineCount === 2 ? (
                    <OverlayTrigger
                      key={`company_name`}
                      placement='bottom'
                      overlay={
                        <Tooltip id={`company_name`}>
                          <p>{props?.profile?.company_user_mapping?.company?.name}</p>
                        </Tooltip>
                      }
                    >
                      <LinesEllipsis
                        text={props?.profile?.company_user_mapping?.company?.name}
                        maxLine='2'
                        ellipsis='...'
                        trimRight
                      />
                    </OverlayTrigger>
                  ) : (
                    props?.profile?.company_user_mapping?.company?.name
                  )}
                </p>
                <p className={styles.loginName} data-testid='profileName' ref={userNameref}>
                  {usernameLineCount === 2 ? (
                    <OverlayTrigger
                      key={`user_name`}
                      placement='bottom'
                      overlay={
                        <Tooltip id={`user_name`}>
                          <p>{capitalizeName(props?.profile?.name)}</p>
                        </Tooltip>
                      }
                    >
                      <LinesEllipsis
                        text={capitalizeName(props?.profile?.name)}
                        maxLine='2'
                        ellipsis='...'
                        trimRight
                      />
                    </OverlayTrigger>
                  ) : (
                    capitalizeName(props?.profile?.name)
                  )}
                </p>
              </div>
              <div className={styles.profileBtn}>
                <DropdownMenu
                  title={capitalizeName(props?.profile?.name)}
                  isAdmin={isAdmin}
                  pointOfContact={props?.paymentDetails?.pointOfContacts}
                  {...props}
                />
              </div>
            </div>
          ) : null}
        </div>

        {isAdminBounceEmailBannerVisible() && <AdminBounceEmailBannerVisible {...props} />}
        {isNoPackageBalanceVisible() && <NoPackageBanner {...props} />}
        {isWalletPackageBalanceBannerVisible() && <WalletPackageBalanceBanner {...props} />}

        {isUnpaidInvoiceBannerVisible() && <AddCreditsWarning />}

        {isCreditExceededBannerVisible() && <CreditLimitExceeded />}
        {props.historyProps?.history.location.pathname === '/dashboard' ? (
          <div className={styles.headerDashboard}>
            <Dashboard {...props.historyProps} />
          </div>
        ) : null}
      </div>
    </>
  );
});
