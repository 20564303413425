import React, { useEffect, useState, useRef } from 'react';

import styles from '../../Integrations.module.scss';
import { Container, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { DropdownList } from 'react-widgets';
import Image from '../../../core-components/Image';

import TopTextArea from '../../Utils/TopTextArea';
import IntegrationLayout from '../../Utils/Layout';
import Button from '../../../core-components/Button';
import WarningModal from '../../WarningModal';
import ConfirmationModal from '../../../core-components/ConfirmationModal';
import AppConstants from '../../../../../../core-components/AppConstants';
import authenticate from '@truto/truto-link-sdk';
import _, { isEmpty } from 'lodash';

import { toast } from 'react-toastify';
import { errToastMessage } from '../../../utils/Utilities';
import { ashbyDetails } from '../../Utils/constants';
import {
  deleteIntegrations,
  postIntegrations,
  getTRUTOLinkToken,
  getTRUTOATSApplicationStages,
  getTRUTOJobs,
  getViewBuyPackages,
  getIntegrationFieldSubtypeMapping
} from '../../../../../../api/company';

export default function Ashby(props) {
  const [integrationData, setIntegrationData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [triggerUseEffect, setTriggerUseEffect] = useState(false);
  const [linkToken, setLinkToken] = useState('');

  const { setFieldValue, values, handleSubmit, isSubmitting } = props;

  const [isPackageMapped, setIsPackageMapped] = useState(false);
  const [identifierDataLoading, setIdentifierDataLoading] = useState(true);
  const [identifierValues, setIdentifierValues] = useState([]);
  const [packages, setPackages] = useState([]);
  const [reputeSubtypeMapping, setReputeSubtypeMapping] = useState([]);
  const [selectedIdentifierValues, setSelectedIdentifierValues] = useState({});
  const [dropdownIdentifierValues, setDropdownIdentifierValues] = useState([]);
  const [applicationStages, setApplicationStages] = useState([]);
  const [applicationStagesLoading, setApplicationStagesLoading] = useState(false);

  const ashbyIdentifierArray = ['Jobs'];

  const backStepHandler = () => {
    props.history.push('/integrations');
  };

  useEffect(() => {
    const integrationData = props?.integrationData;
    setIntegrationData(integrationData);

    getViewBuyPackages({ limit: 50, offset: 0 })
      .then((result) => {
        setPackages(result?.data?.data?.rows);
      })
      .catch((error) => {
        errToastMessage(error);
      });

    if (integrationData.ashby?.integrationId) {
      setIsPackageMapped(false);
      let subtypeMapping = {};

      // Fetching Integration field subtype mapping
      getIntegrationFieldSubtypeMapping({
        companyId: props.values.companyId,
        integrationName: 'ASHBY'
      })
        .then((res) => {
          if (res.data.length > 0) {
            setIsPackageMapped(true);
          }
          res.data.map((mapping) => {
            if (subtypeMapping?.hasOwnProperty(mapping.subtype_id_fk)) {
              subtypeMapping[mapping.subtype_id_fk] = {
                packageId: mapping.package_id_fk,
                tags: [
                  ...subtypeMapping[mapping.subtype_id_fk].tags,
                  ...mapping.integration_field_value.split(';')
                ]
              };
            } else {
              subtypeMapping[mapping.subtype_id_fk] = {
                packageId: mapping.package_id_fk,
                tags: mapping.integration_field_value.split(';')
              };
            }
          });
          setReputeSubtypeMapping(subtypeMapping);
          setSelectedIdentifierValues(subtypeMapping);
        })
        .catch((err) => console.error(err));

      // Fetching Trigger stage from Integration
      setApplicationStagesLoading(true);
      getTRUTOATSApplicationStages({
        integration_id: integrationData.ashby?.integrationId,
        source: 'ASHBY'
      })
        .then((response) => {
          const data = response?.data?.result;
          setApplicationStages(data);
          const triggerStage = [];
          if (data.length > 0) {
            data.map((stage) => {
              triggerStage.push(stage.name);
            });
            setApplicationStages(triggerStage);
          }
          setApplicationStagesLoading(false);
        })
        .catch((error) => {
          errToastMessage(error);
        });

      // Mapping Identifier values
      mapAPICallToIdentifier();
    }
  }, [props?.integrationData]);

  useEffect(() => {
    props.setIntegrationAPI();
    props.getViewBuyPackages({ limit: 50, offset: 0 });
    getTRUTOLinkToken()
      .then((response) => {
        setLinkToken(response.data.link_token);
      })
      .catch((error) => {
        errToastMessage(error);
      });
  }, []);

  useEffect(() => {
    const selectedTagsObj = { ...selectedIdentifierValues };
    const allTagsArr = Object.values(selectedTagsObj).map((obj) => !isEmpty(obj) && obj.tags);
    setDropdownIdentifierValues(_.difference(identifierValues, _.union(...allTagsArr)));
  }, [selectedIdentifierValues, identifierValues]);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setFieldValue('tags', reputeSubtypeMapping);
  }, [reputeSubtypeMapping]);

  const addIntegrationAPI = ({ integrationId, integration }) => {
    const payload = {
      source: integration.toUpperCase(),
      details: { integration_id: integrationId }
    };
    postIntegrations(payload)
      .then((resp) => {
        toast.success('Ashby Integration added successfully.');
        props.setIntegrationAPI();
      })
      .catch((error) => {
        errToastMessage(error);
      });
  };

  const checkIfTagsNotAvailable = () => {
    const selectedIdentifierValuesObj = { ...selectedIdentifierValues };
    const allTagsArr = Object.values(selectedIdentifierValuesObj).map(
      (obj) => !isEmpty(obj) && obj?.tags
    );
    return _.difference(_.union(...allTagsArr), identifierValues).length > 0;
  };

  const removeIntegrationsApi = () => {
    deleteIntegrations('ASHBY')
      .then((resp) => {
        props.setIntegrationAPI();
        toast.success('Removed connection successfully.');
        setTriggerUseEffect(!triggerUseEffect);
        setIsOpen(false);
      })
      .catch((error) => {
        errToastMessage(error);
      });
  };

  const onRemoveConnectionHandler = () => {
    removeIntegrationsApi();
  };

  const connectTruto = () => {
    const options = {
      integration: 'ashby'
    };

    authenticate(linkToken, options)
      .then((response) => {
        console.log(response);
        // { result: 'success', integration: 'copper' }
        addIntegrationAPI({
          integration: response.integration,
          integrationId: response.integrated_account_id
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const identifierSeparator = ({ innerProps }) => {
    return <span style={{ display: 'none' }} {...innerProps} />;
  };

  const setTagsValue = (packageId, subtypeId, value) => {
    let allSubtypesWithTags = { ...reputeSubtypeMapping, ...selectedIdentifierValues };

    allSubtypesWithTags[subtypeId] =
      value && value.length > 0 ? { packageId: packageId, tags: [...value] } : {};

    setSelectedIdentifierValues(allSubtypesWithTags);
    setFieldValue('tags', allSubtypesWithTags);
  };

  const mapAPICallToIdentifier = async () => {
    setIdentifierDataLoading(true);
    const integration_id = props?.integrationData?.ashby?.integrationId;
    const identifierFormattedData = [];
    const resp = await getTRUTOJobs({ integration_id, source: 'ASHBY' });
    setIdentifierDataLoading(false);
    resp?.data?.result?.forEach((item) => {
      identifierFormattedData.push(item.name);
    });
    const uniqueIdentifierData = Array.from(new Set(identifierFormattedData));
    setIdentifierValues(uniqueIdentifierData);
  };

  const onClickIdentifierHandler = () => {
    mapAPICallToIdentifier();
  };
  const checkForTagsError = (tags) => {
    if (tags?.length > 0 && !identifierValues.includes(...tags) && !identifierDataLoading) {
      return <span className={styles.errorInline}>Tags not available</span>;
    }
    return null;
  };

  const tagDropdownStyles = {
    option: (provided, state) => ({
      ...provided,
      fontFamily: 'Poppins',
      fontSize: '14px',
      lineHeight: '21px',
      color: '#666666',
      borderRadius: '4px',
      padding: '10px 20px',
      border: 'none',
      background: state.isFocused ? '#ffffff' : ''
    }),
    control: (provided) => ({
      ...provided,
      border: '1px solid #ebedf2',
      boxSizing: 'border-box',
      borderRadius: '4px',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#333333',
      display: 'flex'
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: '#333333'
      };
    },
    valueContainer: (base) => ({
      ...base
    }),
    container: (base) => ({
      ...base
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: '10'
    })
  };

  const isFormValid = () => {
    return (
      packages.length > 0 &&
      !isEmpty(values.identifierValue) &&
      (!!props.integrationData?.ashby?.triggerStage || values?.triggerStage !== 'Trigger Stages') &&
      Object.values(values?.tags).filter((tag) => tag && !isEmpty(tag)).length === packages.length
    );
  };

  useEffect(() => {
    getTRUTOLinkToken()
      .then((response) => {
        setLinkToken(response.data.link_token);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <IntegrationLayout>
        <div className={styles.parentDivTopTextArea}>
          <TopTextArea title={'Ashby'} icon={'ashby.png'} />
          <Container>
            {!integrationData?.ashby?.integrationId && (
              <div className='mt-5'>
                {ashbyDetails.map((step, index) => {
                  return (
                    <Row>
                      <h6>
                        <span className='font-weight-bold'>Step {index + 1}:</span> {step}
                      </h6>
                    </Row>
                  );
                })}
              </div>
            )}

            {integrationData?.ashby?.integrationId && (
              <div>
                {/* Trigger stage (Application stage) from Ashby  */}
                <>
                  <div className={styles.settingPageContainer}>
                    <Row className='justify-content-between'>
                      <span className={styles.settingPageHeaderText}> Select Trigger Stage</span>
                    </Row>
                    <div className={styles.settingPageHeaderSubText1}>
                      Select Ashby Job Trigger Stage for which you want to initiate the Integration
                    </div>
                  </div>
                  <Row className={styles.dropdownContainer}>
                    <DropdownList
                      style={{ width: '300px' }}
                      data={applicationStages}
                      dataKey='id'
                      textField='text'
                      value={values?.triggerStage}
                      name='triggerStage'
                      selectIcon={<Image name='dropdownArrow.svg' />}
                      onChange={(value) => {
                        setFieldValue('triggerStage', value);
                      }}
                    />
                  </Row>

                  <div className={styles.settingPageContainer}>
                    {/* Ashby fields to select for package mapping */}
                    <Row className='justify-content-between'>
                      <span className={styles.settingPageHeaderText}> Select Ashby field</span>
                    </Row>
                    <div className={styles.settingPageHeaderSubText1}>
                      Select ashby field for which you want to use to configure packages
                    </div>
                  </div>

                  <Row className={styles.dropdownContainer}>
                    <DropdownList
                      disabled={isPackageMapped}
                      style={{ width: '300px' }}
                      data={ashbyIdentifierArray}
                      dataKey='id'
                      textField='text'
                      value={values?.identifierValue}
                      name='identifierValue'
                      selectIcon={<Image name='dropdownArrow.svg' />}
                      onChange={(value) => {
                        setFieldValue('identifierValue', value);
                      }}
                    />
                  </Row>
                </>

                <div className={styles.secondPageSubSectionContainer}>
                  <Row>
                    <span className={styles.settingPageSubSectionHeader}>
                      {' '}
                      Setup Package Association
                    </span>
                  </Row>
                  <div className={styles.settingPageSubSectionList1}>
                    Match the relevant {props.values?.identifierValue} in Ashby to the packages on
                    SpringVerify.
                    <br />
                    Count of Ashby {props.values?.identifierValue} should be at least equal to the
                    number of package assigned.
                  </div>
                </div>

                <div className={styles.packageSelectorHeaderContainer1} xs={2} md={4} lg={6}>
                  <Col md={3} className={styles.packageNameHeader}>
                    <div className={styles.packageTextStyle}>PACKAGE NAME</div>
                  </Col>
                  <Col className={styles.refreshPackageContainer}>
                    <div className={styles.packageTextStyle}>SELECT JOBS</div>
                    <div
                      className={styles.refreshPackageButton}
                      onClick={() => onClickIdentifierHandler()}
                    >
                      <Image className={styles.refreshImageContainer} name='reset.png' />
                      <span className={styles.refreshText}>Refresh</span>
                    </div>
                  </Col>
                </div>
                {packages.map((packageRow) => (
                  <Row xs={2} md={4} lg={6} className='my-2'>
                    <Col md={3} className={styles.packageName}>
                      <div className={styles.text}>
                        <span className={styles.packageNameText}>{packageRow.package_name}</span>
                        <span className={styles.packageSubtypeNameText}>
                          {packageRow.subtype_name}
                        </span>
                      </div>
                    </Col>
                    <Col>
                      <Select
                        className={styles.tagsDropDown}
                        isMulti
                        placeholder={
                          identifierDataLoading
                            ? `${props.values?.identifierValue} loading...`
                            : identifierValues.length > 0
                            ? `Please select ${props.values?.identifierValue}`
                            : `No ${props.values.identifierValue}`
                        }
                        name='tags'
                        components={{ identifierSeparator }}
                        options={dropdownIdentifierValues}
                        getOptionLabel={(option) => option}
                        getOptionValue={(option) => option}
                        styles={tagDropdownStyles}
                        isClearable={false}
                        noOptionsMessage={() => (
                          <span className={styles.dropDownNoOptionMessage}>
                            No {props.values?.identifierValue} available. Please create relevant{' '}
                            {props.values?.identifierValue} on Ashby and refresh.
                          </span>
                        )}
                        value={values?.tags[packageRow?.subtype_id]?.tags}
                        onChange={(e) => {
                          setTagsValue(packageRow?.package_id, packageRow?.subtype_id, e);
                        }}
                      />
                      {checkForTagsError(values?.tags[packageRow.subtype_id]?.tags)}
                    </Col>
                  </Row>
                ))}
              </div>
            )}
          </Container>
        </div>
        <Container>
          <div className={`${styles.parentDivInputArea} mb-5`}>
            <div className={styles.rowDisplay}>
              <Row>
                <div
                  className={
                    integrationData?.ashby?.integrationId
                      ? styles.step2FooterContainer
                      : styles.step1FooterContainer
                  }
                >
                  <Button className={styles.goBackKNITBtn} onClick={() => backStepHandler()}>
                    Go Back
                  </Button>
                  {!integrationData?.ashby?.integrationId ? (
                    <>
                      <Button className={styles.connectButton} onClick={() => connectTruto()}>
                        Connect
                      </Button>
                    </>
                  ) : (
                    <div>
                      <Button
                        className={styles.removeButtonRepute}
                        onClick={() => setIsOpen(true)}
                        variant='outline-danger'
                      >
                        Disconnect
                      </Button>
                      <Button
                        className={styles.activeBtn}
                        onClick={handleSubmit}
                        disabled={!isFormValid() || checkIfTagsNotAvailable() || isSubmitting}
                      >
                        Save
                      </Button>
                    </div>
                  )}
                </div>
              </Row>
              {isOpen ? (
                <WarningModal
                  show={isOpen}
                  onHide={() => {
                    setIsOpen(false);
                  }}
                  title={'Ashby'}
                  onConfirm={() => {
                    onRemoveConnectionHandler();
                  }}
                />
              ) : null}
            </div>
          </div>
        </Container>
      </IntegrationLayout>
      {showModal ? (
        <ConfirmationModal
          show={showModal}
          onHide={() => {
            setShowModal(false);
          }}
          heading={'Integration Alert'}
          onSubmit={() => {
            setShowModal(false);
            props.history.push(`/integrations`);
          }}
          enableBtn={true}
          subHeading={
            'Your account includes multiple packages and requires a custom configuration. Our CS team has been alerted about your request and will get in touch with you to enable the integration.'
          }
          sBtnText={'Close'}
          hideCloseBtn={true}
        />
      ) : null}
    </>
  );
}
