/* eslint-disable no-unused-expressions */
import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { capitalize, startCase } from 'lodash';
import Icon from '../../../../core-components/Icon';
import { ADDRESS_TYPES, CHECK_STATUSES } from '../../../../utils';

import { getCustomMapping, getStatus } from '../../../../utils/utilities';

import {
  IDENTITY_TYPE,
  ICONS_VERIFICATIONS,
  CUSTOMIZATION_TYPES_STATUS_CODES_MAPPING
} from '../../../../utils/commonConstant';
import styles from './VerificationIcons.modules.scss';

export default (props) => {
  const getColor = (status) => {
    return getCustomMapping(
      props?.checkStatusesMapping?.colors,
      status,
      CUSTOMIZATION_TYPES_STATUS_CODES_MAPPING.CHECK_STATUSES.COLORS
    );
  };

  const getLabel = (status) => {
    return getCustomMapping(
      props?.checkStatusesMapping?.labels,
      status,
      CUSTOMIZATION_TYPES_STATUS_CODES_MAPPING.CHECK_STATUSES.LABELS
    );
  };

  let VERIFICATION_ICONS = ICONS_VERIFICATIONS;

  let verficationIconsArray = [];
  if (props?.candidate?.candidatesIds !== undefined && props?.candidate?.candidatesIds !== null) {
    // eslint-disable-next-line no-unused-expressions
    props?.candidate?.candidatesIds?.map((value, index) => {
      let { status } = value;
      status = getStatus(status);
      verficationIconsArray.push(
        <OverlayTrigger
          key={`candidate_ids_${index}`}
          placement='bottom'
          overlay={
            <Tooltip id={`identity${index}`}>
              <p>{VERIFICATION_ICONS[0].value}</p>
              <p>
                {IDENTITY_TYPE[value.type]}
                <span
                  style={{ color: getColor(value?.status) || CHECK_STATUSES.values[status].color }}
                >
                  {getLabel(value?.status) || CHECK_STATUSES.values[status].value}
                </span>
              </p>
            </Tooltip>
          }
        >
          <div>
            <Icon
              icon={VERIFICATION_ICONS[0].icon}
              color={getColor(value?.status) || CHECK_STATUSES.values[status].color}
              width={VERIFICATION_ICONS[0].width}
              height={16}
              className={styles.iconScaling}
            />
          </div>
        </OverlayTrigger>
      );
    });
  }
  if (
    props?.candidate?.candidatesEmployments !== undefined &&
    props?.candidate?.candidatesEmployments !== null
  ) {
    // eslint-disable-next-line no-unused-expressions
    props?.candidate?.candidatesEmployments?.map((value, index) => {
      let { status } = value;
      status = getStatus(status);
      verficationIconsArray.push(
        <OverlayTrigger
          key={`candidate_employements_${index}`}
          placement='bottom'
          overlay={
            <Tooltip id={`employments${index}`}>
              <p>{VERIFICATION_ICONS[1].value}</p>
              <p>
                {value.companyName}
                <span
                  style={{ color: getColor(value?.status) || CHECK_STATUSES.values[status].color }}
                >
                  {getLabel(value?.status) || CHECK_STATUSES.values[status].value}
                </span>
              </p>
            </Tooltip>
          }
        >
          <div>
            <Icon
              icon={VERIFICATION_ICONS[1].icon}
              color={getColor(value?.status) || CHECK_STATUSES.values[status].color}
              width={VERIFICATION_ICONS[1].width}
              height={16}
              className={styles.iconScaling}
            />
          </div>
        </OverlayTrigger>
      );
    });
  }
  if (
    props?.candidate?.candidatesAddresses !== undefined &&
    props?.candidate?.candidatesAddresses !== null
  ) {
    // eslint-disable-next-line no-unused-expressions
    props?.candidate?.candidatesAddresses.map((value, index) => {
      let { status } = value;
      status = getStatus(status);
      if (status !== 11) {
        verficationIconsArray.push(
          <OverlayTrigger
            key={`candidate_addresses_${index}`}
            placement='bottom'
            overlay={
              <Tooltip id={`address${index}`}>
                <p>{VERIFICATION_ICONS[2].value}</p>
                <p>
                  {ADDRESS_TYPES[value.type]}
                  <span
                    style={{
                      color: getColor(value?.status) || CHECK_STATUSES.values[status].color
                    }}
                  >
                    {getLabel(value?.status) || CHECK_STATUSES.values[status].value}
                  </span>
                </p>
              </Tooltip>
            }
          >
            <div>
              <Icon
                icon={VERIFICATION_ICONS[2].icon}
                color={getColor(value?.status) || CHECK_STATUSES.values[status].color}
                width={VERIFICATION_ICONS[2].width}
                height={16}
                className={styles.iconScaling}
              />
            </div>
          </OverlayTrigger>
        );
      }
    });
  }
  if (
    props?.candidate?.candidatesEducations !== undefined &&
    props?.candidate?.candidatesEducations !== null
  ) {
    props?.candidate?.candidatesEducations.map((value, index) => {
      let { status } = value;
      status = getStatus(status);

      verficationIconsArray.push(
        <OverlayTrigger
          key={`candidate_educations_${index}`}
          placement='bottom'
          overlay={
            <Tooltip id={`educations${index}`}>
              <p>{VERIFICATION_ICONS[3]?.value}</p>
              <p>
                {startCase(capitalize(value?.type?.replace(/_Std(.*)$/i, '')))}
                <span
                  style={{
                    color: getColor(value?.status) || CHECK_STATUSES?.values[status]?.color
                  }}
                >
                  {getLabel(value?.status) || CHECK_STATUSES?.values[status]?.value}
                </span>
              </p>
            </Tooltip>
          }
        >
          <div>
            <Icon
              icon={VERIFICATION_ICONS[3]?.icon}
              color={getColor(value?.status) || CHECK_STATUSES?.values[status]?.color}
              width={VERIFICATION_ICONS[3]?.width}
              height={16}
              className={styles.iconScaling}
            />
          </div>
        </OverlayTrigger>
      );
    });
  }
  if (
    props?.candidate?.candidatesWorldchecks !== undefined &&
    props?.candidate?.candidatesWorldchecks !== null
  ) {
    props.candidate.candidatesWorldchecks.map((value, index) => {
      let { status } = value;
      status = getStatus(status);

      verficationIconsArray.push(
        <OverlayTrigger
          key={`candidate_world_checks_${index}`}
          placement='bottom'
          overlay={
            <Tooltip id={`world${index}`}>
              <p>{VERIFICATION_ICONS[4].value}</p>
              <p>
                <span
                  style={{ color: getColor(value?.status) || CHECK_STATUSES.values[status].color }}
                >
                  {getLabel(value?.status) || CHECK_STATUSES.values[status].value}
                </span>
              </p>
            </Tooltip>
          }
        >
          <div>
            <Icon
              icon={VERIFICATION_ICONS[4].icon}
              color={getColor(value?.status) || CHECK_STATUSES.values[status].color}
              width={VERIFICATION_ICONS[4].width}
              height={16}
              className={styles.iconScaling}
            />
          </div>
        </OverlayTrigger>
      );
    });
  }

  if (props?.candidate?.candidateDrugs !== undefined && props?.candidate?.candidateDrugs !== null) {
    props.candidate.candidateDrugs.map((value, index) => {
      let { status } = value;
      status = getStatus(status);
      verficationIconsArray.push(
        <OverlayTrigger
          key={`candidate_drugs_${index}`}
          placement='bottom'
          overlay={
            <Tooltip id={`drug${index}`}>
              <p>{VERIFICATION_ICONS[5].value}</p>
              <p>
                <span
                  style={{ color: getColor(value?.status) || CHECK_STATUSES.values[status].color }}
                >
                  {getLabel(value?.status) || CHECK_STATUSES.values[status].value}
                </span>
              </p>
            </Tooltip>
          }
        >
          <div>
            <Icon
              icon={VERIFICATION_ICONS[5].icon}
              color={getColor(value?.status) || CHECK_STATUSES.values[status].color}
              width={VERIFICATION_ICONS[5].width}
              height={16}
              className={styles.iconScaling}
            />
          </div>
        </OverlayTrigger>
      );
    });
  }

  if (
    props?.candidate?.candidateHistories !== undefined &&
    props?.candidate?.candidateHistories !== null
  ) {
    props.candidate.candidateHistories.map((value, index) => {
      let { status } = value;
      status = getStatus(status);

      verficationIconsArray.push(
        <OverlayTrigger
          key={`candidate_histories_${index}`}
          placement='bottom'
          overlay={
            <Tooltip id={`court${index}`}>
              <p>{VERIFICATION_ICONS[6].value}</p>
              <p>
                <span
                  style={{ color: getColor(value?.status) || CHECK_STATUSES.values[status].color }}
                >
                  {getLabel(value?.status) || CHECK_STATUSES.values[status].value}
                </span>
              </p>
            </Tooltip>
          }
        >
          <div>
            <Icon
              icon={VERIFICATION_ICONS[6].icon}
              color={getColor(value?.status) || CHECK_STATUSES.values[status].color}
              width={VERIFICATION_ICONS[6].width}
              height={16}
              className={styles.iconScaling}
            />
          </div>
        </OverlayTrigger>
      );
    });
  }

  if (
    props?.candidate?.candidatesCreditchecks !== undefined &&
    props?.candidate?.candidatesCreditchecks !== null
  ) {
    props.candidate.candidatesCreditchecks.map((value, index) => {
      let { status } = value;
      status = getStatus(status);

      verficationIconsArray.push(
        <OverlayTrigger
          key={`candidate_credit_checks_${index}`}
          placement='bottom'
          overlay={
            <Tooltip id={`credits${index}`}>
              <p>{VERIFICATION_ICONS[7].value}</p>
              <p>
                <span
                  style={{ color: getColor(value?.status) || CHECK_STATUSES.values[status].color }}
                >
                  {getLabel(value?.status) || CHECK_STATUSES.values[status].value}
                </span>
              </p>
            </Tooltip>
          }
        >
          <div>
            <Icon
              icon={VERIFICATION_ICONS[7].icon}
              color={getColor(value?.status) || CHECK_STATUSES.values[status].color}
              width={VERIFICATION_ICONS[7].width}
              height={16}
              className={styles.iconScaling}
            />
          </div>
        </OverlayTrigger>
      );
    });
  }

  if (
    props?.candidate?.candidatesRefchecks !== undefined &&
    props?.candidate?.candidatesRefchecks !== null
  ) {
    props.candidate.candidatesRefchecks.map((value, index) => {
      let { status } = value;
      status = getStatus(status);

      verficationIconsArray.push(
        <OverlayTrigger
          key={`candidate_refchecks_${index}`}
          placement='bottom'
          overlay={
            <Tooltip id={`ref${index}`}>
              <p>{VERIFICATION_ICONS[8].value}</p>
              <p>
                <span
                  style={{ color: getColor(value?.status) || CHECK_STATUSES.values[status].color }}
                >
                  {getLabel(value?.status) || CHECK_STATUSES.values[status].value}
                </span>
              </p>
            </Tooltip>
          }
        >
          <div>
            <Icon
              icon={VERIFICATION_ICONS[8].icon}
              color={getColor(value?.status) || CHECK_STATUSES.values[status].color}
              width={VERIFICATION_ICONS[8].width}
              height={16}
              className={styles.iconScaling}
            />
          </div>
        </OverlayTrigger>
      );
    });
  }

  if (props?.candidate?.customCheck !== undefined && props?.candidate?.customCheck !== null) {
    props.candidate.customCheck.map((value, index) => {
      let { status } = value;
      status = getStatus(status);
      const truncatedCheckName =
        value.checkName.length > 50
          ? `${value.checkName.substring(0, 50)}...`
          : value.checkName || 'Custom Verification';

      verficationIconsArray.push(
        <OverlayTrigger
          key={`candidate_customchecks_${index}`}
          placement='bottom'
          overlay={
            <Tooltip id={`ref${index}`} className={styles.customCheckTooltip}>
              <p>{truncatedCheckName}</p>
              <p>
                <span
                  style={{ color: getColor(value?.status) || CHECK_STATUSES.values[status]?.color }}
                >
                  {getLabel(value?.status) || CHECK_STATUSES.values[status]?.value}
                </span>
              </p>
            </Tooltip>
          }
        >
          <div>
            <Icon
              icon={VERIFICATION_ICONS[9].icon}
              color={getColor(value?.status) || CHECK_STATUSES.values[status].color}
              width={VERIFICATION_ICONS[9].width}
              height={16}
              className={styles.iconScaling}
            />
          </div>
        </OverlayTrigger>
      );
    });
  }

  if (verficationIconsArray.length === 0) {
    if (props?.status === 3) {
      props?.candidateMapping?.paymentsCandidateSubtypeMapping?.paymentsCompanyMappingSubTypesIds?.map(
        (value, index) => {
          verficationIconsArray.push(
            <OverlayTrigger
              key={`candidate_ids_${index}`}
              placement='bottom'
              overlay={
                <Tooltip id={`identity${index}`}>
                  <p>{VERIFICATION_ICONS[0].value}</p>
                  <p>
                    <span
                      style={{ color: getColor(value?.status) || CHECK_STATUSES.values[3].color }}
                    >
                      {getColor(value?.status) || CHECK_STATUSES.values[3].value}
                    </span>
                  </p>
                </Tooltip>
              }
            >
              <div>
                <Icon
                  icon={VERIFICATION_ICONS[0].icon}
                  color={getColor(value?.status) || CHECK_STATUSES.values[3].color}
                  width={VERIFICATION_ICONS[0].width}
                  height={16}
                  className={styles.iconScaling}
                />
              </div>
            </OverlayTrigger>
          );
        }
      );
      props?.candidateMapping?.paymentsCandidateSubtypeMapping?.paymentsCompanyAddOnIds?.map(
        (value, index) => {
          verficationIconsArray.push(
            <OverlayTrigger
              key={`candidate_ids_${index}`}
              placement='bottom'
              overlay={
                <Tooltip id={`identity${index}`}>
                  <p>{VERIFICATION_ICONS[0].value}</p>
                  <p>
                    <span
                      style={{ color: getColor(value?.status) || CHECK_STATUSES.values[3].color }}
                    >
                      {getLabel(value?.status) || CHECK_STATUSES.values[3].value}
                    </span>
                  </p>
                </Tooltip>
              }
            >
              <div>
                <Icon
                  icon={VERIFICATION_ICONS[0].icon}
                  color={getColor(value?.status) || CHECK_STATUSES.values[3].color}
                  width={VERIFICATION_ICONS[0].width}
                  height={16}
                  className={styles.iconScaling}
                />
              </div>
            </OverlayTrigger>
          );
        }
      );

      let employmentCount =
        props?.candidateMapping?.paymentsCandidateSubtypeMapping?.employmentCount;
      for (let i = 0; i < employmentCount; i++) {
        verficationIconsArray.push(
          <OverlayTrigger
            key={`candidate_employements`}
            placement='bottom'
            overlay={
              <Tooltip id={`employments`}>
                <p>{VERIFICATION_ICONS[1].value}</p>
                <p>
                  <span style={{ color: CHECK_STATUSES.values[3].color }}>
                    {CHECK_STATUSES.values[3].value}
                  </span>
                </p>
              </Tooltip>
            }
          >
            <div>
              <Icon
                icon={VERIFICATION_ICONS[1].icon}
                color={CHECK_STATUSES.values[3].color}
                width={VERIFICATION_ICONS[1].width}
                height={16}
                className={styles.iconScaling}
              />
            </div>
          </OverlayTrigger>
        );
      }
      props?.candidateMapping?.paymentsCandidateSubtypeMapping?.paymentsCompanyAddOnEmployments?.map(
        (value, index) => {
          verficationIconsArray.push(
            <OverlayTrigger
              key={`candidate_employements`}
              placement='bottom'
              overlay={
                <Tooltip id={`employments`}>
                  <p>{VERIFICATION_ICONS[1].value}</p>
                  <p>
                    <span
                      style={{ color: getColor(value?.status) || CHECK_STATUSES.values[3].color }}
                    >
                      {getLabel(value?.status) || CHECK_STATUSES.values[3].value}
                    </span>
                  </p>
                </Tooltip>
              }
            >
              <div>
                <Icon
                  icon={VERIFICATION_ICONS[1].icon}
                  color={getColor(value?.status) || CHECK_STATUSES.values[3].color}
                  width={VERIFICATION_ICONS[1].width}
                  height={16}
                  className={styles.iconScaling}
                />
              </div>
            </OverlayTrigger>
          );
        }
      );

      props?.candidateMapping?.paymentsCandidateSubtypeMapping?.paymentsCompanyMappingSubTypesAddresses?.map(
        (value, index) => {
          verficationIconsArray.push(
            <OverlayTrigger
              key={`candidate_addresses_${index}`}
              placement='bottom'
              overlay={
                <Tooltip id={`address${index}`}>
                  <p>{VERIFICATION_ICONS[2].value}</p>
                  <p>
                    <span
                      style={{ color: getColor(value?.status) || CHECK_STATUSES.values[3].color }}
                    >
                      {getLabel(value?.status) || CHECK_STATUSES.values[3].value}
                    </span>
                  </p>
                </Tooltip>
              }
            >
              <div>
                <Icon
                  icon={VERIFICATION_ICONS[2].icon}
                  color={getColor(value?.status) || CHECK_STATUSES.values[3].color}
                  width={VERIFICATION_ICONS[2].width}
                  height={16}
                  className={styles.iconScaling}
                />
              </div>
            </OverlayTrigger>
          );
        }
      );
      props?.candidateMapping?.paymentsCandidateSubtypeMapping?.paymentsCompanyAddOnAddresses?.map(
        (value, index) => {
          verficationIconsArray.push(
            <OverlayTrigger
              key={`candidate_addresses_${index}`}
              placement='bottom'
              overlay={
                <Tooltip id={`address${index}`}>
                  <p>{VERIFICATION_ICONS[2].value}</p>
                  <p>
                    <span
                      style={{ color: getColor(value?.status) || CHECK_STATUSES.values[3].color }}
                    >
                      {getLabel(value?.status) || CHECK_STATUSES.values[3].value}
                    </span>
                  </p>
                </Tooltip>
              }
            >
              <div>
                <Icon
                  icon={VERIFICATION_ICONS[2].icon}
                  color={getColor(value?.status) || CHECK_STATUSES.values[3].color}
                  width={VERIFICATION_ICONS[2].width}
                  height={16}
                  className={styles.iconScaling}
                />
              </div>
            </OverlayTrigger>
          );
        }
      );

      let educationCount = props?.candidateMapping?.paymentsCandidateSubtypeMapping?.educationCount;
      for (let i = 0; i < educationCount; i++) {
        verficationIconsArray.push(
          <OverlayTrigger
            key={`candidate_educations`}
            placement='bottom'
            overlay={
              <Tooltip id={`educations`}>
                <p>{VERIFICATION_ICONS[3].value}</p>
                <p>
                  <span style={{ color: CHECK_STATUSES.values[3].color }}>
                    {CHECK_STATUSES.values[3].value}
                  </span>
                </p>
              </Tooltip>
            }
          >
            <div>
              <Icon
                icon={VERIFICATION_ICONS[3].icon}
                color={CHECK_STATUSES.values[3].color}
                width={VERIFICATION_ICONS[3].width}
                height={16}
                className={styles.iconScaling}
              />
            </div>
          </OverlayTrigger>
        );
      }
      props?.candidateMapping?.paymentsCandidateSubtypeMapping?.paymentsCompanyAddOnEducations?.map(
        (value, index) => {
          verficationIconsArray.push(
            <OverlayTrigger
              key={`candidate_educations`}
              placement='bottom'
              overlay={
                <Tooltip id={`educations`}>
                  <p>{VERIFICATION_ICONS[3].value}</p>
                  <p>
                    <span
                      style={{ color: getColor(value?.status) || CHECK_STATUSES.values[3].color }}
                    >
                      {getLabel(value?.status) || CHECK_STATUSES.values[3].value}
                    </span>
                  </p>
                </Tooltip>
              }
            >
              <div>
                <Icon
                  icon={VERIFICATION_ICONS[3].icon}
                  color={getColor(value?.status) || CHECK_STATUSES.values[3].color}
                  width={VERIFICATION_ICONS[3].width}
                  height={16}
                  className={styles.iconScaling}
                />
              </div>
            </OverlayTrigger>
          );
        }
      );

      if (props?.candidateMapping?.paymentsCandidateSubtypeMapping?.world) {
        verficationIconsArray.push(
          <OverlayTrigger
            key={`candidate_world_checks`}
            placement='bottom'
            overlay={
              <Tooltip id={`world`}>
                <p>{VERIFICATION_ICONS[4].value}</p>
                <p>
                  <span style={{ color: CHECK_STATUSES.values[3].color }}>
                    {CHECK_STATUSES.values[3].value}
                  </span>
                </p>
              </Tooltip>
            }
          >
            <div>
              <Icon
                icon={VERIFICATION_ICONS[4].icon}
                color={CHECK_STATUSES.values[3].color}
                width={VERIFICATION_ICONS[4].width}
                height={16}
                className={styles.iconScaling}
              />
            </div>
          </OverlayTrigger>
        );
      }
      props?.candidateMapping?.paymentsCandidateSubtypeMapping?.paymentsCompanyAddOnWorlds?.map(
        (value, index) => {
          verficationIconsArray.push(
            <OverlayTrigger
              key={`candidate_world_checks`}
              placement='bottom'
              overlay={
                <Tooltip id={`world`}>
                  <p>{VERIFICATION_ICONS[4].value}</p>
                  <p>
                    <span
                      style={{ color: getColor(value?.status) || CHECK_STATUSES.values[3].color }}
                    >
                      {getLabel(value?.status) || CHECK_STATUSES.values[3].value}
                    </span>
                  </p>
                </Tooltip>
              }
            >
              <div>
                <Icon
                  icon={VERIFICATION_ICONS[4].icon}
                  color={getColor(value?.status) || CHECK_STATUSES.values[3].color}
                  width={VERIFICATION_ICONS[4].width}
                  height={16}
                  className={styles.iconScaling}
                />
              </div>
            </OverlayTrigger>
          );
        }
      );

      if (
        props?.candidateMapping?.paymentsCandidateSubtypeMapping?.drug !== 'NONE' &&
        props?.candidateMapping?.paymentsCandidateSubtypeMapping?.drug !== null &&
        props?.candidateMapping?.paymentsCandidateSubtypeMapping?.drug !== undefined
      ) {
        verficationIconsArray.push(
          <OverlayTrigger
            key={`candidate_drugs`}
            placement='bottom'
            overlay={
              <Tooltip id={`drug`}>
                <p>{VERIFICATION_ICONS[5].value}</p>
                <p>
                  <span style={{ color: CHECK_STATUSES.values[3].color }}>
                    {CHECK_STATUSES.values[3].value}
                  </span>
                </p>
              </Tooltip>
            }
          >
            <div>
              <Icon
                icon={VERIFICATION_ICONS[5].icon}
                color={CHECK_STATUSES.values[3].color}
                width={VERIFICATION_ICONS[5].width}
                height={16}
                className={styles.iconScaling}
              />
            </div>
          </OverlayTrigger>
        );
      }
      props?.candidateMapping?.paymentsCandidateSubtypeMapping?.paymentsCompanyAddOnDrugs?.map(
        (value, index) => {
          verficationIconsArray.push(
            <OverlayTrigger
              key={`candidate_drugs`}
              placement='bottom'
              overlay={
                <Tooltip id={`drug`}>
                  <p>{VERIFICATION_ICONS[5].value}</p>
                  <p>
                    <span
                      style={{ color: getColor(value?.status) || CHECK_STATUSES.values[3].color }}
                    >
                      {getLabel(value?.status) || CHECK_STATUSES.values[3].value}
                    </span>
                  </p>
                </Tooltip>
              }
            >
              <div>
                <Icon
                  icon={VERIFICATION_ICONS[5].icon}
                  color={getColor(value?.status) || CHECK_STATUSES.values[3].color}
                  width={VERIFICATION_ICONS[5].width}
                  height={16}
                  className={styles.iconScaling}
                />
              </div>
            </OverlayTrigger>
          );
        }
      );

      props?.candidateMapping?.paymentsCandidateSubtypeMapping?.paymentsCompanyMappingSubTypesCourts?.map(
        (value, index) => {
          verficationIconsArray.push(
            <OverlayTrigger
              key={`candidate_histories`}
              placement='bottom'
              overlay={
                <Tooltip id={`court`}>
                  <p>{VERIFICATION_ICONS[6].value}</p>
                  <p>
                    <span
                      style={{ color: getColor(value?.status) || CHECK_STATUSES.values[3].color }}
                    >
                      {getLabel(value?.status) || CHECK_STATUSES.values[3].value}
                    </span>
                  </p>
                </Tooltip>
              }
            >
              <div>
                <Icon
                  icon={VERIFICATION_ICONS[6].icon}
                  color={getColor(value?.status) || CHECK_STATUSES.values[3].color}
                  width={VERIFICATION_ICONS[6].width}
                  height={16}
                  className={styles.iconScaling}
                />
              </div>
            </OverlayTrigger>
          );
        }
      );
      props?.candidateMapping?.paymentsCandidateSubtypeMapping?.paymentsCompanyAddOnCourts?.map(
        (value, index) => {
          verficationIconsArray.push(
            <OverlayTrigger
              key={`candidate_histories`}
              placement='bottom'
              overlay={
                <Tooltip id={`court`}>
                  <p>{VERIFICATION_ICONS[6].value}</p>
                  <p>
                    <span
                      style={{ color: getColor(value?.status) || CHECK_STATUSES.values[3].color }}
                    >
                      {getLabel(value?.status) || CHECK_STATUSES.values[3].value}
                    </span>
                  </p>
                </Tooltip>
              }
            >
              <div>
                <Icon
                  icon={VERIFICATION_ICONS[6].icon}
                  color={getColor(value?.status) || CHECK_STATUSES.values[3].color}
                  width={VERIFICATION_ICONS[6].width}
                  height={16}
                  className={styles.iconScaling}
                />
              </div>
            </OverlayTrigger>
          );
        }
      );

      if (props?.candidateMapping?.paymentsCandidateSubtypeMapping?.credit) {
        verficationIconsArray.push(
          <OverlayTrigger
            key={`candidate_credit_checks`}
            placement='bottom'
            overlay={
              <Tooltip id={`credits`}>
                <p>{VERIFICATION_ICONS[7].value}</p>
                <p>
                  <span style={{ color: CHECK_STATUSES.values[3].color }}>
                    {CHECK_STATUSES.values[3].value}
                  </span>
                </p>
              </Tooltip>
            }
          >
            <div>
              <Icon
                icon={VERIFICATION_ICONS[7].icon}
                color={CHECK_STATUSES.values[3].color}
                width={VERIFICATION_ICONS[7].width}
                height={16}
                className={styles.iconScaling}
              />
            </div>
          </OverlayTrigger>
        );
      }
      props?.candidateMapping?.paymentsCandidateSubtypeMapping?.paymentsCompanyAddOnCredits?.map(
        (value, index) => {
          verficationIconsArray.push(
            <OverlayTrigger
              key={`candidate_credit_checks`}
              placement='bottom'
              overlay={
                <Tooltip id={`credits`}>
                  <p>{VERIFICATION_ICONS[7].value}</p>
                  <p>
                    <span
                      style={{ color: getColor(value?.status) || CHECK_STATUSES.values[3].color }}
                    >
                      {getLabel(value?.status) || CHECK_STATUSES.values[3].value}
                    </span>
                  </p>
                </Tooltip>
              }
            >
              <div>
                <Icon
                  icon={VERIFICATION_ICONS[7].icon}
                  color={getColor(value?.status) || CHECK_STATUSES.values[3].color}
                  width={VERIFICATION_ICONS[7].width}
                  height={16}
                  className={styles.iconScaling}
                />
              </div>
            </OverlayTrigger>
          );
        }
      );

      if (
        props?.candidateMapping?.paymentsCandidateSubtypeMapping?.paymentsCompanyMappingSubTypesRef
      ) {
        let refCheckCount = Object.keys(
          props?.candidateMapping?.paymentsCandidateSubtypeMapping
            ?.paymentsCompanyMappingSubTypesRef
        ).length;

        for (let i = 0; i < refCheckCount; i++) {
          verficationIconsArray.push(
            <OverlayTrigger
              key={`candidate_refchecks`}
              placement='bottom'
              overlay={
                <Tooltip id={`ref`}>
                  <p>{VERIFICATION_ICONS[8].value}</p>
                  <p>
                    <span style={{ color: CHECK_STATUSES.values[3].color }}>
                      {CHECK_STATUSES.values[3].value}
                    </span>
                  </p>
                </Tooltip>
              }
            >
              <div>
                <Icon
                  icon={VERIFICATION_ICONS[8].icon}
                  color={CHECK_STATUSES.values[3].color}
                  width={VERIFICATION_ICONS[8].width}
                  height={16}
                  className={styles.iconScaling}
                />
              </div>
            </OverlayTrigger>
          );
        }
      }
      props?.candidateMapping?.paymentsCandidateSubtypeMapping?.paymentsCompanyAddOnRefs?.map(
        (value, index) => {
          verficationIconsArray.push(
            <OverlayTrigger
              key={`candidate_refchecks`}
              placement='bottom'
              overlay={
                <Tooltip id={`ref`}>
                  <p>{VERIFICATION_ICONS[8].value}</p>
                  <p>
                    <span
                      style={{ color: getColor(value?.status) || CHECK_STATUSES.values[3].color }}
                    >
                      {getLabel(value?.status) || CHECK_STATUSES.values[3].value}
                    </span>
                  </p>
                </Tooltip>
              }
            >
              <div>
                <Icon
                  icon={VERIFICATION_ICONS[8].icon}
                  color={getColor(value?.status) || CHECK_STATUSES.values[3].color}
                  width={VERIFICATION_ICONS[8].width}
                  height={16}
                  className={styles.iconScaling}
                />
              </div>
            </OverlayTrigger>
          );
        }
      );

      props?.candidateMapping?.paymentsCandidateSubtypeMapping?.paymentsCompanySubtypeMapping?.paymentsPackagesSubtype?.paymentsCustomSubType?.map(
        (value, index) => {
          verficationIconsArray.push(
            <OverlayTrigger
              key={`candidate_customchecks`}
              placement='bottom'
              overlay={
                <Tooltip id={`ref`}>
                  <p>{value.customCheckName || 'Custom Verification'}</p>
                  <p>
                    <span
                      style={{ color: getColor(value?.status) || CHECK_STATUSES.values[3]?.color }}
                    >
                      {getLabel(value?.status) || CHECK_STATUSES.values[3]?.value}
                    </span>
                  </p>
                </Tooltip>
              }
            >
              <div>
                <Icon
                  icon={VERIFICATION_ICONS[9].icon}
                  color={getColor(value?.status) || CHECK_STATUSES.values[3].color}
                  width={VERIFICATION_ICONS[9].width}
                  height={16}
                  className={styles.iconScaling}
                />
              </div>
            </OverlayTrigger>
          );
        }
      );
      props?.candidateMapping?.paymentsCandidateSubtypeMapping?.paymentsCompanyAddOnCustoms?.map(
        (value, index) => {
          verficationIconsArray.push(
            <OverlayTrigger
              key={`candidate_customchecks`}
              placement='bottom'
              overlay={
                <Tooltip id={`ref`}>
                  <p>{value.checkName || 'Custom Verification'}</p>
                  <p>
                    <span
                      style={{ color: getColor(value?.status) || CHECK_STATUSES.values[3]?.color }}
                    >
                      {getLabel(value?.status) || CHECK_STATUSES.values[3]?.value}
                    </span>
                  </p>
                </Tooltip>
              }
            >
              <Icon
                icon={VERIFICATION_ICONS[9].icon}
                color={getColor(value?.status) || CHECK_STATUSES.values[3].color}
                width={VERIFICATION_ICONS[9].width}
                height={16}
                className={styles.iconScaling}
              />
            </OverlayTrigger>
          );
        }
      );

      if (props?.checkType && props.checkType.includes('1')) {
        verficationIconsArray.push(
          <OverlayTrigger
            key={`candidate_checktype_1`}
            placement='bottom'
            overlay={
              <Tooltip id={`identity`}>
                <p>{VERIFICATION_ICONS[0].value}</p>
              </Tooltip>
            }
          >
            <div>
              <Icon
                icon={VERIFICATION_ICONS[0].icon}
                width={VERIFICATION_ICONS[0].width}
                height={16}
                className={styles.iconScaling}
              />
            </div>
          </OverlayTrigger>
        );
      }
      if (props?.checkType && props.checkType.includes('4')) {
        verficationIconsArray.push(
          <OverlayTrigger
            key={`candidate_checktype_4`}
            placement='bottom'
            overlay={
              <Tooltip id={`employments`}>
                <p>{VERIFICATION_ICONS[1].value}</p>
              </Tooltip>
            }
          >
            <div>
              <Icon
                icon={VERIFICATION_ICONS[1].icon}
                width={VERIFICATION_ICONS[1].width}
                height={16}
                className={styles.iconScaling}
              />
            </div>
          </OverlayTrigger>
        );
      }
      if (props?.checkType && props.checkType.includes('3')) {
        verficationIconsArray.push(
          <OverlayTrigger
            key={`candidate_checktype_3`}
            placement='bottom'
            overlay={
              <Tooltip id={`address`}>
                <p>{VERIFICATION_ICONS[2].value}</p>
              </Tooltip>
            }
          >
            <div>
              <Icon
                icon={VERIFICATION_ICONS[2].icon}
                width={VERIFICATION_ICONS[2].width}
                height={16}
                className={styles.iconScaling}
              />
            </div>
          </OverlayTrigger>
        );
      }
      if (props?.checkType && props.checkType.includes('5')) {
        verficationIconsArray.push(
          <OverlayTrigger
            key={`candidate_checktype_5`}
            placement='bottom'
            overlay={
              <Tooltip id={`educations`}>
                <p>{VERIFICATION_ICONS[3].value}</p>
              </Tooltip>
            }
          >
            <div>
              <Icon
                icon={VERIFICATION_ICONS[3].icon}
                width={VERIFICATION_ICONS[3].width}
                height={16}
                className={styles.iconScaling}
              />
            </div>
          </OverlayTrigger>
        );
      }
      if (props?.checkType && props.checkType.includes('6')) {
        verficationIconsArray.push(
          <OverlayTrigger
            key={`candidate_checktype_6`}
            placement='bottom'
            overlay={
              <Tooltip id={`world`}>
                <p>{VERIFICATION_ICONS[4].value}</p>
              </Tooltip>
            }
          >
            <div>
              <Icon
                icon={VERIFICATION_ICONS[4].icon}
                width={VERIFICATION_ICONS[4].width}
                height={16}
                className={styles.iconScaling}
              />
            </div>
          </OverlayTrigger>
        );
      }
      if (props?.checkType && props.checkType.includes('7')) {
        verficationIconsArray.push(
          <OverlayTrigger
            key={`candidate_checktype_7`}
            placement='bottom'
            overlay={
              <Tooltip id={`drug`}>
                <p>{VERIFICATION_ICONS[5].value}</p>
              </Tooltip>
            }
          >
            <div>
              <Icon
                icon={VERIFICATION_ICONS[5].icon}
                width={VERIFICATION_ICONS[5].width}
                height={16}
                className={styles.iconScaling}
              />
            </div>
          </OverlayTrigger>
        );
      }
      if (props?.checkType && props.checkType.includes('2')) {
        verficationIconsArray.push(
          <OverlayTrigger
            key={`candidate_checktype_2`}
            placement='bottom'
            overlay={
              <Tooltip id={`court`}>
                <p>{VERIFICATION_ICONS[6].value}</p>
              </Tooltip>
            }
          >
            <div>
              <Icon
                icon={VERIFICATION_ICONS[6].icon}
                width={VERIFICATION_ICONS[6].width}
                height={16}
                className={styles.iconScaling}
              />
            </div>
          </OverlayTrigger>
        );
      }
      if (props?.checkType && props.checkType.includes('8')) {
        verficationIconsArray.push(
          <OverlayTrigger
            key={`candidate_checktype_8`}
            placement='bottom'
            overlay={
              <Tooltip id={`credits`}>
                <p>{VERIFICATION_ICONS[7].value}</p>
              </Tooltip>
            }
          >
            <div>
              <Icon
                icon={VERIFICATION_ICONS[7].icon}
                width={VERIFICATION_ICONS[7].width}
                height={16}
                className={styles.iconScaling}
              />
            </div>
          </OverlayTrigger>
        );
      }
      if (props?.checkType && props.checkType.includes('9')) {
        verficationIconsArray.push(
          <OverlayTrigger
            key={`candidate_checktype_9`}
            placement='bottom'
            overlay={
              <Tooltip id={`ref`}>
                <p>{VERIFICATION_ICONS[8].value}</p>
              </Tooltip>
            }
          >
            <div>
              <Icon
                icon={VERIFICATION_ICONS[8].icon}
                width={VERIFICATION_ICONS[8].width}
                height={16}
                className={styles.iconScaling}
              />
            </div>
          </OverlayTrigger>
        );
      }
    } else {
      verficationIconsArray.push(<div key={`blank_icon`}>{'-'}</div>);
    }
  }
  return verficationIconsArray;
};
