import * as Yup from 'yup';
import { getCustomNameValidationSchema } from '../../../../../../../utils/ValidationSchema';

export default Yup.object().shape({
  universityName: getCustomNameValidationSchema({
    message: 'Please remove specials characters',
    isRequired: true,
    isRequiredMessage: 'University/Board'
  }),
  collegeName: getCustomNameValidationSchema({
    message: 'Please remove specials characters',
    isRequired: true,
    isRequiredMessage: 'College/Institution/School'
  }),
  registrationNumber: getCustomNameValidationSchema({
    message: 'Please remove specials characters',
    isRequired: true,
    isRequiredMessage: 'Registration Number'
  }),
  startDate: Yup.mixed()
    .test({
      name: 'startDate',
      message: 'Provide a valid date',
      test: (value) => {
        return value !== null ? true : false;
      }
    })
    .test({
      name: 'startDateBeforeEndDate',
      message: 'Start date must be before the end date',
      test: function (startDate) {
        const { endDate } = this.parent; // Access the endDate value
        if (!startDate || !endDate) return true; // Skip validation if either is null
        return new Date(startDate) < new Date(endDate); // Ensure startDate is less than endDate
      }
    }),
  endDate: Yup.mixed()
    .test({
      name: 'endDate',
      message: 'Provide a valid date',
      test: (value) => {
        return value !== null ? true : false;
      }
    })
    .test({
      name: 'endDateAfterStartDate',
      message: 'End date must be after the start date',
      test: function (endDate) {
        const { startDate } = this.parent; // Access the startDate value
        if (!startDate || !endDate) return true; // Skip validation if either is null
        return new Date(startDate) < new Date(endDate); // Ensure startDate is less than endDate
      }
    })
});
