import React, { useState, useEffect } from 'react';
import ordinal from 'ordinal';
import Modal from 'react-bootstrap/Modal';
import { Col, Button, Container, Row } from 'react-bootstrap';

import {
  showAddOnLabel,
  getCourtVerificationType,
  getAddressVerificationType
} from '../../../../utils/utilities';
import {
  IdentityArr,
  AddressArr,
  EducationArr,
  educationVerificationMediumObject
} from '../../../../utils/commonConstant';
import Loader from '../../../../core-components/Loader';

import styles from './SelectedChecks.module.scss';
import Image from '../../../../core-components/Image';
import { useSelector } from 'react-redux';
import { maskPriceForAdmins } from '../../frontend-common/CreditBuyPackagePage/UtilComponents/UtilitiesFunction';

export default (props) => {
  const [loading, setLoading] = useState(false);
  const [candidateID, setCandidateID] = useState(
    new URLSearchParams(document.location.search).get('candidateId') || null
  );
  const details = props?.packageDetails || null;
  const [isAddonPresent, setIsAddOnPresent] = useState(true);
  const profile = useSelector((state) => state.profile);
  const paymentModelType =
    profile?.profile?.company_user_mapping?.company?.payments_company_model_config?.model_type ===
    'POSTPAID_CANDIDATE_COMPLETION'
      ? 'POSTPAID'
      : 'PREPAID';
  const accessLevel = profile?.profile?.access_level;
  useEffect(() => {
    if (details && details?.addons) {
      setIsAddOnPresent(showAddOnLabel(details?.addons));
    }
  }, []);
  const getFinalReferencesAddon = () => {
    let reference_addons = [];
    details?.addons?.reference?.forEach((element) => {
      element?.check?.map((check, index) => {
        if (reference_addons[index]) {
          reference_addons[index] = reference_addons[index] + parseInt(check);
        } else {
          reference_addons.push(parseInt(check));
        }
      });
    });
    return reference_addons;
  };
  return (
    <Modal
      size='lg'
      show={props?.show}
      onHide={props?.onHide}
      dialogClassName='customisePackageModal'
      backdropClassName={'customBGVModalBackdrop'}
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          <h6 className={styles.mainTitle}>{`Checks selected`}</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Container className={styles.loaderContainer}>
            <Row className={styles.loader}>
              <Loader />
            </Row>
          </Container>
        ) : (
          <Container className={styles.detailsContainer}>
            <Row className={styles.headingRow}>
              <Col>
                <Row className={styles.headingRow}>
                  <Col lg={12} md={12} className={styles.checkHeading}>
                    PACKAGE
                  </Col>
                  <Col lg={12} md={12} className={styles.checkDetails}>
                    {details?.packageName}
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row className={styles.headingRow}>
                  <Col lg={12} md={12} className={styles.checkHeading}>
                    SUB TYPE
                  </Col>
                  <Col lg={12} md={12} className={styles.checkDetails}>
                    {details?.subtypeName}{' '}
                    {details?.friendlyName ? `(${details?.friendlyName})` : null}
                  </Col>
                </Row>
              </Col>
            </Row>
            {details?.internationalChargesIncluded && (
              <Row>
                <Col lg={12} md={12} className={styles.checkSubHeading}>
                  <div className={styles.bannerContainer}>
                    <Image name='InfoIcon.svg' style={{ marginLeft: '5px' }} />
                    <span>Note: International charges are included in the package</span>
                  </div>
                </Col>
              </Row>
            )}
            <Row>
              <Col lg={12} md={12} className={styles.checkSubHeading}>
                CHECKS INCLUDED
              </Col>
            </Row>
            <Row className={styles.checksDescripContainer}>
              {/* Identity */}
              {details?.checksIncluded &&
              details?.checksIncluded?.identity &&
              details?.checksIncluded?.identity?.length ? (
                <Col lg={6} md={6} className={styles.checksDescripCol}>
                  <Col lg={12} md={12} className={styles.checkDetails}>
                    Identity
                  </Col>
                  {details?.checksIncluded?.identity?.map((val, index) => {
                    return (
                      <Col lg={12} md={12} className={styles.checkHeading} key={`checks-${index}`}>
                        {val === 'VOTER'
                          ? IdentityArr['VOTER_ID']
                          : IdentityArr[val]
                          ? IdentityArr[val]
                          : val.replace('_', ' ')}
                      </Col>
                    );
                  })}
                </Col>
              ) : null}

              {/* Address */}
              {details?.checksIncluded &&
              details?.checksIncluded?.address &&
              details?.checksIncluded?.address?.length ? (
                <Col lg={6} md={6} className={styles.checksDescripCol}>
                  <Col lg={12} md={12} className={styles.checkDetails}>
                    Address
                  </Col>
                  {details.checksIncluded.address.map((val, index) => {
                    return (
                      <Col lg={12} md={12} className={styles.checkHeading} key={`checks-${index}`}>
                        {AddressArr[val?.type]
                          ? AddressArr[val?.type]
                          : val?.type?.replace('_', ' ')}
                        {` (${getAddressVerificationType(val?.verificationType)})`}
                      </Col>
                    );
                  })}
                </Col>
              ) : null}

              {/* Court */}
              {details?.checksIncluded &&
              details?.checksIncluded?.court &&
              details?.checksIncluded?.court?.length ? (
                <Col lg={6} md={6} className={styles.checksDescripCol}>
                  <Col lg={12} md={12} className={styles.checkDetails}>
                    Court Record
                  </Col>
                  {details.checksIncluded.court.map((val, index) => {
                    return (
                      <Col lg={12} md={12} className={styles.checkHeading} key={`checks-${index}`}>
                        {AddressArr[val?.type]
                          ? AddressArr[val?.type]
                          : val?.type?.replace('_', ' ')}
                        {` (${getCourtVerificationType(val?.verificationType)})`}
                      </Col>
                    );
                  })}
                </Col>
              ) : null}

              {/* Employment */}
              {details?.checksIncluded && details?.checksIncluded?.employment ? (
                <Col lg={6} md={6} className={styles.checksDescripCol}>
                  <Col lg={12} md={12} className={styles.checkDetails}>
                    Employment
                  </Col>
                  <Col lg={12} md={12} className={styles.checkHeading}>
                    {`Last ${details.checksIncluded.employment}`}
                  </Col>
                </Col>
              ) : null}

              {/* Education */}
              {details?.checksIncluded && details?.checksIncluded?.education ? (
                <Col lg={6} md={6} className={styles.checksDescripCol}>
                  <Col lg={12} md={12} className={styles.checkDetails}>
                    Education
                  </Col>
                  {details?.checksIncluded?.education ? (
                    Array.isArray(details.checksIncluded.education) ? (
                      details.checksIncluded.education.map((val, index) => {
                        return (
                          <Col
                            lg={12}
                            md={12}
                            className={styles.checkHeading}
                            key={`checks-${index}`}
                          >
                            {EducationArr[val] ? EducationArr[val] : val?.replace('_', ' ')}
                            {` (${
                              educationVerificationMediumObject[
                                details.checksIncluded?.educationVerificationType
                              ]
                            }) `}
                          </Col>
                        );
                      })
                    ) : (
                      <Col lg={12} md={12} className={styles.checkHeading}>
                        {`Highest ${details?.checksIncluded?.education}`}
                        {` (${
                          educationVerificationMediumObject[
                            details?.checksIncluded?.educationVerificationType
                          ]
                        }) `}
                      </Col>
                    )
                  ) : null}
                </Col>
              ) : null}
              {/* Reference */}
              {details?.checksIncluded &&
              details?.checksIncluded?.reference &&
              details?.checksIncluded?.reference?.length ? (
                <Col lg={6} md={6} className={styles.checksDescripCol}>
                  <Col lg={12} md={12} className={styles.checkDetails}>
                    Reference
                  </Col>
                  {details.checksIncluded.reference.map((val, index) => {
                    if (val != 0) {
                      return (
                        <Col
                          lg={12}
                          md={12}
                          className={styles.checkHeading}
                          key={`checks-${index}`}
                        >
                          {index == 0
                            ? `${val} × Last employment`
                            : `${val} × ${ordinal(index + 1)} Last employment`}
                        </Col>
                      );
                    }
                  })}
                </Col>
              ) : null}

              {/* Drug */}
              {details?.checksIncluded && details?.checksIncluded?.drug ? (
                <Col lg={6} md={6} className={styles.checksDescripCol}>
                  <Col lg={12} md={12} className={styles.checkDetails}>
                    Drug
                  </Col>
                  <Col lg={12} md={12} className={styles.checkHeading}>
                    {details.checksIncluded.drug
                      ? details?.checksIncluded.drug.replace('_', ' ')
                      : ''}
                  </Col>
                </Col>
              ) : null}
              {/* CIBIL */}
              {details?.checksIncluded && details?.checksIncluded?.cibil ? (
                <Col lg={6} md={6} className={styles.checksDescripCol}>
                  <Col lg={12} md={12} className={styles.checkDetails}>
                    1 x Credit Check
                  </Col>
                </Col>
              ) : null}
              {/* World */}
              {details?.checksIncluded && details?.checksIncluded?.world ? (
                <Col lg={6} md={6} className={styles.checksDescripCol}>
                  <Col lg={12} md={12} className={styles.checkDetails}>
                    1 x World
                  </Col>
                </Col>
              ) : null}

              {/* Custom */}
              {details?.checksIncluded?.custom?.map((val, index) => (
                <>
                  <Col lg={6} md={6} className={styles.checksDescripCol}>
                    <Col lg={12} md={12} className={styles.checkDetails} key={`checks-${index}`}>
                      {val ? val.replace('_', ' ') : ''}
                    </Col>
                  </Col>
                </>
              ))}
            </Row>
            {isAddonPresent ? (
              <Row>
                <Col lg={12} md={12} className={styles.checkSubHeading}>
                  ADD ONS
                </Col>
              </Row>
            ) : null}
            <Row className={styles.checksDescripContainer}>
              {/* Identity */}

              {details?.addons && details?.addons?.identity && details?.addons?.identity?.length ? (
                <Col lg={6} md={6} className={styles.checksDescripCol}>
                  <Col lg={12} md={12} className={styles.checkDetails}>
                    Identity
                  </Col>
                  {details.addons.identity.map((val, index) => {
                    return (
                      <Col lg={12} md={12} className={styles.checkHeading} key={`checks-${index}`}>
                        {IdentityArr[val?.check]
                          ? IdentityArr[val?.check]
                          : val.check
                          ? val.check.replace('_', ' ')
                          : 'ANY'}
                        <span className={styles.priceText}>{`₹${maskPriceForAdmins(
                          val?.cost,
                          accessLevel,
                          paymentModelType
                        )}`}</span>
                      </Col>
                    );
                  })}
                </Col>
              ) : null}

              {/* Address */}
              {details?.addons && details?.addons?.address && details?.addons?.address?.length ? (
                <Col lg={6} md={6} className={styles.checksDescripCol}>
                  <Col lg={12} md={12} className={styles.checkDetails}>
                    Address
                  </Col>
                  {details.addons.address.map((val, index) => {
                    return (
                      <Col lg={12} md={12} className={styles.checkHeading} key={`checks-${index}`}>
                        {AddressArr[val?.check]
                          ? AddressArr[val?.check]
                          : val?.check.replace('_', ' ')}
                        {` (${getAddressVerificationType(val?.verificationType)})`}
                        <span className={styles.priceText}>{`₹${maskPriceForAdmins(
                          val?.cost,
                          accessLevel,
                          paymentModelType
                        )}`}</span>
                      </Col>
                    );
                  })}
                </Col>
              ) : null}

              {/* Court */}
              {details?.addons && details?.addons?.court && details?.addons?.court?.length ? (
                <Col lg={6} md={6} className={styles.checksDescripCol}>
                  <Col lg={12} md={12} className={styles.checkDetails}>
                    Court Record
                  </Col>
                  {details.addons.court.map((val, index) => {
                    return (
                      <Col lg={12} md={12} className={styles.checkHeading} key={`checks-${index}`}>
                        {AddressArr[val?.check]
                          ? AddressArr[val?.check]
                          : val?.check.replace('_', ' ')}
                        {` (${getCourtVerificationType(val?.verificationType)})`}
                        <span className={styles.priceText}>{`₹${maskPriceForAdmins(
                          val?.cost,
                          accessLevel,
                          paymentModelType
                        )}`}</span>
                      </Col>
                    );
                  })}
                </Col>
              ) : null}

              {/* Employment */}
              {details?.addons &&
              details?.addons?.employment &&
              details?.addons?.employment?.count ? (
                <Col lg={6} md={6} className={styles.checksDescripCol}>
                  <Col lg={12} md={12} className={styles.checkDetails}>
                    {`${details.addons.employment.count} × Employment`}
                    <span className={styles.priceText}>{`₹${maskPriceForAdmins(
                      details.addons.employment.cost,
                      accessLevel,
                      paymentModelType
                    )}`}</span>
                  </Col>
                </Col>
              ) : null}

              {/* Education */}
              {details?.addons &&
              details?.addons?.education &&
              details?.addons?.education?.config &&
              details?.addons?.education?.config?.length ? (
                <Col lg={6} md={6} className={styles.checksDescripCol}>
                  <Col lg={12} md={12} className={styles.checkDetails}>
                    Education
                  </Col>
                  {details.addons.education.config.map((val, index) => {
                    return (
                      <Col lg={12} md={12} className={styles.checkHeading} key={`checks-${index}`}>
                        {details.addons.education.orderType === 'SPECIFIC' ? (
                          EducationArr[val?.educationLevel] ? (
                            EducationArr[val.educationLevel]
                          ) : (
                            val?.educationLevel?.replace('_', ' ')
                          )
                        ) : (
                          <span> Last {val?.educationLevel}</span>
                        )}
                        {` (${educationVerificationMediumObject[val?.verificationType]})`}
                        <span className={styles.priceText}>{`₹${maskPriceForAdmins(
                          val?.cost,
                          accessLevel,
                          paymentModelType
                        )}`}</span>
                      </Col>
                    );
                  })}
                </Col>
              ) : null}
              {/* Reference */}
              {details?.addons &&
              details?.addons?.reference &&
              details?.addons?.reference?.length ? (
                <Col lg={6} md={6} className={styles.checksDescripCol}>
                  {getFinalReferencesAddon()?.length &&
                    getFinalReferencesAddon().map((val) => {
                      return (
                        val !== 0 && (
                          <Col lg={12} md={12} className={styles.checkDetails}>
                            Reference
                            <span className={styles.priceText}>{`₹${maskPriceForAdmins(
                              details.addons.reference[0].cost,
                              accessLevel,
                              paymentModelType
                            )}`}</span>
                          </Col>
                        )
                      );
                    })}
                  {getFinalReferencesAddon()?.length &&
                    getFinalReferencesAddon().map((val, index) => {
                      return (
                        val !== 0 && (
                          <Col
                            lg={12}
                            md={12}
                            className={styles.checkHeading}
                            key={`checks-${index}`}
                          >
                            {index == 0
                              ? `${val} × Last employment`
                              : `${val} × ${ordinal(index + 1)} Last employment`}
                          </Col>
                        )
                      );
                    })}
                </Col>
              ) : null}

              {/* World */}
              {details?.addons && details?.addons?.world && details?.addons?.world?.cost ? (
                <Col lg={6} md={6} className={styles.checksDescripCol}>
                  <Col lg={12} md={12} className={styles.checkDetails}>
                    {`World check`}
                    <span className={styles.priceText}>{`₹${maskPriceForAdmins(
                      details.addons.world.cost,
                      accessLevel,
                      paymentModelType
                    )}`}</span>
                  </Col>
                </Col>
              ) : null}

              {/* Cibil */}
              {details?.addons && details?.addons?.cibil && details?.addons?.cibil?.cost ? (
                <Col lg={6} md={6} className={styles.checksDescripCol}>
                  <Col lg={12} md={12} className={styles.checkDetails}>
                    {`Credit Check`}
                    <span className={styles.priceText}>{`₹${maskPriceForAdmins(
                      details.addons.cibil.cost,
                      accessLevel,
                      paymentModelType
                    )}`}</span>
                  </Col>
                </Col>
              ) : null}

              {/* Drug */}
              {details?.addons && details?.addons?.drug && details?.addons?.drug?.length ? (
                <Col lg={6} md={6} className={styles.checksDescripCol}>
                  <Col lg={12} md={12} className={styles.checkDetails}>
                    Drug check
                  </Col>
                  {details.addons.drug
                    ? details.addons.drug.map((val, index) => {
                        return (
                          <Col
                            lg={12}
                            md={12}
                            className={styles.checkHeading}
                            key={`drug-checks-${index}`}
                          >
                            {`${val.check.replace('_', ' ')}`}
                            <span className={styles.priceText}>{`₹${maskPriceForAdmins(
                              val.cost,
                              accessLevel,
                              paymentModelType
                            )}`}</span>
                          </Col>
                        );
                      })
                    : null}
                </Col>
              ) : null}

              {/* Custom */}
              {details?.addons?.custom?.map((val, index) => {
                return (
                  <Col lg={6} md={6} className={styles.checksDescripCol} key={`checks-${index}`}>
                    <Col lg={12} md={12} className={styles.checkDetails}>
                      {val.check ? val.check.replace('_', ' ') : ''}
                      <span className={styles.priceText}>{`₹${
                        maskPriceForAdmins(val.cost, accessLevel, paymentModelType) || '0'
                      }`}</span>
                    </Col>
                  </Col>
                );
              })}
            </Row>
            <Row>
              <Col lg={12} md={12} className={styles.creditSubHeading}>
                CREDITS USED
              </Col>
              <Col lg={12} md={12} className={styles.creditDetails}>
                {`₹${maskPriceForAdmins(details?.credits, accessLevel, paymentModelType) || 0}`}
              </Col>
            </Row>
            {details?.hrInviteNote ? (
              <Row>
                <Col lg={12} md={12} className={styles.customNoteHeading}>
                  CUSTOM HR NOTE TO CANDIDATE
                </Col>
                <Col lg={12} md={12} className={styles.customNoteDetails}>
                  {details?.hrInviteNote}
                </Col>
              </Row>
            ) : null}
          </Container>
        )}
      </Modal.Body>
      <Modal.Footer className={styles.modalFooter}>
        <Button
          className={styles.activeBtn}
          onClick={() => {
            props?.onHide();
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
