import * as Yup from 'yup';
import moment from 'moment';
import {
  employeeIdValidationSchema,
  getCityNameValidation,
  getStrictNameValidationSchema
} from '../../../../../../../utils/ValidationSchema';

export default Yup.object().shape({
  is_employee_id_not_available: Yup.boolean(),
  company_name: getStrictNameValidationSchema({ message: 'Company Legal Name', isRequired: true }),
  company_brand_name: getStrictNameValidationSchema({
    message: 'Company Brand Name',
    isRequired: false
  }),
  city: Yup.string().required('City is required'),
  designation: getStrictNameValidationSchema({ message: 'designation', isRequired: true }),
  employee_id: Yup.string().when('is_employee_id_not_available', {
    is: false,
    then: employeeIdValidationSchema({ message: 'Employee ID', isRequired: true })
  }),

  currently_working: Yup.boolean(),
  start_date: Yup.mixed()
    .required('Provide a valid start date')
    .test({
      name: 'start_date_validity',
      message: 'Start date must be before last working date',
      test: function (start_date) {
        const { end_date } = this.parent;
        if (!start_date || !end_date) return true;
        return moment(start_date).isBefore(moment(end_date));
      }
    }),
  end_date: Yup.mixed().when(['currently_working', 'is_lwd_optional'], {
    is: (currently_working, is_lwd_optional) => currently_working && !is_lwd_optional,
    then: Yup.mixed()
      .required('Provide a valid tentative last working date')
      .test({
        name: 'end_date_validity',
        message: 'Last working date must be after start date',
        test: function (end_date) {
          const { start_date } = this.parent;
          const today = moment().startOf('day');
          if (!start_date || !end_date) return true;

          const start = moment(start_date);
          const end = moment(end_date);
          if (end.isSame(start, 'day')) {
            return this.createError({
              message: 'Start date and tentative last working date cannot be the same'
            });
          }
          if (!end.isAfter(start)) {
            return this.createError({
              message: ' Tentative last working date must be after start date'
            });
          }
          if (end.isBefore(today)) {
            return this.createError({
              message: 'Tentative Last working date must be same or greater than current date'
            });
          }

          return true;
        }
      }),
    otherwise: Yup.mixed().when('is_lwd_optional', {
      is: false,
      then: Yup.mixed()
        .required('Provide a valid last working date')
        .test({
          name: 'end_date_validity',
          message: 'Last working date must be after start date',
          test: function (end_date) {
            const { start_date } = this.parent;
            const today = moment().startOf('day');
            if (!start_date || !end_date) return true;

            const start = moment(start_date);
            const end = moment(end_date);
            if (end.isSame(start, 'day')) {
              return this.createError({
                message: 'Start date and last working date cannot be the same'
              });
            }
            if (!end.isAfter(start)) {
              return this.createError({ message: 'Last working date must be after start date' });
            }

            return true;
          }
        })
    })
  }),
  reason: Yup.string().nullable().trim().max(500, 'Max character limit (500) reached')
});
