import * as Yup from 'yup';
import { getTrimmedValue, isValidPhoneNumber } from '../../../../utils/utilities';
import {
  employeeIdValidationSchema,
  getEmailValidationSchema,
  getNameValidationSchema,
  getPhoneValidationSchema,
  getUANValidationSchema
} from '../../../../utils/ValidationSchema';

export default Yup.object().shape({
  name: getNameValidationSchema({ message: 'name.', isRequired: true }),
  email: getEmailValidationSchema({
    isRequired: false,
    isNullable: false,
    msg: 'Please enter a valid email address.'
  }).when('formConfig', {
    is: (formConfig) => {
      return !formConfig.isEmailOptional;
    },
    then: getEmailValidationSchema({
      isRequired: true,
      isNullable: false,
      msg: 'Please enter a valid email address.'
    })
  }),
  alternateEmail: getEmailValidationSchema({
    isRequired: false,
    isNullable: true,
    msg: 'Please enter a valid email address.'
  }),
  phone: Yup.string()
    .transform((currentVal) => getTrimmedValue(currentVal, false))
    .when('phoneNumberExists', {
      is: true,
      then: Yup.string().test({
        name: 'phone',
        message: 'Phone Number already exists.',
        test: (value) => {
          return false;
        }
      })
    })
    .required('Phone Number cannot be blank.'),
  consentDoc: Yup.string().when('consent', {
    is: false,
    then: Yup.string().required('Consent cannot be blank.')
  }),
  resumeLink: Yup.string().when('formConfig', {
    is: (formConfig) => !formConfig.isResumeOptional,
    then: Yup.string().required('Resume cannot be blank.')
  }),
  employeeId: Yup.string()
    .transform((currentVal) => getTrimmedValue(currentVal, true))
    .when('employeeIdExists', {
      is: true,
      then: Yup.string('Employee Id already exists.'),
      otherwise: employeeIdValidationSchema({
        message: 'Employee ID.',
        isRequired: false
      })
    }),
  uanNumber: getUANValidationSchema({ isRequired: false, isNullable: false }),
  doj: Yup.date()
    .transform((value, originalValue) => {
      const trimmedValue = getTrimmedValue(originalValue, false);
      return trimmedValue ? new Date(trimmedValue) : null;
    })
    .when('formConfig', {
      is: (formConfig) => formConfig.isDojMandatory,
      then: (schema) =>
        schema
          .required('Date of joining is required')
          .nullable()
          .typeError('Please enter a valid date')
    })
});
